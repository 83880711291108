import gql from 'graphql-tag'

export default gql`query phxGroups($group_id: ID) {
	phxGroups(group_id: $group_id) {
		id
    	name
		alt_name
		aws_connect_phone
		bill_on_day
		bill_on_additional_charges {
			description
			amount
			percent
			bill_on_behavior
      	} 
		bill_on_additional_discounts {
			description
			amount
			percent
			bill_on_behavior
		} 
		billing_email_address
		voicemail {
			language
			voice
			primary_contact
			greeting {
				language
    			text
			}
			mailboxes {
				name
    			users
			}
			entry_point
			entry_value
			custom_prompts {
				language
				text
				key
			}
			metadata {
				name
				collect
				prompts {
					language
					text
				}
			}
			menus {
				name
    			introduction{
					language
					text
				}
    			message {
					language
					text
				}
    			options {
					number
					action
					action_value
				}
			}
			notify_sms_numbers
    		notify_emails
			additional_numbers {
				number
				voicemail {
					language
					voice
					primary_contact
					greeting {
						language
						text
					}
					mailboxes {
						name
						users
					}
					entry_point
					entry_value
					custom_prompts {
						language
						text
						key
					}
					metadata {
						name
						collect
						prompts {
							language
							text
						}
					}
					menus {
						name
						introduction{
							language
							text
						}
						message {
							language
							text
						}
						options {
							number
							action
							action_value
						}
					}					
				}
			}
		}
		voicemail_backups {
			date
			description
			id
		}
  	}
}`
