import { clearLocalStorage, route } from '../../methods/globalMethods';

function defaultErrorHandler(e) {
  if (e.networkError && e.networkError.result && e.networkError.result.errors) {
    if (e.networkError.result.errors.length > 0) {
      if (e.networkError.result.errors[0].errorType === "UnauthorizedException" || e.networkError.result.errors[0].errorType === "Unauthorized") {
        // need to go back to login
        console.log('Your auth token has expired. Bringing you back to login.');
        clearLocalStorage();
        console.log('ROUTE: ', route);
        route('login', 'fade-in-transition');
      }
    }
  } else {
    console.log('There was an error: ', e);
  }
}

export { defaultErrorHandler }
