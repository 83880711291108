<template>

    <div class="d-flex fill-height ycmd-dark-blue full-width pa-2 br-2">

        <div class="d-flex fill-height full-width flex-column">

            <div class="d-flex align-center white--text pa-2 pb-3">

                <div class="fs-16 fw-500">CONTACT US</div>
                <v-spacer />
                <v-btn dark icon text @click="route('authFAQ', 'down')">
                    <v-icon>close</v-icon>
                </v-btn>
            </div>

            <div class="d-flex flex-column flex-grow-1 full-width">

              <div class="d-flex  flex-column flex-grow-1 full-width relative">

                  <div class="absolute full-width full-height">
                      <div class="d-flex flex-column fill-height flex-grow-1 ycmd-medium-blue white--text full-width pa-3 pb-4 br-2" style="overflow: auto">

                          <div class="d-flex flex-column pt-3 pb-3">
                              <div class="fs-16">Phone</div>
                              <div class="pt-3 fs-13 fade-2">Gaining access to the PCPLink portal is a multi step process.
                                Call us at <a style="color: white;" href="tel:+8335720424">(833)-572-0424</a>
                              </div>
                          </div>

                          <div class="d-flex flex-column pt-4 pb-3">
                              <div class="fs-16">Email</div>
                              <div class="pt-3 fs-13 fade-2">
                                You can email us at <a style="color: white;" href="mailto:developers@youcallmd.com" target="_top">developers@youcallmd.com</a>
                              </div>
                          </div>

                      </div>
                  </div>

              </div>

            </div>

            <div class="d-flex flex-column align-center">

                <div class="full-width pt-1">
                    <v-btn dark block large depressed color="ycmd-green"><a href="tel:+8335720424">Call: (833)-572-0424</a></v-btn>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    export default {
        name: 'authContactUs',
        data: () => ({
            tabs: null,
            email: '',
            challengeName: '',
            errorMessage: '',
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                emailMatch: () => ('The email and password you entered don\'t match')
            }
        }),
        apollo: {
            $client: 'publicClient'
        },
        methods: {
            async sendEmail() {

                console.log('code sent')
            },
            route(name, direction) {

                this.$router.push({
                    name: name, params: {
                        transition: direction
                    }
                }).catch(err => { console.log('router error: ', err) });
            }
        }
    }

</script>

<style scoped>



</style>
