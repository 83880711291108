<template>

    <v-layout column class="blue">

        <v-layout shrink column dark text class="transparent pa-0 ma-0">

            <v-layout class="pl-3 pt-2 pr-4 pb-1">
                <v-layout align-center class="pt-2" style="height: 100%">
                    <div class="hidden-xs-only white--text fs-13 pl-1">
                        <span class="fade-2 hidden-sm-and-down">Showing</span>
                        <span class="fw-500 fs-18 pl-1 pr-1">10</span>
                        <span class="fade-2">of</span>
                        <span class="fw-500 fs-18 pl-1 pr-1">24</span>
                    </div>
                    <v-tooltip bottom nudge-bottom="16" color="ycmd-black" content-class="fade-0 pa-4 pt-2 pb-2">
                        <template v-slot:activator="{ on }">
                            <v-btn icon small dense depressed v-on="on" class="ml-2 mr-2" color="white">
                                <v-icon :size="22" color="white">refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh List</span>
                    </v-tooltip>
                    <v-text-field hide-details dark flat single-line dense solo
                                  v-model="searchValue"
                                  v-on:keyup.enter="searchPCP"
                                  label="Search"
                                  color="blue"
                                  prepend-inner-icon="search"
                                  class="search-bar ml-2 br-0 br_tl-2 br_bl-2"/>
                    <v-btn dark depressed class="hidden-xs-only mr-3 br-0 br_tr-2 br_br-2"
                           style="background: #0e4678; height: 38px">Go
                    </v-btn>
                </v-layout>
                <v-layout class="provider-list pl-4 pr-3" style="height: 100%; max-width: 16%; min-width: 100px">
                    <v-btn @click="route('ADMIN.sampleDetails', 'next')">Next</v-btn>
                </v-layout>
            </v-layout>

        </v-layout>

        <v-layout class="red">
            <div class="fs-32 white--text pb-4" >List</div>
            <v-btn dark depressed color="ycmd-black" @click="changeView('details-view')">Next</v-btn>
        </v-layout>

    </v-layout>

</template>

<script>

    export default {
        name: 'sampleList',
        data() {
            return {

            }
        },
        mounted() {

        },
        methods: {
            changeView(view) {

                this.$emit('onChangeView', view);
            },
            route(name, transition) {

                this.$router.push({
                    name: name, params: {
                        transition: transition
                    }
                }).catch(err => { console.log('router error: ', err) });
            }
        }
    }

</script>

<style scoped>

</style>
