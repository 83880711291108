import gql from 'graphql-tag'

export default gql`mutation billingGroupUpdate($group_id: ID, $billing_group: BillingGroupInput, $extended_billing_plan: [BillingPlanExtendedItemInput], $bill_on_additional_charges: [BillingCharge], $bill_on_additional_discounts: [BillingCharge] ) {
	billingGroupUpdate(group_id: $group_id, billing_group: $billing_group, extended_billing_plan: $extended_billing_plan, bill_on_additional_charges: $bill_on_additional_charges, bill_on_additional_discounts: $bill_on_additional_discounts) {
		name
		altName
		shortCode
		group_id
		group_id_postgres
    bill_on_day
    billing_contact_name
    billing_contact_salutation
    billing_email_address 
		address {
        id
        address
        addressCont
        city
        state
        zipCode
        legacy_id
    }
    billing_plan {
      name
      type
      included
      value
      description
      rate
    }
    extended_billing_plan {
      name
      type
      included
      value
      description
      rate
      tier_break
      tiers {
        limit
        value
        rate
        description
      }
    }
    bill_on_additional_charges {
      description
      amount
      percent
      bill_on_behavior
    } 
    bill_on_additional_discounts{
      description
      amount
      percent
      bill_on_behavior
    } 

  }
}`
