import gql from 'graphql-tag'

export default gql`query ycmdFacilitySearch($facility_id: ID, $limit: Int, $page: Int, $search: String, $location: String, $group_ids: [ID], $enrollment_status: FacilityEnrollmentStatus) {
	ycmdFacilitySearch(facility_id: $facility_id, limit: $limit, page: $page, search: $search, location: $location, group_ids: $group_ids, enrollment_status: $enrollment_status)
    {
		name
		id
		shortcode
		units {
			unit_id
			name
			number
			shortcode
			new_patients
    		default_patient_status
		}
    }
}`
