<template>

    <div class="d-flex fill-height ycmd-dark-blue full-width pa-2 br-2">

      <loading :active.sync="isLoading"
              :can-cancel="false"
              :is-full-page="false"
              :background-color="`#101622`"
              :color="`#637eb6`"
              :loader="`bars`">
      </loading>

        <div class="d-flex fill-height full-width flex-column">

            <div class="d-flex align-center white--text pa-2 pb-3">

                <div class="fs-16 fw-500">SECURE CODE</div>
                <v-spacer />
                <v-btn dark icon text @click="route('login', 'prev')">
                    <v-icon>close</v-icon>
                </v-btn>
            </div>

            <div class="d-flex flex-column fill-height full-width">

                <div class="d-flex flex-column flex-grow-1 ycmd-medium-blue full-width pa-3 br-2">

                    <div class="pa-2 fade-1 white--text">Please enter the code sent to you via SMS</div>

                    <div class="pa-1 pt-3">

                        <v-text-field
                                light solo dense flat
                                v-model="smsCode"
                                name="input-smsCode"
                                label="Code"
                                v-on:keyup.enter="submit2FA"
                                :rules="[rules.required, rules.min]"
                                hint="At least 5 characters" />

                    </div>

                    <div v-if="mfaErrorMessage" style="background-color: red; color: white; padding: 5px;">{{mfaErrorMessage}}</div>
                </div>

                <div class="d-flex flex-column align-center">

                    <div class="full-width pa-1 pl-0 pr-0">
                        <v-btn dark block large depressed color="ycmd-green"
                               @click="submit2FA">Continue</v-btn>
                    </div>

                    <div class="pt-2">
                        <v-btn dark text depressed color="white"
                               @click="resend2fa">Resend Code</v-btn>
                    </div>
                </div>
            </div>

        </div>

      <v-dialog v-if="currentDialog === 'codeExpired'" persistent v-model="dialog" max-width="330" style="">

        <v-card color="" dark style="background-color: #09283F; padding-top: 15px;">
          <v-card-text class="fs-18">
            Your code has expired! Please log in again.
          </v-card-text>


          <v-card-actions>
            <v-spacer/>
            <v-btn text color="#FC5252" @click="() => {this.clearDialog(); this.route('login', 'fade-in-transition')}">Login</v-btn>
            <v-spacer/>
          </v-card-actions>
        </v-card>

      </v-dialog>

      <v-dialog v-if="currentDialog === 'pcpOnly'" persistent v-model="dialog" max-width="330" style="">

        <v-card color="" dark style="background-color: #09283F; padding-top: 15px;">
          <v-card-text class="fs-18">
            Please download our app YouCallMD in the iOS app store or Android play store.
          </v-card-text>


          <v-card-actions>
            <v-spacer/>
            <v-btn text color="#FC5252" @click="() => {this.clearDialog(); this.route('login', 'fade-in-transition')}">Back</v-btn>
            <v-spacer/>
          </v-card-actions>
        </v-card>

      </v-dialog>

      <v-dialog v-if="currentDialog === 'disabled'" persistent v-model="dialog" max-width="330" style="">

        <v-card color="" dark style="background-color: #09283F; padding-top: 15px;">
          <v-card-text class="fs-18">
            Your account has been disabled. Please contact your administrator for further assitance.
          </v-card-text>


          <v-card-actions>
            <v-spacer/>
            <v-btn text color="#FC5252" @click="() => {this.clearDialog(); this.route('login', 'fade-in-transition')}">OK</v-btn>
            <v-spacer/>
          </v-card-actions>
        </v-card>

      </v-dialog>

      <v-dialog v-if="currentDialog === 'noRoles'" persistent v-model="dialog" max-width="330" style="">

        <v-card color="" dark style="background-color: #09283F; padding-top: 15px;">
          <v-card-text class="fs-18">
            Your account has no roles. Please contact your administrator for further assitance.
          </v-card-text>


          <v-card-actions>
            <v-spacer/>
            <v-btn text color="#FC5252" @click="() => {this.clearDialog(); this.route('login', 'fade-in-transition')}">OK</v-btn>
            <v-spacer/>
          </v-card-actions>
        </v-card>

      </v-dialog>

    </div>

</template>

<script>

    import _ from 'lodash'

    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';

    import LOGIN_START from '../../graphql/mutations/authenticateBegin';
    import LOGIN_CONTINUE from '../../graphql/mutations/authenticateContinue';
    import { routeBasedOnRole } from '../../methods/globalMethods';

    export default {
        name: 'mfaCode',
        data: () => ({
            dialog: false,
            currentDialog: '',
            isLoading: false,
            smsCode: '',
            errorMessage: '',
            mfaErrorMessage: '',

            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 5 || 'Min 5 characters',
            }
        }),
        components: {
          Loading
        },
        mounted() {
          // Get other data from Vue-x
          const loginData = this.$store.getters['profile/getAuthenticateBegin'];

          // Check if other required data still exists in Vue-x
          if (!loginData.username || !loginData.challengeName || !loginData.session || !loginData.password) {
            this.route('login', 'fade-in-transition');
            return;
          }
        },
        apollo: {
            $client: 'publicClient'
        },
        methods: {
            async submit2FA() {
              // Get other data from Vue-x
              const loginData = this.$store.getters['profile/getAuthenticateBegin'];
              // console.log(loginData.username , loginData.challengeName, loginData.session);

              // Check if other required data still exists in Vue-x
              if (!loginData.username || !loginData.challengeName || !loginData.session) {
                this.route('login', 'fade-in-transition');
                return;
              }

              // // We are setting the accesstoken to null here because we don't want access token header to send for login
              // localStorage.setItem("accessToken", null);


              this.isLoading = true;

              try {
                  const response = await this.$apollo.mutate({
                      mutation: LOGIN_CONTINUE,
                      variables: {
                        email: loginData.username,
                        challengeName: loginData.challengeName,
                        session: loginData.session,
                        mfCode: this.smsCode
                      },
                      fetchPolicy: 'no-cache',
                      errorPolicy: 'all'
                  });
                  console.log('MFA response: ', response);

                  this.isLoading = false;

                  if (response.data.authenticateContinue) {
                    if (response.data.authenticateContinue.ycmdAuthenticate.accessToken && response.data.authenticateContinue.ycmdAuthenticate.idToken) {

                      const userRoles = JSON.parse(localStorage.userRoles);
                      // console.log('test user roles: ', userRoles);

                      // save user id
                      if (response.data.authenticateContinue.phoenixAuthenticate && response.data.authenticateContinue.phoenixAuthenticate.user_id) {
                        // console.log('setting user id to: ', response.data.authenticateContinue.phoenixAuthenticate.user_id);

                        localStorage.setItem("userID", response.data.authenticateContinue.phoenixAuthenticate.user_id);
                      }

                      let userFacilities = [];
                      for (let user in response.data.authenticateContinue.ycmdAuthenticate.roles) {
                        const currentRoleObj = response.data.authenticateContinue.ycmdAuthenticate.roles[user];
                        if (currentRoleObj.facilities) {
                            userFacilities = _.unionBy(userFacilities, currentRoleObj.facilities, 'id');
                        }
                        userRoles[currentRoleObj.role] = currentRoleObj;
                      }
                      console.log('USER FACILITIES: ', userFacilities);
                      console.log('USER ROLES: ', userRoles);

                      // save user facilities
                      localStorage.setItem("userFacilities", JSON.stringify(userFacilities));

                      // save user roles
                      localStorage.setItem("userRoles", JSON.stringify(userRoles));

                      // check if they have no roles
                      if (!response.data.authenticateContinue.ycmdAuthenticate.roles) {
                        this.currentDialog = 'noRoles';
                        this.dialog = true;
                        return;
                      }

                      this.$store.commit('profile/setPassword', {
                        password: ''
                      });

                      localStorage.setItem("accessToken", response.data.authenticateContinue.ycmdAuthenticate.accessToken);
                      localStorage.setItem("idToken", response.data.authenticateContinue.ycmdAuthenticate.idToken);
                      localStorage.setItem("refreshToken", response.data.authenticateContinue.ycmdAuthenticate.refreshToken);
                      localStorage.setItem("roles", JSON.stringify(response.data.authenticateContinue.ycmdAuthenticate.roles));
                      localStorage.setItem("firstName", response.data.authenticateContinue.ycmdAuthenticate.first_name);
                      localStorage.setItem("lastName", response.data.authenticateContinue.ycmdAuthenticate.last_name);

                      this.$store.commit('profile/setRoles', {
                        roles: response.data.authenticateContinue.ycmdAuthenticate.roles
                      });
                      // set name
                      this.$store.commit('profile/setMyInfo', {
                        firstName: response.data.authenticateContinue.ycmdAuthenticate.first_name,
                        lastName: response.data.authenticateContinue.ycmdAuthenticate.last_name
                      });
                      // Route based on roles
                      routeBasedOnRole(this.route);
                    } else {
                      // user is disabled or no token came back
                      this.currentDialog = 'disabled';
                      this.dialog = true;
                      return;
                    }
                  } else if (response.errors && response.errors.length > 0) {
                    // If an error came back
                    if (response.errors[0].message) {
                      // Check for special error messages
                      if (response.errors[0].errorType === 'NotAuthorizedException') {
                        // If your session has expired
                        this.currentDialog = 'codeExpired';
                        this.dialog = true;
                      }
                      this.mfaErrorMessage = response.errors[0].message;
                    } else {
                      this.mfaErrorMessage = "Error 2A. Invalid response.";
                    }
                  } else {
                    // data did not come back
                    console.log('loginStart data did not come back: ', response);
                    this.errorMessage = 'Authentication was not successful. Please try again.';
                  }
              } catch (e) {
                  this.isLoading = false;
                  this.errorMessage = e.message;
                  console.log('CAUGHT: There was an error logging in loginContinue: ', e);
              }

            },
            async resend2fa() {
              // Get other data from Vue-x
              const loginData = this.$store.getters['profile/getAuthenticateBegin'];

              this.mfaErrorMessage = '';
              this.isLoading = true;

              try {
                  const response = await this.$apollo.mutate({
                      mutation: LOGIN_START,
                      variables: {
                        email: loginData.username,
                        password: loginData.password
                      },
                      fetchPolicy: 'no-cache',
                      errorPolicy: 'all'
                  });
                  // console.log('login response: ', response);
                  //
                  this.isLoading = false;

                  if (response.data.authenticateBegin) {

                    this.$store.commit('profile/setAuthenticateBegin', {
                      session: response.data.authenticateBegin.Session,
                      challengeName: response.data.authenticateBegin.ChallengeName,
                      username: loginData.username
                    });

                    if (response.data.authenticateBegin.ChallengeName === 'NEW_PASSWORD_REQUIRED') {

                    } else {

                    }
                  } else if (response.errors && response.errors.length > 0) {

                  } else {
                    // data did not come back
                    console.log('loginStart data did not come back: ', response);
                  }
              } catch (e) {
                  this.isLoading = false;
                  console.log('CAUGHT: There was an error logging in *loginStart* : ', e);
              }
            },
            clearDialog() {
              this.dialog = false;
              this.currentDialog = '';
            },
            route(name, direction) {

                this.$router.push({
                    name: name, params: {
                        transition: direction
                    }
                }).catch(err => { console.log('router error: ', err) });
            }
        }
    }

</script>

<style scoped>



</style>
