<template>
  <v-card
    flat
  >
    <v-card-subtitle
      class="py-0 text-center"
    >
      {{local_description}}
    </v-card-subtitle>
    <v-card-text>
      <v-progress-linear
        :indeterminate="isIndeterminant"
        background-color="ycmd-light-gray"
        color="ycmd-dark-blue"
        height="10"
        v-model="local_current"
      />

    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: 'ycmdProgress',
    components: {
    },
    props: {
      progress: {
          type: Object,
          default: () => null
      },
      color: {
        type: String,
        default: null
      },
      job_id: {
        type: String,
        default: null
      },
    },
    data: () => ({
      local_current: null,
      local_max: null,
      local_min: 0,
      local_description: ''
    }),
    mounted() {
      if (this.progress) {
        this.local_min = this.progress.min
        this.local_max = this.progress.max
        this.local_current = this.percentage(this.progress.current,this.progress.max) 
        this.local_description = this.progress.description
      }
    },
    methods: {
      percentage(value, max) {
        console.log('value, max', value, max)
        if (value == max) {
          this.$emit('close')
        }
        return value * 100 / max
      }
    },
    computed: {
      ...mapGetters('data', ['globalEvent']),     
      isIndeterminant () {
        if (this.progress && this.progress.type === 'indeterminant') {
          return true
        }
        return false
      } 
    },
    watch: {
      /*
      globalEvent: {
          handler (val, oldVal) {
              console.log('globalevent', val)
              if (val.app === 'Report' && val.item === this.job_id) {
                  console.log('this.job', this.job)
                  

              }

          },
          deep: true
      },
      */ 
      progress: {
          handler (val, oldVal) {
            this.local_min = val.min
            this.local_max = val.max
            this.local_current = this.percentage(val.current,val.max) 
            this.local_description = val.description            
          },
          deep: true
      },            
    }
}
</script>
