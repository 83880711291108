import gql from 'graphql-tag'

export default gql`query billingInvoiceGet($group_id: ID, $id: ID, $page: Int, $nextToken: String) {
	billingInvoiceGet(group_id: $group_id, id: $id, page: $page, nextToken: $nextToken) {
		invoices {
			number
			amount
			id
			date_service_start
			date_service_end
			date_due
			date_added
			status
			past_due
			remaining
			usage
			amount_due
		}
		nextToken
  }
}`
