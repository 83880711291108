import gql from 'graphql-tag'

export default gql`query ycmdMessageGet($folder: Folder, $page: Int, $limit: Int) {
	ycmdMessageGet(folder: $folder, page: $page, limit: $limit)
    {
        total_count
        messages {
            id
            from_user_id
            from_user {
                id
                username
                first_name
                last_name
                middle_initial
                group_id
                email
            }
            patient {
                dob
                first_name
                last_name
            }
            contents
        }
    }
}`
