import dataClient from '@/graphql/clients/axios';

const state = {
  customerProfileMap: {},
  groups: []
};

const getters = {
  getCustomerProfileByGroupId: (state) => (id) => {
    if (state.customerProfileMap) {
        let profile = state.customerProfileMap[id]
        return profile                                     
    }
    return null
  },
  getGroups: state => state.groups,
};

const actions = {
  loadCustomerProfile: async ({commit, getters, dispatch}, payload) => {

    let groupId = payload.group_id

    let customerProfile = getters['getCustomerProfileByGroupId'](groupId)

    if (customerProfile) {
      return customerProfile
    }

    let params = {
      operation: 'customer_profile_get',
      parameters: [{
        id: 'group_id',
        value: groupId
      }]
    }

    let result = await dataClient('billingGet', params)

    if (result && result.customerProfiles && result.customerProfiles.length) {
      result.customerProfiles.map(profile => {
        commit('addCustomerProfile', profile)
      })  
    }

    customerProfile = getters['getCustomerProfileByGroupId'](groupId)

    return customerProfile
  },
  getAllGroups : async ({commit, getters, dispatch}, payload) => {
    let groups = getters.getGroups 
    if (groups && groups.length) {
      return groups
    }

    let result = await dataClient('phxGroups', {})

    groups = result.sort(function(a, b) {
      return a.name.trim().localeCompare(b.name.trim());
    });

    commit('setGroups', groups)

    return groups
  },

  addPaymentProfile: async ({commit, getters, dispatch}, payload) => {
    let params = {
      operation: 'add_payment_profile',
      parameters: [{
      }]
    }
    for (let prop in payload) {
      params.parameters.push({
        id: prop,
        value: typeof(payload[prop]) == 'object' ? JSON.stringify(payload[prop]) : payload[prop]
      })
    }

    let result = await dataClient('billingOperation', params)

    if (result && result.error) {
      return result
    }

    if (result && result.paymentProfiles && result.paymentProfiles.length)
    commit('addPaymentProfileToCustomerProfile', {
      groupId: payload.group_id,
      paymentProfile: result.paymentProfiles[0]
    })

    return result.paymentProfiles[0]

  },
  deletePaymentProfile: async ({commit, getters, dispatch}, payload) => {
    let params = {
      operation: 'delete_payment_profile',
      parameters: []
    }
    for (let prop in payload) {
      params.parameters.push({
        id: prop,
        value: typeof(payload[prop]) == 'object' ? JSON.stringify(payload[prop]) : payload[prop]
      })
    }

    let result = await dataClient('billingOperation', params)

    if (result.paymentProfiles && result.paymentProfiles.length && result.paymentProfiles[0].paymentProfileId == payload.paymentProfileId) {

      commit('deletePaymentProfileFromCustomerProfile', {
        groupId: payload.group_id,
        paymentProfileId: payload.paymentProfileId
      })
    }

    return result


  },
  makePayment: async ({commit, getters, dispatch}, payload) => {

    let params = {
      operation: 'make_payment',
      parameters: [{
      }]
    }
    for (let prop in payload) {
      params.parameters.push({
        id: prop,
        value: payload[prop]
      })
    }

    let result = await dataClient('billingOperation', params)

    return result
  },
};

const mutations = {
  setGroups (state, data) {
    state.groups = data;
  },  
  addCustomerProfile (state, profile) {
    state.customerProfileMap[profile.groupId] = profile
  },
  deletePaymentProfileFromCustomerProfile (state, payload) {

    let customerProfile = state.customerProfileMap[payload.groupId]
    if (customerProfile && customerProfile.paymentProfiles && customerProfile.paymentProfiles.length ) {
      let index = customerProfile.paymentProfiles.findIndex(p => {
        return p.paymentProfileId == payload.paymentProfileId
      })

      if (index >= 0) {
        customerProfile.paymentProfiles.splice(index, 1)
      }
    }
  },
  addPaymentProfileToCustomerProfile(state, payload) {

    let customerProfile = state.customerProfileMap[payload.groupId]
    if (customerProfile && Array.isArray(customerProfile.paymentProfiles)) {
      customerProfile.paymentProfiles.push(payload.paymentProfile)
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
