<template>

    <div >
        
        <v-select flat 
            v-model="selectedPaymentProfile"
            :label="label"
            :items="paymentProfiles"
            item-text="name"
            item-value="paymentProfileId"
            :background-color="backgroundColor"
            :outlined="outlined"
            :rules="rules"
            hide-details
        >
                
            <template v-slot:item="data">
                <v-container
                class="pa-0 ma-0"
                >        
                    <v-row
                        class="pa-0 ma-0"
                    >
                        <v-col cols="6"
                            class="pa-0 ma-0"
                        >
                            {{paymentType(data.item)}}: <span class=" fw-700">{{paymentNumber(data.item)}}</span>
                        </v-col>
                        <v-spacer />
                        <v-col
                            class="pa-0 ma-0 shrink"
                        >
                            {{expDate(data.item)}}
                        </v-col>
                    </v-row>

                </v-container>

            </template>
            <template v-slot:selection="data">
                <v-container
                class="pa-0 ma-0"
                >        
                    <v-row
                        class="pa-0 ma-0"
                    >
                        <v-col cols="6"
                            class="pa-0 ma-0"
                        >
                            {{paymentType(data.item)}}: <span class=" fw-700">{{paymentNumber(data.item)}}</span>
                        </v-col>
                        <v-spacer />
                        <v-col
                            class="pa-0 ma-0 shrink"
                        >
                            {{expDate(data.item)}}
                        </v-col>
                    </v-row>
                </v-container>

            </template>
        </v-select>
    </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

    export default {

        components: {
        },
        props: {
            rules: {
                type: Array,
                default: () => []
            },  
            value: {
                default: '',
                type: String
            },               
            paymentProfiles: {
                type: Array,
                default: () => []
            },  
            outlined: {
                default: false,
                type: Boolean
            },
            backgroundColor: {
                default: 'white',
                type: String
            },
            label: {
                default: 'Payment Methods',
                type: String
            },
        },
        data: () => ({
            //selectedLocation: ''
        }),
        created() {

        },
        async mounted() {

        },
        watch: {
            /*
            selectedLocation (newValue, oldValue) {
                this.$emit('input', newValue)
            }*/
        },
        methods: {
            paymentType(item) {
                switch (item.type) {
                    case 'creditCard':
                        return 'Credit Card'

                    default: 
                        return 'Unknown'
                }
            },
            paymentNumber(item) {
                switch (item.type) {
                    case 'creditCard':
                        return item.cardNumber

                    default: 
                        return 'Unknown'
                }
            },
            expDate(item) {
                switch (item.type) {
                    case 'creditCard':
                        return item.expirationDate

                    default: 
                        return 'Unknown'
                }
            }            
        },
        computed: {
            ...mapGetters(
                'emr',['getProviders','getPractices']
            ),   
            selectedPaymentProfile: {
                get: function() {
                    return this.value ;
                },
                set: function(newValue) {
                    this.$emit('input', newValue)
                }
            }, 

        }
    }
</script>
