<template>
    <div
        style="width: 100%"
        v-if="menu"
    >
        <h2>Prompt</h2>
        <ivr-prompt
            :language="language"
            :prompt="menu.introduction"
            label="Introduction"
            @changed="changed"
        />

        <ivr-prompt
            :language="language"
            :prompt="menu.message"
            label="Message"
            @changed="changed"
        />

        <ivr-options
            :language="language"
            :options="menu.options"
            :menus="menus"
            @changed="changed"
        />

    </div>
</template>
<script>
import ivrPrompt from './ivrPrompt'
import ivrOptions from './ivrOptions'

export default {
    components: {
        'ivr-prompt': ivrPrompt, 
        'ivr-options': ivrOptions      
    },
    props: {
        menu: {
            type: Object,
            default: () => {}
        },
        language: {
            type: String,
            default: ''
        },
        menus: {
            type: Array,
            default: () => {}
        }
    },
    methods: {
        changed (value) {
            this.$emit('changed', this.menus)
        }
    },
    computed: {
    },
    data: () => ({
    }),
    activated() {
    },
    mounted() {

    },
    watch: {
    }
}

</script>
