<template>

    <div class="view ycmd-blue-gradient">

        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="false"
                 :background-color="`#101622`"
                 :color="`white`"
                 :loader="`bars`">
        </loading>

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <div class="i-card__main pa-2">

                <div class="i-card__main__content white br-2 pa-2">

                    <div class="i-card__scrollable ycmd-light-gray full-width full-height">

                        <div>
                            <h1>{{report.name}}</h1>
                        </div>
                        
                        <v-tabs
                            center-active
                            light
                            v-model="tabSelected"
                        >
                            <v-tab
                                class="tab"
                                active-class="tabs--active"
                            >Report
                            </v-tab>
                            <v-tab
                                class="tab"
                                active-class="tabs--active"
                            >Schedules
                            </v-tab>

                            <v-tabs-items v-model="tabSelected">
                                <v-tab-item
                                >                        
                        
                                    <v-container
                                            fluid
                                        >
                                            <div
                                                style="max-heightxxx: 200px;overflow-yxxx: scroll;"
                                            >
                                            <v-form
                                                ref="frmValidateParameters"
                                            >

                                            <template
                                                v-for="(parameter) in report.parameters"
                                            >
                                                <report-parameter :parameter="parameter"
                                                                :key="`par_${parameter.id}_${refreshKey}`"
                                                                v-model="propertyValues[parameter.id]"
                                                                :users.sync="userlists[parameter.id]"
                                                                :list="findList(parameter)"
                                                                :variables="currentVariables"
                                                                @changed="parameterChanged"
                                                />
                                            </template>
                                            </v-form>
                                            </div>
                                            <v-row
                                                class="pl-0 pr-0 pb-0 pt-5 align-center"
                                            >
                                                <v-col
                                                cols="3" md="2"
                                                class="pa-0 ma-0"
                                                >
                                                </v-col>
                                                <v-col                                                
                                                class="pa-0 ma-0 pb-5 shrink"
                                                >
                                                    <v-btn
                                                        @click="run"
                                                        color="primary"
                                                        :disabled="!isValid || running"
                                                    >
                                                    Run</v-btn>
                                                </v-col>
                                                <v-col
                                                class="pa-0 ma-0 pb-5 shrink pl-5"
                                                >
                                                    <v-btn
                                                        @click="schedule"
                                                        color="primary"
                                                        :disabled="!isValid || running"
                                                    >
                                                    Schedule</v-btn>
                                                </v-col>

                                                <v-col
                                                class="pa-0 ma-0 pb-5 shrink pl-5"
                                                >
                                                    <v-select
                                                    :items="formats"
                                                    v-model="reportFormat"
                                                    label="Format"
                                                    style="min-width: 100px;"                                                    
                                                    ></v-select>
                                                </v-col>

                                            </v-row>

                                        </v-container>

                                </v-tab-item>
                                <v-tab-item>
                                        <schedules
                                            :schedules="report.schedules"
                                             @deleteSchedule="deleteSchedule"
                                            height="200px"
                                        />

                                </v-tab-item>
                            </v-tabs-items>

                        </v-tabs>                        

                        <v-divider />

                        <v-tabs
                            center-active
                            light
                            v-model="reportSelected"
                            icons-and-text
                        >
                            <v-tab
                                class="tab"
                                active-class="tabs--active"
                                v-for="(job, i) in jobs"
                                :loading="job.running"
                                :key="i"
                                :ref="`tab_job_${job.key}`"
                            >
                                <v-progress-circular
                                    v-if="job.running"
                                indeterminate
                                color="primary"
                                ></v-progress-circular>
                                <div
                                    v-else
                                >{{job && job.report ? job && job.report.report_name: 'Report'}}</div>                                
                            </v-tab>

                            <v-tabs-items v-model="reportSelected">
                                <v-tab-item
                                    v-for="(job,i) in jobs"
                                    :key="`tabitem_${job.job_id}_${i}`" 
                                    style="min-height: 400px;"                               
                                    :ref="`tabitem_${job.job_id}`" 
                                >
                                    <job-view :job="job"  />
                                </v-tab-item>
                            </v-tabs-items>

                        </v-tabs>                        
                    </div>
                </div>

            </div>

        </i-card>

        <schedules-modal v-model="openSchedule" @save="saveSchedule"
            :key="`schedule_${refreshSchedule}`"
        />
        <confirmation
            v-model="confirmScheduleDelete"
            title="Delete Schedule"
            subtitle="Confirm Schedule Delete"
            text="Are you sure you want to delete the schedule?"
            @click="confirmationClicked"
            :buttons="confirmationButtons"
            max-width="500"
        />

    </div>

</template>

<script>
    import { format } from 'date-fns';
    import Loading from 'vue-loading-overlay';
    import reportParameter from '../components/reportParameter';
    //import reportView from '../components/reportView';
    import jobView from '../components/jobView';

    //import ycmdReportRun from '@/graphql/queries/ycmdReportRun'
    import {mapGetters} from "vuex";
    import Schedules from '@/components/schedules';
    import SchedulesModal from '@/views/apps/reports/components/schedule';
    import ycmdReportScheduleCreate from '@/graphql/mutations/phoenix/ycmdReportScheduleCreate';
    import ycmdReportScheduleDelete from '@/graphql/mutations/phoenix/ycmdReportScheduleDelete';
    import Confirmation from '@/components/confirmation';
    import ycmdReportDefinitionGet from '@/graphql/queries/phoenix/ycmdReportDefinitionGet'

    export default {
        name: 'report',
        components: {
            Loading,
            'report-parameter': reportParameter,
            'job-view': jobView,
            'schedules': Schedules,
            'schedules-modal': SchedulesModal,
            confirmation: Confirmation
        },
        props: {
            params: {
                type: Object
            }
        },
        data() {
            return {
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                dialog: false,
                selectedModal: null,
                isLoading: false,
                errorMsg: '',
                successMsg: '',
                propertyValues: {},
                reportRun: {},
                refreshKey: 0,
                reportSelected: 0,
                tabSelected: 0,
                running: false,
                userlists: {},
                openSchedule: false,
                refreshSchedule: 0,
                confirmScheduleDelete: false,
                sending: false,
                deleteScheduleId: null,
                //refresh: 0,
                updatedDefinition: null,
                updatingDefinition: false,
                currentVariables: [],
                formats: ['xls', 'csv'],
                reportFormat: 'xls',
                submittedKey: '',
                jobs: [],
                moveTimer: 0
            }
        },
        activated() {

        },
        mounted() {
        },
        computed: {
            ...mapGetters('report', ['getReportDefinitions']),
            //...mapGetters('report', ['getJobs']),
            report () {
                let id = this.$route.query.id
                let rep
                if (this.getReportDefinitions && this.getReportDefinitions.length) {
                    rep = this.getReportDefinitions.find(r => {
                        return r.id === id
                    })
                }
                if (this.updatedDefinition) {
                    rep = this.updatedDefinition
                }
                console.log('rep', rep)
                let excelOnly = false

                if (rep && rep.reportProperties && rep.reportProperties.length) {
                    let obj = rep.reportProperties.find(p => {
                        return p.name == 'customExcelFormat' 
                    })
                    if (obj) {
                        excelOnly = obj.value.toLowerCase() === 'true' ? true : false
                    }
                }
                if (excelOnly) {
                    this.formats = ['xls']
                } else {
                    this.formats = ['xls', 'csv']
                }

                return rep ? rep : {}
            },
            isValid () {
                if (!this.report.id) {
                    return false
                }

                let valid = true
                this.report.parameters.map(p => {
                    if (p.required && p.data_type !== 'boolean') {
                        if (!this.propertyValues[p.id]) {
                            valid = false
                        }
                    }
                })
                return valid
            },
            listMap () {
                let map = {}
                if (this.report.lists && this.report.lists.length) {
                    this.report.lists.map(l => {
                        map[l.key] = l
                    })
                }
                return map
            },
            confirmationButtons () {
                return [
                    {caption: 'OK', disabled: this.sending},
                    {caption: 'Cancel', disabled: this.sending}
                ]
            },
        },
        methods: {
            /*
            moveTo(key) {
                console.log('moveTo', key)

                let ref = this.$refs[`tab_job_${key}`]

                if (ref && ref.length) {                                
                    setTimeout( () => {

                        let index = this.jobs.findIndex(j => {
                            return j.key === key
                        })

                        this.submittedKey = ''

                        //this.reportSelected = index
                        this.refreshKey++
                    }, 100)                                  
                } else {
                    this.moveTimer = setTimeout(() => {
                        this.moveTo(key)
                    }, 50)                                  
                }
            },
            */
            parameterChanged(value, formattedValue) {

                if (formattedValue) {
                    
                }
            },
            confirmationClicked (btn) {
                if (btn.caption === 'OK') {
                    this.doDeleteSchedule () 
                }
                this.confirmScheduleDelete = false
            },
            schedule () {
                this.openSchedule = true
            },
            deleteSchedule (scheduleId) {
                this.deleteScheduleId = scheduleId
                this.confirmScheduleDelete = true
            },
            async doDeleteSchedule () {
                const response = await this.$apollo.mutate({
                    mutation: ycmdReportScheduleDelete,
                    variables: {schedule_id: this.deleteScheduleId},
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all'
                });

                console.log('response', response)
                if (response && response.errors && response.errors.length) {
                    this.$toasted.error(`Error: ${response.errors[0].message}`);
                } else {
                    await this.$store.commit('report/removeSchedule',  
                    {
                        report_id: this.report.id,
                        schedule_id: this.deleteScheduleId
                    })
                    this.$toasted.success('Schedule deleted');
                }
                this.sending = false

            },
            findList(par) {
                if (par.select_list_key) {
                    return this.listMap[par.select_list_key]
                }
                return null
            },
            formatTime(dt,formatString) {
                return format(dt, formatString)
            },
            async saveSchedule (schedule) {
                let scheduleObject = {
                    report_id: this.report.id,
                    schedule_repeat: schedule.repeat,
                    dow: schedule.dow,
                    dom: schedule.dom,
                    time: schedule.time,
                    parameters: [],
                    format: this.reportFormat,
                    emails: schedule.emails
                }
                this.report.parameters.map(p => {
                    scheduleObject.parameters.push({
                        id: p.id,
                        value: this.propertyValues[p.id]
                    })
                })

                const response = await this.$apollo.mutate({
                    mutation: ycmdReportScheduleCreate,
                    variables: {schedule: scheduleObject},
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all'
                });

                console.log('response', response)

                if (response && response.data && response.data.ycmdReportScheduleCreate) {
                    await this.$store.commit('report/addSchedule',  
                    {
                        report_id: this.report.id,
                        schedule: JSON.parse(response.data.ycmdReportScheduleCreate.data)
                    })
                    this.$toasted.success('Schedule saved');
                    this.refreshSchedule++

                } else {
                        this.$toasted.error(`An error has occurred`);
                }

            },

            getParameters () {
                let parameters = []

                this.report.parameters.map(p => {
                    parameters.push({
                        id: p.id,
                        value: this.propertyValues[p.id]
                    })
                })

                return parameters
            },

            async run () {
                let parameters = this.getParameters()
                this.running = true

                this.submittedKey = `key_${(new Date()).getTime()}`

                let job = await this.$store.dispatch('report/submitReport', {
                        report_id: this.report.id,
                        parameters: parameters,
                        format: this.reportFormat,
                        key: this.submittedKey
                })

                this.jobs.push(job)

                this.running = false
                this.propertyValues = {}             

                
                setTimeout(() => {
                    this.reportSelected = this.jobs.length - 1
                }, 1000)             

                setTimeout(() => {
                    this.refreshKey++
                }, 3000)                                  
                
                //this.moveTo(this.submittedKey)

            }
        },
        watch: {

            propertyValues: {
                async handler (newValue, oldValue) {
                    this.$refs.frmValidateParameters.validate()
                    if (this.updatingDefinition) {
                        return
                    }

                    let count = 0
                    for (let prop in newValue) {
                        count++
                    }

                    if (count) {
                        let refreshParameters = false
                        this.updatingDefinition = true

                        if (this.report && this.report.reportProperties && this.report.reportProperties.length) {
                            let obj = this.report.reportProperties.find(p => {
                                return p.name == 'refreshParameters' 
                            })
                            if (obj) {
                                refreshParameters = obj.value.toLowerCase() === 'true' ? true : false
                            }
                        }
                        let variables = {
                            report_id: this.report.id,
                            parameters: this.getParameters()
                        }

                        let arr = []
                        variables.parameters.map(v => {
                            arr.push(v)
                        })
                        this.currentVariables = arr

                        if (refreshParameters) {
                            let response = await this.$apollo.query({
                                query: ycmdReportDefinitionGet,
                                variables: variables,
                                fetchPolicy: 'no-cache',
                                errorPolicy: 'all'
                            });

                            if (response.data && response.data.ycmdReportDefinitionGet && response.data.ycmdReportDefinitionGet.length) {
                                this.updatedDefinition = response.data.ycmdReportDefinitionGet[0]
                            }
                        }
                        this.updatingDefinition = false
                    }
                }, 
                deep: true
            },
            /*
            getJobs: {
                handler (val, oldVal) {

                    if (this.submittedKey) {
                        let index = val.findIndex(j => {
                            return j.key == this.submittedKey
                        })
                        if (index >= 0) {
                            let ref = this.$refs[`tabitem_${val[index].job_id}`]

                            if (ref) {                                
                                this.submittedKey = ''
                                setTimeout(() => {
                                    this.reportSelected = index
                                    this.refreshKey++
                                }, 1000)                                  
                            }
                        }
                    }

                },
                deep: true
            },
            */
        }
    }

</script>

<style scoped>

    .item-box {
        background-color: #fff;
        border-bottom: #177ad5 solid 1px;
    }

    .item-box.odd {
        background-color: #ECEFF1;
    }

    .item-box:hover {
        background-color: rgba(255, 255, 255, 0.8)
    }

</style>
