<template>

    <div class="d-flex fill-height ycmd-dark-blue full-width pa-2 br-2">

      <loading :active.sync="isLoading"
              :can-cancel="false"
              :is-full-page="false"
              :background-color="`#101622`"
              :color="`#637eb6`"
              :loader="`bars`">
      </loading>

      <div class="d-flex fill-height full-width flex-column">

            <div class="d-flex align-center white--text pa-2 pb-3">

                <div class="fs-16 fw-500">LOGIN</div>
                <v-spacer />
                <v-btn dark depressed small text class="fade-2" @click="route('forgotPassword', 'next')" color="white">Forgot Password?</v-btn>
            </div>

            <div class="d-flex flex-column fill-height full-width">

                    <div class="d-flex flex-column flex-grow-1 ycmd-medium-blue full-width pa-3 br-2">

                        <div class="pl-2 pt-3">
                            <v-text-field
                                    light solo dense flat
                                    v-model="username"
                                    name="input-username"
                                    :tabindex="1"
                                    background-color="white"
                                    v-on:keyup.enter="loginStart"
                                    label="Email"/>
                        </div>

                        <div class="pl-2">
                            <v-text-field
                                    solo light dense flat
                                    v-model="password"
                                    :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                                    :type="showPassword ? 'text' : 'password'"
                                    name="input-password"
                                    :tabindex="2"
                                    label="Password"
                                    background-color="white"
                                    v-on:keyup.enter="loginStart"
                                    @click:append="showPassword = !showPassword" />
                            <div v-if="errorMessage" style="background-color: red; color: white; padding: 5px;">{{errorMessage}}</div>
                        </div>

                    </div>

                    <div class="d-flex flex-column align-center">

                        <div class="full-width pa-1 pl-0 pr-0">
                            <v-btn dark block large depressed color="ycmd-green" @click="loginStart">Continue</v-btn>
                        </div>

                        <div class="d-flex full-width">
                            <!-- <div class="d-flex pt-2 pl-2" style="width: 50%">
                                <v-btn dark text depressed color="white" class="fade-2" @click="route('pcpoptin', 'up')">New Account</v-btn>
                            </div> -->
                            <div class="d-flex justify-center" style="width: 100%">
                                <v-btn dark text depressed color="white" class="fade-2 mt-1 white--text" style=""  @click="route('authFAQ', 'up')">FAQ</v-btn>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>

</template>

<script>

    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';

    import {refreshHandler} from '../../graphql/handler/refreshHandler';
    import LOGIN_START from '../../graphql/mutations/authenticateBegin';
    import TEST from '../../graphql/mutations/testPushNotification';

    import { routeBasedOnRole } from '../../methods/globalMethods';

    export default {
        name: 'login',
        data: () => ({
            isLoading: false,
            user: null,
            username: '',
            password: '',
            challengeName: '',
            session: '',
            showPassword: false,
            errorMessage: '',
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                emailMatch: () => ('The email and password you entered don\'t match')
            }
        }),
        components: {
          Loading
        },
        mounted() {
          // if you go to login page while still logged in bring them back to app
          routeBasedOnRole(this.route);
        },
        apollo: {
          $client: 'publicClient',
          loginStart: {
              query: () => LOGIN_START,
              variables() {
                  return {
                      email: this.username,
                      password: this.password
                  }
              },
              update(data) {
                // console.log('We got login data back!: ', data);
              },
              error(error) {
                console.log('There was an error logging in -loginStart-: ', error);
              },
              skip: true
          }
        },
        methods: {
            checkHasTokens() {
              const refreshToken = localStorage.getItem("refreshToken");
              const accessToken = localStorage.getItem("accessToken");
              const username = localStorage.getItem("username");
              if (!refreshToken || !accessToken || !username) {
                return false
              }
              return true;
            },
            async loginStart() {
                // We are setting the accesstoken to null here because we don't want access token header to send for login
                // localStorage.setItem("accessToken", null);
                //
                this.isLoading = true;

                try {
                    const response = await this.$apollo.mutate({
                        mutation: LOGIN_START,
                        variables: {
                          email: this.username,
                          password: this.password
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    console.log('login response: ', response);
                    //
                    this.isLoading = false;

                    if (response.data.authenticateBegin) {

                      const userRoles = {};

                      // authenticateResult
                      if (response.data.authenticateBegin.AuthenticateResult) {

                        // check phoenix result
                        if (response.data.authenticateBegin.AuthenticateResult.phoenixAuthenticate) {

                          // save phoenix token
                          localStorage.setItem("phoenixToken", response.data.authenticateBegin.AuthenticateResult.phoenixAuthenticate.token)

                          // loop over roles
                          for (let role in response.data.authenticateBegin.AuthenticateResult.phoenixAuthenticate.roles) {
                            const currentRole = response.data.authenticateBegin.AuthenticateResult.phoenixAuthenticate.roles[role];
                            userRoles[currentRole] = currentRole;
                          }

                        }

                      }

                      // save roles to local storage
                      localStorage.setItem("userRoles", JSON.stringify(userRoles));
                      // console.log('login user roles: ', JSON.parse(localStorage.userRoles))

                      // save email
                      localStorage.setItem("username", this.username);

                      this.$store.commit('profile/setAuthenticateBegin', {
                        session: response.data.authenticateBegin.Session,
                        challengeName: response.data.authenticateBegin.ChallengeName,
                        username: this.username
                      });
                      // Check if it's a created account by admin or user
                      if (response.data.authenticateBegin.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
                        this.route('newPass', 'next');
                        return;
                      } else if (response.data.authenticateBegin.ChallengeName === 'SMS_MFA') {
                        // successful login
                        this.$store.commit('profile/setPassword', {
                          username: this.username,
                          password: this.password
                        });
                        this.route('mfaCode', 'next');
                        return
                      } else {
                        console.log('routing')
                        routeBasedOnRole(this.route);
                        return
                      }
                      console.log('Login conditions not met');
                    } else if (response.errors && response.errors.length > 0) {
                      this.errorMessage = response.errors[0].message;
                    } else {
                      // data did not come back
                      console.log('loginStart data did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoading = false;
                    console.log('CAUGHT: There was an error logging in *loginStart* : ', e);
                }
            },
            async changePassword() {

                try {

                    const requiredFields =
                    {
                        email: this.username
                    };

                    // console.log(requiredFields);
                    //
                    // const user = await Auth.completeNewPassword(this.user, this.newPassword, requiredFields);
                    //
                    // console.log(user);
                }
                catch(e) {

                    console.log(e);
                }
            },
            route(name, direction) {

                this.$router.push({
                    name: name, params: {
                        transition: direction
                    }
                }).catch(err => { console.log('router error: ', err) });
            }
        }
    }

</script>

<style scoped>



</style>
