<template>

    <v-autocomplete
        v-model="localValue"
        :items="items"
        outlined
        dense
        chips
        small-chips
        :label="label"
        item-value="id"
        item-text="name"
        :multiple="!single"
        @change="changed"
        clearable
        :rules="rules"
        :search-input.sync="searchTerm"
        :loading="isLoading"
    >
        <template v-slot:append-item>
            <div v-intersect="endIntersect" />
        </template>    
    </v-autocomplete>

</template>

<script>
    import { mapGetters, mapMutations  } from 'vuex';
    import dataClient from '@/graphql/clients/axios';

    export default {
        name: 'searchBar',
        components: {
        },
        props: {
            label: {
                type: String,
                default: 'Select the users'
            },   
            value: {
                default: null
            },
            roles: {
                type: Array,
                default: () => []
            },  
            include_searching_user: {
                type: Boolean,
                default: false
            },  
            exclude_group_filter: {
                type: Boolean,
                default: false
            },  
            single: {
                type: Boolean,
                default: false
            },     
            rules: {
                type: Array,
                default: () => []
            },   
            filter: {
                type: Array,
                default: () => []
            },
            showGroup: {
                type: Boolean,
                default: false
            },
            additionalVariables: {
                type: Object,
                default: () => {}
            },
        },
        data: () => ({
            items: [],
            localValue: null,
            page: 1,
            searchTerm: null,
            groupId: null,
            isLoading: false
        }),
        async mounted() {
            if (!this.items || !this.items.length) {
                await this.loadUsers()
            }
            this.localValue = this.value
        },        
        methods: {
            ...mapMutations('report', ['setReferentialByKey']),

            endIntersect(entries, observer, isIntersecting) {

                if (isIntersecting) {
                    this.page += 1
                    this.loadUsers()
                }
            },

            matchesFilter(item, filterExpression) {
                //console.log('matchesFilter item', item)
                //console.log('matchesFilter filterExpression', filterExpression)
                switch (filterExpression.value) {
                    case '$EXISTS':
                        let retval = typeof(item[filterExpression.field]) == 'undefined' || item[filterExpression.field] === null   ? false : true
                        // console.log(`${item.last_name} ${item.first_name} ${item.id}  typeof ${filterExpression.field}`, item[filterExpression.field], typeof(item[filterExpression.field]), retval)
                        
                        return retval

                    default:
                        console.log('unknown filter expression', filterExpression)
                        break
                }
            },
            isFiltered(item, filter) {

                if (!filter || !filter.length) {
                    return false
                }

                for (let i = 0;i<filter.length;i++) {
                    if (this.matchesFilter(item, filter[i])) {
                        return false
                    }
                }

                return true
            },
            changed (params) {

                this.$emit('input', this.localValue)
                this.$emit('changed', this.localValue)

                let arr = this.single ? [this.localValue] : this.localValue

                arr.map(u => {
                    let key = `user_${u}`
                    if (!this.getReferentialByKey(key)) {
                        let user = this.items.find(i => {
                            return i.id === u
                        })
                        if (user) {
                            this.setReferentialByKey({key: key, value: user})
                        }
                    }
                })
            },
            getName(item) {   
                let n = `${item.last_name ? item.last_name : ''}, ${item.first_name ? item.first_name: ''}`
                if (n.length <= 2) {
                    n=`id: ${item.id}`
                }
                if (this.showGroup) {
                    if (item.group_name) {
                        return `${n}: ${item.group_name}`
                    }
                }

                return n
            },
            async loadUsers() {
                this.isLoading = true
                let variables = {
                        roles: this.roles,
                        include_searching_user: this.include_searching_user,
                        exclude_group_filter: this.exclude_group_filter,
                        limit: 100,
                        page: this.page,
                        search: this.searchTerm
                }
                if (this.additionalVariables) {
                    for (let prop in this.additionalVariables) {

                        if (prop === 'group_id') {
                            if (this.additionalVariables[prop]) {
                                variables.group_ids = [this.additionalVariables[prop]]
                            }
                        } else {
                            variables[prop] = this.additionalVariables[prop]
                        }
                    }
                }  

                //let users = await this.$store.dispatch('report/loadReferential', {query: 'ycmdUserSearch', variables: variables})
                let users = await dataClient('ycmdUserSearch', variables)
                console.log('users', users)

                let all = []

                if (this.page > 1) {
                    all = this.items
                }
                
                if (users && users.length) {
                    users.map(u => {
                        if (u.users && Array.isArray(u.users)) {
                            all.push(...u.users)
                        }
                    })
                }

                let filtered = []

                if (all && all.length) {
                    all.map(f => {
                        if (!this.isFiltered(f, this.filter)) {
                            f.name = this.getName(f)
                            filtered.push(f)
                        }
                    })
                }
/*
                let sorted = filtered.sort( (a,b) => {
                    let asortable = a.name// (a.first_name ? a.first_name : '') + (a.last_name ? a.last_name : '')
                    let bsortable = b.name // (b.first_name ? b.first_name : '') + (b.last_name ? b.last_name : '')
                    return asortable.localeCompare(bsortable)
                })
                */
                this.items = filtered //sorted
                this.isLoading = false

                this.$emit('update:users', filtered)
            }
        },
        computed: {
            ...mapGetters('report', ['getReferentialByKey']),
        },
        watch: {
            value (newValue, oldValue) {
                if (newValue !== this.localValue) {
                    this.localValue = newValue
                }
            },
            searchTerm (newValue, oldValue) {
                this.page = 1
                this.loadUsers()
            },

            additionalVariables: {
                async handler (newValue, oldValue) {                    
                    this.page = 1

                    await this.loadUsers()

                }, 
                deep: true
            },

        }
    }
</script>
