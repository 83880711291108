import { clearLocalStorage, route } from '../../methods/globalMethods';

function defaultErrorHandlerPhoenix(errors, toast) {
  if (errors && errors.length) {
      for (let error in errors) {
          const currentError = errors[error];
          console.log('current error: ', currentError);
          if (currentError.errorType === 'TokenExpiredError') {
              console.log('Your auth token has expired. Bringing you back to login.');
              // toast.error('Your session has expired. Please log in again.');
              // clearLocalStorage();
              // route('login', 'fade-in-transition');
              toast.error('Your session has expired. Please log in again.');
          }
      }
  } else {
    // no errors
    return true;
  }
}

export { defaultErrorHandlerPhoenix }
