<template>

  <v-layout column class="ycmd-black full-width full-height">

    <v-layout column align-center justify-center class="full-height white--text">

      <v-layout column justify-center align-center>
        <img src="/images/ycmdlogo_transparent.png" style="height:72px" />
        <div class="pt-3 fs-18 fw-300 pb-3">Clinical Communications, Simplified.</div>
        <v-btn dark round color="blue" @click="route('dashboard', 'fade-in-transition')">Get Started</v-btn>
      </v-layout>

    </v-layout>

  </v-layout>

</template>

<script>

  export default {
    name: 'splash',
    data () {
      return {

      }
    },
    methods: {
      route(name, transition) {

        this.$router.push({
          name: name, params: {
            transition: transition
          }
        }).catch(err => { console.log('router error: ', err) });
      }
    }
  }

</script>

<style scoped>



</style>
