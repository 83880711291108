// Initial state
const state = {
  auth: {
    session: '',
    challengeName: '',
    username: '',
    password: ''
  },
  tokens: {
    accessToken: '',
    idToken: '',
    refreshToken: '',
    tokenType: '',
    expiresIn: 0
  },
  myInfo: {
    firstName: localStorage.getItem("firstName"),
    lastName: localStorage.getItem("lastName")
  },
  roles: [],
  toDoPressed: false,
  billing_group: null,
};

const getters = {
  getAuthenticateBegin: state => state.auth,
  getTokens: state => state.tokens,
  getRoles: state => state.roles,
  getToDoPressed: state => state.toDoPressed,
  getFirstName: state => state.myInfo.firstName,
  getLastName: state => state.myInfo.lastName,
  billingGroupGetter: state => state.billing_group
};

const actions = {

};

const mutations = {

  setMyInfo(state, val) {
    state.myInfo.firstName = val.firstName;
    state.myInfo.lastName = val.lastName;
  },

  setToDoPressed(state, val) {
    // console.log('set todo to: ', val);
    state.toDoPressed = val;
  },

  change (state, profile) {
    state.current = profile;
  },

  setAuthenticateBegin(state, data) {
    [state.auth.session, state.auth.challengeName, state.auth.username] = [data.session, data.challengeName, data.username];
  },

  setPassword(state, data) {
    [state.auth.username, state.auth.password] = [data.username, data.password];
  },

  setTokens(state, data) {
    [state.tokens.accessToken, state.tokens.idToken, state.tokens.refreshToken, state.tokens.tokenType, state.tokens.expiresIn] = [data.accessToken, data.idToken, data.refreshToken, data.tokenType, data.expiresIn];
  },

  setRoles(state, data) {
    state.roles = data;
  },

  setBillingGroup (state, billingGroup) {
    state.billing_group = billingGroup;
  },


};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
