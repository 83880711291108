// import gql from 'graphql-tag'
//
// export default gql`mutation testPushNotification($token: String!, $service: PushNotificationService!, $message: String!, $title: String!, $action: PushNotificationAction!, $url: String) {
// 	testPushNotification(token: $token, service: $service, message: $message, title: $title, action: $action, url: $url) {
//
//   }
// }`

import gql from 'graphql-tag'

export default gql`mutation testPushNotification($token: String!, $service: PushNotificationService!, $message: String!, $title: String!, $action: PushNotificationAction!) {
	testPushNotification(token: $token, service: $service, message: $message, title: $title, action: $action)
}`
