import gql from 'graphql-tag'

export default gql`query ycmdChatRoomGet(
	$room_id: ID,
	$search: String,
	$participants: [ID],
	$meta: [NameValueInput],
	$tags: [String],
	$search_objects: [ChatObject],
	$room_types: [ChatRoomType],
	$limit: Int,
	$nextToken: String
) {
	ycmdChatRoomGet(
		room_id: $room_id,
		search: $search,
		participants: $participants,
		meta: $meta,
		tags: $tags,
		search_objects: $search_objects,
		room_types: $room_types,
		limit: $limit,
		nextToken: $nextToken
	) {
		rooms {
			id
			created_by_id
			date_created
			date_updated
			last_post_id
			last_post_time
    		last_post_text
    		last_post_type

			unread_count
			name
			room_type
			participants {
				date_joined
				date_left
				date_last_seen
				last_post_seen
				user_id
				first_name
				last_name
				creator
				title
				specialty
				group_id
				group_name
				online_status
				status
				ooo
			}
			meta {
				name
				value
			}
			tags
			deleted
		}
		contacts {
			user_id
			first_name
			last_name
			group_id
			group_name
			mobile_number
			title
			specialty
			roles
		}
		nextToken
	}
}`
