<template>

    <div class="view ycmd-blue-gradient">

        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="false"
                 :background-color="`#101622`"
                 :color="`white`"
                 :loader="`bars`">
        </loading>

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <div class="i-card__main pa-2">

                <div class="i-card__main__content white br-2 pa-2">
                    <div class="i-card__scrollable ycmd-light-gray full-width full-height">
                      <div class="pr-1 pa-2">

                          <div class="" style="display: flex; flex-direction: row; flex-wrap: wrap;">

                            <div class="ma-1" style="flex-grow: 2; min-width: 300px; max-width: 400px;">
                              <v-select light dense flat outlined
                                        :items="subtypeOptions"
                                        v-model="subtype"
                                        item-text="name"
                                        label="Type"
                                        return-object
                                        style="max-width: 400px;"
                                        background-color="white"
                                        class="small-input"/>

                              <v-select light dense flat outlined
                                        :items="notificationOptions"
                                        v-model="notification"
                                        item-text="name"
                                        label="Notification Options"
                                        return-object
                                        style="max-width: 400px;"
                                        background-color="white"
                                        class="small-input"/>

                                <v-text-field
                                        light dense flat outlined
                                        class="small-input"
                                        style="max-width: 400px;"
                                        v-model="title"
                                        label="Title"
                                        background-color="white" />

                                <v-textarea
                                        light dense flat outlined
                                        class="small-input"
                                        style="max-width: 400px;"
                                        auto-grow
                                        v-model="message"
                                        label="Message"
                                        background-color="white" />
                            </div>

                            <div style="flex-grow: 1; min-width: 5px; max-width: 20px;"></div>

                            <div class="ma-1" style="flex-grow: 2; min-width: 300px; max-width: 400px;">

                              <div class="d-flex" style="">
                                  <v-autocomplete
                                        light dense flat outlined
                                        v-model="selectedGroup"
                                        :disabled="!allGroups.length"
                                        :items="allGroups"
                                        :loading="isLoadingGroupsSearch"
                                        label="Search Group"
                                        item-value="id"
                                        item-text="name"
                                        style="max-width: 400px;"
                                        background-color="white"
                                        clearable
                                        return-object
                                  ></v-autocomplete>
                              </div>

                              <div class="d-flex" style="">
                                  <v-select
                                      :disabled="!selectedGroups.length"
                                      light dense flat outlined
                                      v-model="selectedGroups"
                                      :items="selectedGroups"
                                      label="Groups"
                                      item-text="name"
                                      multiple
                                      class=""
                                      style="max-width: 400px;"
                                      background-color="white"
                                      clearable
                                  ></v-select>
                              </div>

                              <div class="d-flex">
                                  <v-autocomplete
                                          light dense flat outlined
                                          v-model="selectedFacility"
                                          :items="facilitiesSearched"
                                          :loading="isLoadingFacilitySearch"
                                          :search-input.sync="searchFacility"
                                          item-value="id"
                                          item-text="name"
                                          label="Search Facility"
                                          style="max-width: 400px;"
                                          background-color="white"
                                          clearable
                                          return-object/>
                                  <!-- <v-btn depressed dark class="ycmd-blue ml-2" style=""
                                         @click="addFacility">Add
                                  </v-btn> -->
                              </div>

                              <v-select
                                  :disabled="!facilitiesSelected.length"
                                  light dense flat outlined
                                  v-model="facilitiesSelected"
                                  :items="facilitiesSelected"
                                  label="Facilities"
                                  item-text="name"
                                  multiple
                                  class=""
                                  style="max-width: 400px;"
                                  background-color="white"
                                  clearable
                              ></v-select>

                              <div class="d-flex">
                                <v-autocomplete
                                        light dense flat outlined
                                        v-model="selectedPCPProvider"
                                        :items="providersPCPAll"
                                        :loading="isLoadingPCPProviderSearch"
                                        :search-input.sync="searchProvidersPCP"
                                        :disabled="selectAllPCPs"
                                        item-value="id"
                                        :item-text="getNamePCP"
                                        label="Search PCP Users"
                                        style="max-width: 400px;"
                                        background-color="white"
                                        clearable
                                        return-object/>

                                <v-switch
                                  style="margin: 0; margin-top: 4px; margin-left: 4px;"
                                  v-model="selectAllPCPs"
                                  :label="`Send All`"
                                ></v-switch>
                                <!-- <v-btn depressed dark class="ml-2 ycmd-blue" @click="addProviderPCPID">Add</v-btn> -->
                              </div>

                              <v-select
                                :disabled="!providerPCPIDS.length"
                                v-if="!selectAllPCPs"
                                light dense flat outlined
                                v-model="providerPCPIDS"
                                :items="providerPCPIDS"
                                label="PCP Providers"
                                :item-text="getNamePCP"
                                multiple
                                class=""
                                style="max-width: 400px;"
                                background-color="white"
                                clearable
                              ></v-select>

                              <div class="d-flex">
                                <v-autocomplete
                                        light dense flat outlined
                                        v-model="selectedProvider"
                                        :items="providersAll"
                                        :loading="isLoadingProviderSearch"
                                        :search-input.sync="searchProviders"
                                        :disabled="selectAllProviders"
                                        item-value="id"
                                        :item-text="getName"
                                        label="Search Providers"
                                        style="max-width: 400px;"
                                        background-color="white"
                                        clearable
                                        return-object/>

                                <v-switch
                                  style="margin: 0; margin-top: 4px; margin-left: 4px;"
                                  v-model="selectAllProviders"
                                  :label="`Send All`"
                                ></v-switch>
                                <!-- <v-btn depressed dark class="ml-2 ycmd-blue" @click="addProviderID">Add</v-btn> -->
                              </div>

                              <v-select
                                :disabled="!providerIDS.length"
                                v-if="!selectAllProviders"
                                light dense flat outlined
                                v-model="providerIDS"
                                :items="providerIDS"
                                label="Providers"
                                :item-text="getName"
                                multiple
                                class=""
                                style="max-width: 400px;"
                                background-color="white"
                                clearable
                              ></v-select>

                              <v-btn depressed dark class="ycmd-blue" style="width: 180px;" @click="broadcast">Broadcast</v-btn>

                              <div v-if="errorMsg" class="mt-3" style="color: red">{{errorMsg}}</div>
                              <div v-if="successMsg" class="mt-3" style="color: green">{{successMsg}}</div>

                            </div>

                          </div>

                      </div>
                    </div>
                </div>
            </div>

        </i-card>

        <v-dialog v-model="dialog" max-width="330">

        </v-dialog>

    </div>

</template>

<script>

    import { format, parseISO } from 'date-fns';
    import Loading from 'vue-loading-overlay';

    import {defaultErrorHandler} from '../../../../graphql/handler/errorHandler';
    import {refreshHandler} from '../../../../graphql/handler/refreshHandler';
    import BROADCAST from '../../../../graphql/mutations/phxBulletinSendSystem';
    import SEARCH_GROUPS from '../../../../graphql/queries/phxGroups';
    import SEARCH_PROVIDERS from '../../../../graphql/queries/phxProviderSearch';
    import SEARCH_PROVIDERS_PCP from '../../../../graphql/queries/pcpProviders';
    import dataClient from '@/graphql/clients/axios';

    export default {
        name: 'providerLink',
        components: {
            Loading
        },
        props: {
            params: {
                type: Object
            }
        },
        data() {
            return {
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                dialog: false,
                isLoading: false,
                errorMsg: '',
                successMsg: '',

                isLoadingGroupsSearch: false,
                allGroups: [],
                selectedGroup: null,
                selectedGroups: [],

                isLoadingFacilitySearch: false,
                selectedFacility: null,
                searchFacility: null,
                facilitiesSearched: [],
                facilitiesSelected: [],

                isLoadingProviderSearch: false,
                selectedProvider: null,
                searchProviders: null,
                providersAll: [],
                providerIDS: [],

                isLoadingPCPProviderSearch: false,
                selectedPCPProvider: null,
                searchProvidersPCP: null,
                providersPCPAll: [],
                providerPCPIDS: [],

                selectAllProviders: false,
                selectAllPCPs: false,

                message: '',
                title: '',
                subtype: '',
                subtypeOptions: [
                  {
                    name: 'System Message',
                    val: 'system_message'
                  },
                  {
                    name: 'Software Update',
                    val: 'software_update'
                  },
                  {
                    name: 'System Maintenance',
                    val: 'system_maintenance'
                  }
                ],
                notificationOptions: [
                  {
                    name: 'Do Not Notify',
                    val: 'do_not_notify'
                  },
                  {
                    name: 'Notify Always',
                    val: 'notify_always'
                  },
                  {
                    name: 'User Default',
                    val: 'user_default'
                  }
                ],
                facility_ids: null,
                user_ids: null,
                notification: null,
            }
        },
        activated() {

        },
        mounted() {
          this.getGroups();
        },
        methods: {
            calculateUserIDList() {
              // if you selected all
              if (this.selectAllProviders) return [-1]
              if (this.providerIDS.length) {
                return this.providerIDS.map((item) => item.user_id)
              } else {
                return null
              }
            },
            calculateAccountType() {
              let sendingToPCPs = false;
              let sendingToProviders = false;
              let sendingToAll = false;

              if (this.selectedGroups.length || this.facilitiesSelected.length || this.providerIDS.length || this.selectAllProviders) {
                sendingToProviders = true;
              }
              if (this.providerPCPIDS.length || this.selectAllPCPs) {
                sendingToPCPs = true;
              }

              if (sendingToPCPs && sendingToProviders) {
                console.log('SENDING TO BOTH PROVIDERS AND PCPS');
                sendingToAll = true;
                return 'all'
              } else if (sendingToProviders) {
                console.log('SENDING TO PROVIDERS');
                return 'ycmd'
              } else if (sendingToPCPs) {
                console.log('SENDING TO PCPS');
                return 'pcp'
              } else {
                return false
              }
              // pcp
              // ycmd
              // all
            },
            getName(item) {
              // console.log(item.group);
              const groupName = item.group ? item.group.name : 'N/A'
              return `${item.name} - ${groupName}`
            },
            getNamePCP(item) {
              return `${item.first_name} ${item.last_name}`
            },
            clearAllFields() {
              this.notification = null;
              this.message = '';
              this.title = '';
              this.subtype = '';
              this.clearGroupAndBelow();
            },
            clearGroupAndBelow() {
              this.selectedGroup = null;
              this.selectedGroups = [];
              this.clearFacilityAndBelow();
            },
            clearFacilityAndBelow() {
              this.facilitiesSelected = [];
              this.facilitiesSearched = [];
              this.selectedFacility = null;
              this.searchFacility = null;

              this.clearProviders();
              this.clearProvidersPCP();
            },
            clearProviders() {
              this.providerIDS = [];
              this.providersAll = [];
              this.selectedProvider = null;
              this.searchProviders = null;
            },
            clearProvidersPCP() {
              this.selectedPCPProvider = null;
              this.searchProvidersPCP = null;
              this.providersPCPAll = [];
              this.providerPCPIDS = [];
            },
            addProviderID() {
                if (this.selectedProvider && this.selectedProvider.name) {
                    for (let provider in this.providerIDS) {
                        const currentProvider = this.providerIDS[provider];
                        if (currentProvider.name === this.selectedProvider.name) {
                          return;
                        }
                    }
                    this.providerIDS.push(this.selectedProvider);
                }
                this.selectedProvider = null;
                this.providersAll = [];
                this.$nextTick(() => {
                    this.selectedProvider = null;
                    this.providersAll = [];
                });
            },
            addProviderPCPID() {
                if (this.selectedPCPProvider && this.selectedPCPProvider.id) {
                    for (let provider in this.providerPCPIDS) {
                        const currentProvider = this.providerPCPIDS[provider];
                        if (currentProvider.id === this.selectedPCPProvider.id) {
                          return;
                        }
                    }
                    this.providerPCPIDS.push(this.selectedPCPProvider);
                }
                this.selectedProvider = null;
                this.providersPCPAll = [];
                this.$nextTick(() => {
                    this.selectedProvider = null;
                    this.providersPCPAll = [];
                });
            },
            addFacility() {
                if (this.selectedFacility && this.selectedFacility.id) {
                    for (let facility in this.facilitiesSelected) {
                        const currentFacility = this.facilitiesSelected[facility];
                        if (currentFacility.name === this.selectedFacility.name) {
                          return;
                        }
                    }
                    this.facilitiesSelected.push(this.selectedFacility);
                }
                // this.selectedFacility = null;
                // this.facilitiesSearched = [];
                this.$nextTick(() => {
                    this.selectedFacility = null;
                    this.facilitiesSearched = [];
                });
            },
            addGroup() {
              if (this.selectedGroup && this.selectedGroup.id) {
                  for (let group in this.selectedGroups) {
                      const currentGroup = this.selectedGroups[group];
                      if (currentGroup.name === this.selectedGroup.name) {
                        return;
                      }
                  }
                  this.selectedGroups.push(this.selectedGroup);
              }
              this.$nextTick(() => {
                  this.selectedGroup = null;
              });
            },
            async getGroups() {
                this.isLoadingGroupsSearch = true;
                try {
                    const response = await this.$apollo.query({
                        query: SEARCH_GROUPS,
                        variables: {
                            group_id: null
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoadingGroupsSearch = false;
                    // console.log('phxGroups response: ', response);
                    if (response.data.phxGroups) {
                        // console.log('setting all groups: ', response.data.phxGroups);
                        this.allGroups = response.data.phxGroups.sort(function(a, b) {
                           return a.name.trim().localeCompare(b.name.trim());
                        });
                    } else if (response.errors && response.errors.length > 0) {
                        if (response.errors[0].message) {
                            this.errorMsg = response.errors[0].message;
                        } else {
                            this.errorMsg = "Error 2E. Invalid response.";
                        }
                    } else {
                        // data did not come back
                        console.log('phxGroups data did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoadingGroupsSearch = false;
                    defaultErrorHandler(e);
                }
            },
            async getProviders(val) {
                this.isLoadingProviderSearch = true;
                try {
                    const response = await this.$apollo.query({
                        query: SEARCH_PROVIDERS,
                        variables: {
                            search: val
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoadingProviderSearch = false;
                    // console.log('pods response: ', response);
                    if (response.data.phxProviderSearch) {
                      this.providersAll = response.data.phxProviderSearch;
                    } else if (response.errors && response.errors.length > 0) {
                        if (response.errors[0].message) {
                            this.errorMsg = response.errors[0].message;
                        } else {
                            this.errorMsg = "Error 2E. Invalid response.";
                        }
                    } else {
                        // data did not come back
                        console.log('phxProviderSearch data did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoadingProviderSearch = false;
                    defaultErrorHandler(e);
                }
            },
            async getProvidersPCP(val) {
                this.isLoadingPCPProviderSearch = true;
                try {
                    const response = await this.$apollo.query({
                        query: SEARCH_PROVIDERS_PCP,
                        variables: {
                            search: val,
                            limit: 30
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoadingPCPProviderSearch = false;
                    // console.log('pcpProviders response: ', response);
                    if (response.data.pcpProviders) {
                      this.providersPCPAll = response.data.pcpProviders.providers;
                    } else if (response.errors && response.errors.length > 0) {
                        if (response.errors[0].message) {
                            this.errorMsg = response.errors[0].message;
                        } else {
                            this.errorMsg = "Error 2E. Invalid response.";
                        }
                    } else {
                        // data did not come back
                        console.log('pcpProviders data did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoadingPCPProviderSearch = false;
                    defaultErrorHandler(e);
                }
            },
            async getFacilities(val) {

                if (val.length >= 3) {
                    //
                    // Check token expiration
                    this.isLoadingFacilitySearch = true;
                    try {
                      let variables = {
                                search: val
                        }
                        let facilities = await dataClient('ycmdFacilitySearch', variables)
                        this.isLoadingFacilitySearch = false;

                        this.facilitiesSearched = facilities

                    } catch (e) {
                        this.isLoadingSearch = false;
                        defaultErrorHandler(e);
                    }

                }
            },
            async broadcast() {
                this.successMsg = '';
                this.errorMsg = '';
                this.isLoading = true;

                if (!this.calculateAccountType()) {
                  this.errorMsg = 'No users selected to send broadcast to.'
                  this.isLoading = false;
                  return;
                }

                // console.log('message: ', this.message);
                // console.log('title: ', this.title);
                // console.log('subtype: ', this.subtype.val);
                // console.log('group ids: ', this.selectedGroups.map((item) => item.id));
                // console.log('facility ids: ', this.facilitiesSelected.map((item) => item.id))
                // console.log('user raw: ', this.providerIDS);
                // console.log('user ids: ', this.providerIDS.map((item) => item.user_id));
                // console.log('notification: ', this.notification.val);
                console.log('USER IDS: ', this.calculateUserIDList());
                console.log('PCP USER IDS: ', this.providerPCPIDS.length ? this.providerPCPIDS.map((item) => item.user_id) : null);
                console.log('ACCOUNT TYPE: ', this.calculateAccountType());
                // return;
                try {
                    const response = await this.$apollo.mutate({
                        mutation: BROADCAST,
                        variables: {
                            message: this.message || null,
                            title: this.title || null,
                            subtype: this.subtype ? this.subtype.val : null,
                            group_ids: this.selectedGroups.length ? this.selectedGroups.map((item) => item.id) : null,
                            facility_ids: this.facilitiesSelected.length ? this.facilitiesSelected.map((item) => item.id) : null,
                            user_ids: this.calculateUserIDList(),
                            pcp_user_ids: this.providerPCPIDS.length ? this.providerPCPIDS.map((item) => item.user_id) : null,
                            notification: this.notification ? this.notification.val : null,
                            account_type: this.calculateAccountType()
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoading = false;
                    console.log('broadcast response: ', response);
                    if (response.data && response.data.phxBulletinSendSystem) {
                        this.clearAllFields();
                        this.successMsg = 'Message has been sent out';
                    } else if (response.errors && response.errors.length > 0) {
                        if (response.errors[0].message) {
                            this.errorMsg = response.errors[0].message;
                        } else {
                            this.errorMsg = "Error 2E. Invalid response.";
                        }
                    } else {
                        // data did not come back
                        console.log('phxGroups data did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoading = false;
                    defaultErrorHandler(e);
                }
            },
            changeView(view, params) {

                this.$emit('onChangeView', {view: view, params: params});
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
        },
        watch: {
          searchFacility(val) {

              if (!val) return;

              this.getFacilities(val);

          },
          searchProviders(val) {
            if (!val || val.length < 3) return
            this.getProviders(val);
          },
          searchProvidersPCP(val) {
            if (!val || val.length < 3) return
            this.getProvidersPCP(val);
          },
          selectedGroup(val) {
            this.addGroup();
          },
          selectedGroups(val) {
            this.clearFacilityAndBelow();
          },
          facilitiesSelected(val) {
            this.clearProviders();
          },
          selectedFacility(val) {
            this.addFacility();
          },
          selectedPCPProvider(val) {
            this.addProviderPCPID();
          },
          selectedProvider(val) {
            this.addProviderID();
          },
          selectAllProviders() {
            this.clearProviders();
          },
          selectAllPCPs() {
            this.clearProvidersPCP();
          }
        }
    }

</script>

<style scoped>

    .item-box {
        background-color: #fff;
        border-bottom: #177ad5 solid 1px;
    }

    .item-box.odd {
        background-color: #ECEFF1;
    }

    .item-box:hover {
        background-color: rgba(255, 255, 255, 0.8)
    }

    .row {
      margin: 0 -15px;
    }

</style>
