import gql from 'graphql-tag'

export default gql`query pcpMessages($facility_id: ID, $nextToken: String, $limit: Int, $visit_id: ID, $reference_id: ID, $id: ID, $search: String, $event_type_code: [PCPMessageType]) {
	pcpMessages(facility_id: $facility_id, nextToken: $nextToken, limit: $limit, visit_id: $visit_id, reference_id: $reference_id, id: $id, search: $search, event_type_code: $event_type_code) {
		messages {
			_facility
			_id
			event_recorded_time
			_visit
      message_sending_facility
			message_type
      message_trigger_event
			event_recorded_time
			event_type_code
			confirmed_time
			date_created
			patient_id
			patient_family_name
			patient_middle_name
			patient_given_name
			patient_dob
			patient_sex
			pcp_id
			pcp_degree
			pcp_prefix
			pcp_family_name
			pcp_given_name
			pcp_suffix
			visit_id
			visit_admit_reason
			diagnoses {
				coding_method
				code
				description
				time
				type
				priority
				clinician_family_name
				clinician_given_name
			}

			visit_attending {
        id
        family_name
        given_name
        middle_name
      }

			visit_admitting {
        id
        family_name
        given_name
        middle_name
      }

		}
		message_totals {
			message_types {
				message_type
				count
			}
		}
		nextToken
  }
}`
