<template>

  <v-layout column class="blue full-width full-height">

    <v-toolbar dark text class="transparent">

      <v-btn icon depressed @click="route('messageMenu', 'prev')">
        <v-icon color="white">menu</v-icon>
      </v-btn>

      <div class="fs-16 uppercase pl-2">Chat Room 1</div>

      <v-spacer/>

      <v-btn icon>
        <v-icon color="white">search</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon color="white">filter_list</v-icon>
      </v-btn>

    </v-toolbar>

    <v-layout class="overflow-none pl-1 pr-1" style="height: calc(100% - 56px) !important">

      <v-layout column class="relative white full-height overflow-none">

        <div v-bar :preventParentScroll="true" class="absolute full-height full-width white pr-3">

          <div>

            <v-layout column class="pa-3">

              <v-layout shrink class="full-width mt-3">

                <v-avatar size="36px">
                  <img src="/images/photos/man1.jpg" alt="Avatar">
                </v-avatar>

                <div class="blue-grey lighten-4 black--text  pt-2 pb-2 pl-3 pr-3 br-3 ml-3">Did you check on the
                  patient?
                </div>

              </v-layout>

              <v-layout shrink justify-end class="full-width mt-3">

                <div class="green white--text pt-2 pb-2 pl-3 pr-3 br-3 mr-3">Not yet. Please send me his chart.</div>

                <v-avatar size="36px">
                  <img src="/images/photos/woman1.jpg" alt="Avatar">
                </v-avatar>

              </v-layout>

              <v-layout shrink justify-end class="full-width mt-1">

                <div class="green white--text pt-2 pb-2 pl-3 pr-3 br-3 mr-3" style="margin-left: 52px">There is a
                  discrepancy in the patient information can you please verify?
                </div>

                <div style="min-width: 36px; min-height: 36px"/>

              </v-layout>

              <v-layout shrink class="full-width mt-3">

                <v-avatar size="36px">
                  <img src="/images/photos/man1.jpg" alt="Avatar">
                </v-avatar>

                <div class="blue-grey lighten-4 black--text  pt-2 pb-2 pl-3 pr-3 br-3 ml-3">Sent</div>

              </v-layout>

              <v-layout shrink class="full-width mt-1">

                <div style="min-width: 36px; min-height: 36px"/>

                <div class="blue-grey lighten-4 black--text  pt-2 pb-2 pl-3 pr-3 br-3 ml-3">Have you reviewed in yet?
                </div>

              </v-layout>

              <v-layout shrink justify-end class="full-width mt-3">

                <div class="green white--text pt-2 pb-2 pl-3 pr-3 br-3 mr-3">Sorry I was busy...</div>

                <v-avatar size="36px">
                  <img src="/images/photos/woman1.jpg" alt="Avatar">
                </v-avatar>

              </v-layout>

              <v-layout shrink justify-end class="full-width mt-1">

                <div class="green white--text pt-2 pb-2 pl-3 pr-3 br-3 mr-3" style="margin-left: 52px">Can this wait? I
                  have a patient that needs immediate attention..
                </div>

                <div style="min-width: 36px; min-height: 36px"/>

              </v-layout>

              <v-layout shrink class="full-width mt-3">

                <v-avatar size="36px">
                  <img src="/images/photos/man1.jpg" alt="Avatar">
                </v-avatar>

                <div class="blue-grey lighten-4 black--text  pt-2 pb-2 pl-3 pr-3 br-3 ml-3">Unfortunately it can't.... I
                  will you just forward the request through the messaging platform.
                </div>

              </v-layout>

              <v-layout shrink class="full-width mt-1">

                <div style="min-width: 36px; min-height: 36px"/>

                <div class="blue-grey lighten-4 black--text  pt-2 pb-2 pl-3 pr-3 br-3 ml-3">Thanks for you time</div>

              </v-layout>

              <v-layout shrink justify-end class="full-width mt-3">

                <div class="green white--text pt-2 pb-2 pl-3 pr-3 br-3 mr-3">Sorry I could not be of more assistance. I
                  am swamped!
                </div>

                <v-avatar size="36px">
                  <img src="/images/photos/woman1.jpg" alt="Avatar">
                </v-avatar>

              </v-layout>

            </v-layout>

          </div>

        </div>

        <v-layout shrink class="pt-2" style="border-top: solid 1px #e6e6e6">

          <v-btn icon>
            <v-icon color="blue">add</v-icon>
          </v-btn>

          <v-text-field solo text hide-details background-color="blue-grey lighten-5" class="mb-3"/>

          <v-btn icon>
            <v-icon color="blue">mood</v-icon>
          </v-btn>

          <v-btn icon>
            <v-icon color="blue">send</v-icon>
          </v-btn>

        </v-layout>

      </v-layout>

    </v-layout>

  </v-layout>

</template>

<script>

  export default {
    name: 'chat',
    data() {
      return {}
    },
    methods: {
      route(name, transition) {

        this.$router.push({
          name: name, params: {
            transition: transition
          }
        }).catch(err => { console.log('router error: ', err) });
      }
    }
  }

</script>

<style scoped>


</style>
