import gql from 'graphql-tag'

export default gql`mutation authenticateContinue($email: String!, $challengeName: String!, $session: String!, $mfCode: String, $new_password: String) {
	authenticateContinue(email: $email, challengeName: $challengeName, session: $session, mfCode: $mfCode, new_password: $new_password) {
    ycmdAuthenticate {
			accessToken
			tokenType
			refreshToken
			idToken
			roles {
				role
				role_label
				group {
					id
					name
				}
				facilities {
					id
					name
				}
			}
			first_name
			last_name
    }
    phoenixAuthenticate {
			user_id
			token
    }
  }
}`
