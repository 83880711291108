<template>

    <div class="view ycmd-blue-gradient">

        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="false"
                 :background-color="`#101622`"
                 :color="`white`"
                 :loader="`bars`">
        </loading>

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <div class="i-card__main pa-2">

                <div class="i-card__main__content white br-2 pa-2">

                    <div class="i-card__scrollable ycmd-light-gray full-width full-height">
                        <div class="pr-1 pa-2">

                            <div class="d-flex">
                              <label>This script has been decremented</label>
                            </div>
                            
                              <!-- <v-text-field
                                      solo light dense flat outlined
                                      class="small-input"
                                      style="max-width: 400px;"
                                      v-model="currentProviderID"
                                      label="Type the provider ID here"
                                      background-color="white"
                                      v-on:keyup.enter="() => addProviderID()" /> -->
<!--                                      
                            <v-autocomplete
                                    solo light dense flat outlined
                                    v-model="searchedProvider"
                                    :items="providersAll"
                                    :loading="isLoadingSearch"
                                    :search-input.sync="searchProviders"
                                    item-value="id"
                                    :item-text="getName"
                                    color="white"
                                    label="Search Providers"
                                    style="max-width: 500px;"
                                    clearable
                                    v-on:keyup.enter="() => addProviderID()"
                                    return-object/>
                              <v-btn depressed dark class="ml-3 ycmd-blue" @click="addProviderID">ADD</v-btn>
                            </div>

                            <v-select
                              light solo dense flat outlined
                              v-model="providerIDS"
                              :items="providerIDS"
                              chips
                              label="Providers"
                              :item-text="getName"
                              multiple
                              class="mt-2"
                              style="max-width: 500px;"
                              clearable
                            ></v-select>

                            <v-btn depressed dark class="ycmd-blue" style="width: 180px;" @click="removeFromChat">Remove From Chat</v-btn>
                            <v-btn v-if="responseLogs" depressed dark class="ycmd-blue mt-2" style="width: 150px;" @click="viewLogs">View Logs</v-btn>

                            <div v-if="errorMsg" class="mt-3" style="color: red">{{errorMsg}}</div>
                            <div v-if="successMsg" class="mt-3" style="color: green">{{successMsg}}</div>
-->
                        </div>
                    </div>

                </div>
            </div>

        </i-card>

        <v-dialog v-model="dialog" max-width="330">

          <v-card color="ycmd-black" dark style="">
            <div class="fs-18 pa-2">
              Logs
            </div>

              <div
                     style="max-height: 400px"
                     class="overflow-y-auto"
              >
                <div class="d-flex flex-column align-start flex-grow-1 pa-2" v-for="(response, i) in responseLogs" :key="i">
                  <div class="">
                    <span style="font-weight: 600">CHAT:</span> {{response}}
                  </div>
                </div>
            </div>

          </v-card>

        </v-dialog>

    </div>

</template>

<script>

    import { format, parseISO } from 'date-fns';
    import Loading from 'vue-loading-overlay';

    import {defaultErrorHandler} from '../../../../graphql/handler/errorHandler';
    import {refreshHandler} from '../../../../graphql/handler/refreshHandler';
    import REMOVE_FROM_CHAT from '../../../../graphql/mutations/phxProviderRemoveFromChat';
    import SEARCH_PROVIDERS from '../../../../graphql/queries/phxProviderSearch';

    export default {
        name: 'providerLink',
        components: {
            Loading
        },
        props: {
            params: {
                type: Object
            }
        },
        data() {
            return {
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                dialog: false,
                isLoading: false,
                responseLogs: null,
                currentProviderID: '',
                providerIDS: [],
                errorMsg: '',
                successMsg: '',

                searchProviders: null,
                searchedProvider: null,
                providersAll: [],
                isLoadingSearch: false
            }
        },
        activated() {

        },
        mounted() {

        },
        watch: {
          searchProviders(val) {
            if (!val || val.length < 3) return
            this.getProviders(val);
          }
        },
        methods: {
            getName(item) {
              // console.log(item.group);
              const groupName = item.group ? item.group.name : 'N/A'
              return `${item.name} - ${groupName}`
            },
            addProviderID() {
              if (!this.searchedProvider) return;
              this.providerIDS.push(this.searchedProvider);
              this.searchedProvider = null;
              this.currentProviderID = '';
            },
            viewLogs() {
              this.dialog = true;
            },
            async getProviders(val) {
                this.isLoadingSearch = true;
                try {
                    const response = await this.$apollo.query({
                        query: SEARCH_PROVIDERS,
                        variables: {
                            search: val
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoadingSearch = false;
                    console.log('getProviders response: ', response);
                    if (response.data.phxProviderSearch) {
                      this.providersAll = response.data.phxProviderSearch;
                    } else if (response.errors && response.errors.length > 0) {
                        if (response.errors[0].message) {
                            this.errorMsg = response.errors[0].message;
                        } else {
                            this.errorMsg = "Error 2E. Invalid response.";
                        }
                    } else {
                        // data did not come back
                        console.log('phxProviderSearch data did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoadingSearch = false;
                    defaultErrorHandler(e);
                }
            },
            async removeFromChat() {
                this.errorMsg = '';
                this.successMsg = '';
                this.responseLogs = null;
                this.isLoading = true;
                try {
                    const response = await this.$apollo.mutate({
                        mutation: REMOVE_FROM_CHAT,
                        variables: {
                          provider_ids: this.providerIDS ? this.providerIDS.map((item) => item.provider_id) : null
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoading = false;
                    console.log('response: ', response);
                    if (response.data.phxProviderRemoveFromChat) {
                      console.log('Successfully removed from chat... data: ', response.data.phxProviderRemoveFromChat);
                      this.successMsg = 'Successfully removed from chat';
                      this.responseLogs = response.data.phxProviderRemoveFromChat;
                    } else if (response.errors && response.errors.length > 0) {
                      if (response.errors[0].message) {
                        this.errorMsg = response.errors[0].message;
                      } else {
                        this.errorMsg = "Error 2E. Invalid response.";
                      }
                    } else {
                      // data did not come back
                      console.log('phxProviderRemoveFromChat data did not come back: ', response);
                      this.errorMsg = 'Server error';
                    }
                } catch (e) {
                    this.isLoading = false;
                    defaultErrorHandler(e);
                }
            },
            changeView(view, params) {

                this.$emit('onChangeView', {view: view, params: params});
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
        }
    }

</script>

<style scoped>

    .item-box {
        background-color: #fff;
        border-bottom: #177ad5 solid 1px;
    }

    .item-box.odd {
        background-color: #ECEFF1;
    }

    .item-box:hover {
        background-color: rgba(255, 255, 255, 0.8)
    }

</style>
