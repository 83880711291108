import {createAuthLink} from 'aws-appsync-auth-link';
import {SubscriptionHandshakeLink} from "aws-appsync-subscription-link/lib/subscription-handshake-link";
import {NonTerminatingHttpLink} from "aws-appsync-subscription-link/lib/non-terminating-http-link";

import {ApolloClient, ApolloLink, InMemoryCache, from, createHttpLink, HttpLink, split} from '@apollo/client/core';
import {setContext} from '@apollo/client/link/context'
import {getMainDefinition} from '@apollo/client/utilities';

//import Vue from 'vue'
import {onError} from '@apollo/client/link/error';
//import {refreshHandler} from '../handler/refreshHandlerPCP';
import {logout} from '@/methods/globalMethods';

const apiKey = process.env.VUE_APP_APPSYNC_API_KEY;

console.log('process.env.VUE_APP_APPSYNC_URL', process.env.VUE_APP_APPSYNC_URL)

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_APPSYNC_URL
});

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache'
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
    mutate: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    }
}

const errorLink = onError(({graphQLErrors, networkError}) => {

    if (graphQLErrors) {

        graphQLErrors.map(({ errorType, message, path }) => {

            console.error(`[GraphQL error]: ErrorType: ${errorType}, Message: ${ message }, Path: ${path}`)

            switch (errorType) {
                case 'NoAuthToken':
                case 'TokenExpiredError':
                case 'UnauthorizedException':
                    console.log("LOGGING OUT...PCP TOKEN EXPIRED");
                    // Vue.toasted.error(`Session expired... Please log in again`);
                    logout();
                    break;
            }

        });

    }

    if (networkError) {
        console.error(`[Network error (pcp)]: ${JSON.stringify(networkError)}`)
    }

});

const authLink = setContext(async (_, {headers}) => {
    //const refreshHandlerResponse = await refreshHandler();
    const token = localStorage.accessToken;

    try {
        return {
            headers: {
                ...headers,
                authorization: token
            },
        };
    }
    catch (e) {
        console.log('authLink caught error: ', e);
        return {
            headers: {
                ...headers,
                'x-api-key': apiKey
            },
        };
    }
});

const wsLink = ApolloLink.from([
    new NonTerminatingHttpLink("subsInfo", {uri: process.env.VUE_APP_APPSYNC_URL}, true),
    new SubscriptionHandshakeLink("subsInfo")
]);

const pcpLink = ApolloLink.from([
    errorLink,
    authLink,
    split(
        (operation) => {
            const {query} = operation;
            const definition = getMainDefinition(query);
            return definition.kind === "OperationDefinition" && definition.operation === "subscription";
        },
        wsLink,
        httpLink
    )
]);

const pcpClient = new ApolloClient({
    link: pcpLink,
    cache: new InMemoryCache({addTypename: false}),
    defaultOptions: defaultOptions
});

export default pcpClient;
