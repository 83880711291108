import router from '../router'
import SubscriptionService from '@/methods/subscriptionInstance'
export const removePropertiesFromObject =  (obj, propertiesArray) => {
  for (let prop in obj) {
    let pToDelete = propertiesArray.find(p => {
      return p === prop
    })
    if (pToDelete) {
      delete obj[prop]
    } else if (typeof (obj[prop]) === 'object') {
      removePropertiesFromObject( obj[prop], propertiesArray)
    }
  }
}

export function clearLocalStorage() {
    // keep these ones
    const profilePicutre = localStorage.getItem("profilePicture");

    localStorage.clear();

    // re-set items
    localStorage.setItem("profilePicture", profilePicutre);
}

export function checkHasTokensPCP() {
  const refreshToken = localStorage.getItem("refreshToken");
  const accessToken = localStorage.getItem("accessToken");
  const username = localStorage.getItem("username");
  if (!refreshToken || !accessToken || !username) {
    return false
  }
  return true;
}

export function checkHasTokensPhoenix() {
  const phoenixToken = localStorage.getItem("phoenixToken");
  if (!phoenixToken) {
    return false
  }
  return true;
}

export function routeBasedOnRole(router) {
  console.log('localStorage.userRoles: ', localStorage.userRoles);
  if (!localStorage.userRoles) return;

  function routeToLogin() {
    clearLocalStorage();
    router('login', 'fade-in-transition');
  }

  if (JSON.parse(localStorage.userRoles).pcp_admin) {
    if (!checkHasTokensPCP) { routeToLogin(); return; }
    router('SCRIPTS.link-providers', 'fade-in-transition');
    return;
  } else if (JSON.parse(localStorage.userRoles).pcp_group_admin) {
    if (!checkHasTokensPCP) { routeToLogin(); return; }
    router('ADMIN.providers', 'fade-in-transition');
    return;
  } else if (JSON.parse(localStorage.userRoles)['member.admin']) {
    if (!checkHasTokensPhoenix) { routeToLogin(); return; }
    router('GROUP.inbox', 'fade-in-transition');
    return;
  } else if (JSON.parse(localStorage.userRoles).member) {
    if (!checkHasTokensPhoenix) { routeToLogin(); return; }
    router('GROUP.inbox', 'fade-in-transition');
    return;
  } else if (JSON.parse(localStorage.userRoles).pcp_facility_admin) {
    if (!checkHasTokensPCP) { routeToLogin(); return; }
    router('ADMIN.providers', 'fade-in-transition');
    return;
  } else if (JSON.parse(localStorage.userRoles).pcp_analyst) {
    if (!checkHasTokensPCP) { routeToLogin(); return; }
    router('ADMIN.providers', 'fade-in-transition');
    return;
  } else if (JSON.parse(localStorage.userRoles).pcp_user) {
    if (!checkHasTokensPCP) { routeToLogin(); return; }
    router('PCPUSER.notifications', 'fade-in-transition');
    return;
  } else {
    if (!checkHasTokensPCP) { routeToLogin(); return; }
    // router('ADMIN.providers', 'fade-in-transition');
    console.log('WARNING: Role is not on parse list: ', localStorage.userRoles);
    routeToLogin();
  }

}

export const parsePcpStatus = {
  "A01": "Admitted",
  "A02": "Patient Transfer",
  "A03": "Discharged",
  "A04": "Registered",
  "A05": "Preadmit a Patient",
  "A06": "Outpatient to Inpatient",
  "A07": "Inpatient to Outpatient",
  "A08": "Update patient information",
  "A11": "Cancel admission ",
  "A12": "Cancel transfer",
  "A13": "Cancel discharge",
  "A17": "Swap patients",
  "A18": "Merge Records",
  "A23": "Delete a patient record",
  "A31": "Update Person ",
  "A35": "Change Patient Account Number",
  "A36": "Medical record number change",
  "A40": "Merge two Patients based on internal patient id"
}

export function route(name, transition) {
    router.push({
        name: name,
        params: {
            transition: transition || 'fade-in-transition'
        }
    }).catch(err => { console.log('router error: ', err) });
}


export function logout() {

  //save whether we are truly logged in
  let refresh = false
  if (localStorage.accessToken) {
      refresh = true
  }

  console.log("TERMINATING SUBSCRIPTIONS...");

  SubscriptionService.terminateAll();

  console.log("LOGGING OUT...");

  clearLocalStorage();

  console.log("CLEARING LOCAL STORAGE...");


  route('login', 'fade-in-transition')

  if (refresh) {
      location.reload();
  }
}