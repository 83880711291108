<template>

    <div class="view ycmd-blue-gradient">

        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="false"
                 :background-color="`#101622`"
                 :color="`white`"
                 :loader="`bars`">
        </loading>

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <div class="i-card__main pa-2">

                <div class="i-card__main__content white br-2 pa-2">

                    <div class="i-card__scrollable ycmd-light-gray full-width full-height">
                        <div class="pr-1 pa-2">

                            <div class="d-flex flex-row align-start flex-grow-1">
                              <v-text-field
                                      solo light dense flat outlined
                                      class="small-input"
                                      style="max-width: 400px;"
                                      v-model="cannedResponse"
                                      label="Type the canned response here"
                                      background-color="white" />
                              <v-btn depressed dark class="ycmd-blue ml-2" style="width: 60px;" @click="addCannedResponse">Add</v-btn>
                            </div>

                            <div class="d-flex flex-row align-start flex-grow-1">
                              <v-text-field
                                      solo light dense flat outlined
                                      class="small-input"
                                      style="max-width: 400px;"
                                      v-model="cannedResponseID"
                                      label="Type the canned response ID here"
                                      background-color="white" />
                              <v-btn depressed dark class="ycmd-blue ml-2" style="width: 60;" @click="removeCannedResponse">Remove</v-btn>
                            </div>

                            <v-btn depressed dark class="ycmd-blue mt-4" style="width: 220px;" @click="getCannedResponses">View Canned Responses</v-btn>

                            <div v-if="errorMsg" class="mt-3" style="color: red">{{errorMsg}}</div>
                            <div v-if="successMsg" class="mt-3" style="color: green">{{successMsg}}</div>

                        </div>
                    </div>

                </div>
            </div>

        </i-card>

        <v-dialog v-model="dialog" max-width="330">

          <v-card color="ycmd-black" dark style="">
            <div class="fs-18 pa-2">
              Canned Responses
            </div>

              <div
                     style="max-height: 400px"
                     class="overflow-y-auto"
              >
                <div class="d-flex flex-column align-start flex-grow-1 pa-2" v-for="(response, i) in responses" :key="i">
                  <div class="">
                    <span style="font-weight: 600">ID:</span> {{response.id}}
                  </div>
                  <div class="">
                    <span style="font-weight: 600">Response:</span> {{response.text}}
                  </div>
                </div>
            </div>

          </v-card>

        </v-dialog>

    </div>

</template>

<script>

    import { format, parseISO } from 'date-fns';
    import Loading from 'vue-loading-overlay';

    import {defaultErrorHandler} from '../../../../graphql/handler/errorHandler';
    import {refreshHandler} from '../../../../graphql/handler/refreshHandler';
    import ADD_CANNED_RESPONSE from '../../../../graphql/mutations/phxCannedResponseAdd';
    import REMOVE_CANNED_RESPONSE from '../../../../graphql/mutations/phxCannedResponseDelete'
    import GET_CANNED_MESSAGES from '../../../../graphql/queries/phxCannedResponseGet'

    export default {
        name: 'cannedResponse',
        components: {
            Loading
        },
        props: {
            params: {
                type: Object
            }
        },
        data() {
            return {
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                dialog: false,
                isLoading: false,
                responses: [],
                cannedResponse: '',
                cannedResponseID: '',
                errorMsg: '',
                successMsg: ''
            }
        },
        activated() {

        },
        mounted() {

        },
        methods: {
            async addCannedResponse() {
                this.errorMsg = '';
                this.successMsg = '';
                // check if empty
                if (!this.cannedResponse) {
                  this.errorMsg = 'Please enter a canned response';
                  return;
                }
                this.isLoading = true;
                try {
                    const response = await this.$apollo.mutate({
                        mutation: ADD_CANNED_RESPONSE,
                        variables: {
                          canned_response: this.cannedResponse
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoading = false;
                    // console.log('response: ', response);
                    if (response.data.phxCannedResponseAdd) {
                      this.successMsg = 'Successfully added canned response';
                      this.cannedResponse = '';
                    } else if (response.errors && response.errors.length > 0) {
                      if (response.errors[0].message) {
                        this.errorMsg = response.errors[0].message;
                      } else {
                        this.errorMsg = "Error 2E. Invalid response.";
                      }
                    } else {
                      // data did not come back
                      console.log('phxCannedResponseAdd data did not come back: ', response);
                      this.errorMsg = 'Server error';
                    }
                } catch (e) {
                    this.isLoading = false;
                    defaultErrorHandler(e);
                }
            },
            async getCannedResponses() {
                this.errorMsg = '';
                this.successMsg = '';
                this.isLoading = true;
                try {
                    const response = await this.$apollo.query({
                        query: GET_CANNED_MESSAGES,
                        variables: {
                          canned_response_id: null
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    // console.log('canned responses: ', response);
                    if (response.data.phxCannedResponseGet) {
                      // this.successMsg = 'Successfully added canned response';
                      this.responses = response.data.phxCannedResponseGet;
                      this.dialog = true;
                    } else if (response.errors && response.errors.length > 0) {
                      if (response.errors[0].message) {
                        this.errorMsg = response.errors[0].message;
                      } else {
                        this.errorMsg = "Error 2E. Invalid response.";
                      }
                    } else {
                      // data did not come back
                      console.log('phxCannedResponseAdd data did not come back: ', response);
                      this.errorMsg = 'Server error fetching canned response';
                    }
                    // console.log('canned response get response: ', response);
                    this.isLoading = false;
                } catch (e) {
                    this.isLoading = false;
                    this.errorMsg = 'Canned response catch error';
                    console.log(e);
                    defaultErrorHandler(e);
                }
            },
            async removeCannedResponse() {
                this.errorMsg = '';
                this.successMsg = '';
                // check if empty
                if (!this.cannedResponseID) {
                  this.errorMsg = 'Please enter a canned response ID';
                  return;
                }
                this.isLoading = true;
                try {
                    const response = await this.$apollo.mutate({
                        mutation: REMOVE_CANNED_RESPONSE,
                        variables: {
                          canned_response_id: this.cannedResponseID
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoading = false;
                    console.log('response: ', response);
                    if (response.data.phxCannedResponseDelete) {
                      this.successMsg = 'Successfully removed canned response';
                      this.cannedResponseID = '';
                    } else if (response.errors && response.errors.length > 0) {
                      if (response.errors[0].message) {
                        this.errorMsg = response.errors[0].message;
                      } else {
                        this.errorMsg = "Error 2E. Invalid response.";
                      }
                    } else {
                      // data did not come back
                      console.log('phxCannedResponseDelete data did not come back: ', response);
                      this.errorMsg = 'Server error';
                    }
                } catch (e) {
                    this.isLoading = false;
                    defaultErrorHandler(e);
                }
            },
            changeView(view, params) {

                this.$emit('onChangeView', {view: view, params: params});
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
        }
    }

</script>

<style scoped>

    .item-box {
        background-color: #fff;
        border-bottom: #177ad5 solid 1px;
    }

    .item-box.odd {
        background-color: #ECEFF1;
    }

    .item-box:hover {
        background-color: rgba(255, 255, 255, 0.8)
    }

</style>
