<template>

  <v-layout column class="blue full-width full-height">

    <v-toolbar dark text extended class="transparent">

      <v-btn icon depressed @click="route('messageMenu', 'prev')">
        <v-icon color="white">menu</v-icon>
      </v-btn>

      <div class="fs-16 uppercase pl-2">Messages</div>

      <v-spacer />

      <v-layout shrink>

        <v-tabs v-model="model" dark color="white" slider-color="yellow">
          <v-tab ripple :href="`#tab-1`">
            INBOX
          </v-tab>
          <v-tab ripple :href="`#tab-2`">
            SENT
          </v-tab>
        </v-tabs>
      </v-layout>

      <template slot="extension">
        <v-text-field append-icon="mic" text label="Search" prepend-inner-icon="search" solo-inverted class="pt-1" />
      </template>

    </v-toolbar>

    <v-layout class="overflow-none pl-1 pr-1 pb-1" style="height: calc(100% - 56px) !important">

      <v-tabs-items v-model="model" class="full-tabs overflow-none">

        <v-tab-item :value="`tab-1`">

          <v-layout v-bar :preventParentScroll="true" class="full-height overflow-none">

            <div class="pl-1 pr-1 pt-1">

              <v-layout shrink align-center v-for="(msg, i) in messages.inbox" :key="`inbox_item_${i}`" class="pb-1">

                <v-layout align-center class="white full-height pl-3 pt-2 pb-2 pr-1 br-1">

                  <v-layout column>

                    <v-layout>

                      <v-layout class="message-item" @click="showDetails('userDetails', 'next', msg)">

                        <v-layout column style="width: 40%">
                          <div class="fs-16">{{msg.patient.last}}, {{msg.patient.first}}</div>
                          <div class="fs-12 pt-1">{{msg.date}}</div>
                        </v-layout>

                        <v-layout column align-center justify-center class="pr-2" style="width: 10%">
                          <v-icon>arrow_right_alt</v-icon>
                        </v-layout>

                        <v-layout column style="width: 40%">
                          <div class="fs-16">{{msg.sender.last}}, {{msg.sender.first}}</div>
                          <div class="fs-12 pt-1">{{msg.sender.position}}</div>
                        </v-layout>

                      </v-layout>

                      <v-btn icon depressed @click="dialog = true" class="pr-2">
                        <v-icon large color="blue">call</v-icon>
                      </v-btn>

                    </v-layout>

                    <v-layout class="pt-2 fs-12 italic bold">

                      {{msg.message}}

                    </v-layout>


                  </v-layout>

                </v-layout>

              </v-layout>

            </div>

          </v-layout>

        </v-tab-item>

        <v-tab-item :value="`tab-2`">

          <v-layout v-bar :preventParentScroll="true" class="full-height overflow-none">

            <div class="pl-1 pr-1 pt-1">

              <v-layout shrink align-center v-for="(msg, i) in messages.sent" :key="`inbox_item_${i}`" class="pb-1">

                <v-layout align-center class="white full-height pl-3 pt-2 pb-2 pr-1 br-1">

                  <v-layout column>

                    <v-layout>

                      <v-layout class="message-item" @click="showDetails('userDetails', 'next', msg)">

                        <v-layout column style="width: 40%">
                          <div class="fs-16">{{msg.patient.last}}, {{msg.patient.first}}</div>
                          <div class="fs-12 pt-1">{{msg.date}}</div>
                        </v-layout>

                        <v-layout column align-center justify-center class="pr-2" style="width: 10%">
                          <v-icon>arrow_right_alt</v-icon>
                        </v-layout>

                        <v-layout column style="width: 40%">
                          <div class="fs-16">{{msg.sender.last}}, {{msg.sender.first}}</div>
                          <div class="fs-12 pt-1">{{msg.sender.position}}</div>
                        </v-layout>

                      </v-layout>

                      <v-btn icon depressed @click="dialog = true" class="pr-2">
                        <v-icon large color="blue">call</v-icon>
                      </v-btn>

                    </v-layout>

                    <v-layout class="pt-2 fs-12 italic bold">

                      {{msg.message}}

                    </v-layout>


                  </v-layout>

                </v-layout>

              </v-layout>

            </div>

          </v-layout>

        </v-tab-item>

      </v-tabs-items>

    </v-layout>

    <v-dialog v-model="dialog" persistent max-width="300">

      <v-card color="primary" dark>
        <v-img src="/images/photos/woman1.jpg" height="50%" />
        <v-card-text class="fs-18">
          Calling Dr. Smith...
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
        <v-card-actions>
          <v-layout>
            <div class="pl-2">Online</div>
            <div class="green bullet ml-2" />
          </v-layout>
          <v-spacer/>
          <v-btn text color="white" @click="dialog=false">Cancel</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

  </v-layout>

</template>

<script>

  export default {
    name: 'users',
    data () {
      return {
        model: 'tab-1',
        dialog: false,
        messages: {
          inbox: [
            {
              "patient": {
                "first": "Johnny",
                "last": "Roberts",
              },
              "sender": {
                "first": "Sandy",
                "last": "Greene",
                "position": "Pediatrician",
                "photo": "woman1.jpg"
              },
              "message": "Patient needs attention",
              "date": "5/12/2019 2:50 PM",
              "status": "new"
            },
            {
              "patient": {
                "first": "John",
                "last": "Peters",
              },
              "sender": {
                "first": "Gary",
                "last": "Goldstein",
                "position": "Oncologist",
                "photo": "man1.jpg"
              },
              "message": "Please review the blood tests",
              "date": "5/11/2018 6:50 AM",
              "status": "critical"
            },
            {
              "patient": {
                "first": "Jennifer",
                "last": "Gray",
              },
              "sender": {
                "first": "Sarah",
                "last": "Marshal",
                "position": "Nurse",
                "photo": "woman1.jpg"
              },
              "message": "New admit from Dr. Smith",
              "date": "7/16/2018 2:50 PM",
              "status": "new"
            },
            {
              "patient": {
                "first": "Jose",
                "last": "Gonzalez",
              },
              "sender": {
                "first": "Fred",
                "last": "Bateman",
                "position": "Nurse",
                "photo": "man1.jpg"
              },
              "message": "Please call regarding patient",
              "date": "7/16/2018 2:50 PM",
              "status": "new"
            },
            {
              "patient": {
                "first": "Lulu",
                "last": "Greene",
              },
              "sender": {
                "first": "Sandy",
                "last": "Greene",
                "position": "Pediatrician",
                "photo": "woman1.jpg"
              },
              "message": "PT still C/O ABD pain; requesting to increase dilaudid dose",
              "date": "7/16/2018 2:50 PM",
              "status": "new"
            },
            {
              "patient": {
                "first": "Lulu",
                "last": "Greene",
              },
              "sender": {
                "first": "Sandy",
                "last": "Greene",
                "position": "Pediatrician",
                "photo": "woman1.jpg"
              },
              "message": "New admit from Dr. Smith",
              "date": "7/16/2018 2:50 PM",
              "status": "new"
            },
            {
              "patient": {
                "first": "Lulu",
                "last": "Greene",
              },
              "sender": {
                "first": "Sandy",
                "last": "Greene",
                "position": "Pediatrician",
                "photo": "woman1.jpg"
              },
              "message": "New admit from Dr. Smith",
              "date": "7/16/2018 2:50 PM",
              "status": "new"
            },
            {
              "patient": {
                "first": "Lulu",
                "last": "Greene",
              },
              "sender": {
                "first": "Sandy",
                "last": "Greene",
                "position": "Pediatrician",
                "photo": "woman1.jpg"
              },
              "message": "New admit from Dr. Smith",
              "date": "7/16/2018 2:50 PM",
              "status": "new"
            },
            {
              "patient": {
                "first": "Lulu",
                "last": "Greene",
              },
              "sender": {
                "first": "Sandy",
                "last": "Greene",
                "position": "Pediatrician",
                "photo": "woman1.jpg"
              },
              "message": "New admit from Dr. Smith",
              "date": "7/16/2018 2:50 PM",
              "status": "new"
            },
            {
              "patient": {
                "first": "Lulu",
                "last": "Greene",
              },
              "sender": {
                "first": "Sandy",
                "last": "Greene",
                "position": "Pediatrician",
                "photo": "woman1.jpg"
              },
              "message": "New admit from Dr. Smith",
              "date": "7/16/2018 2:50 PM",
              "status": "new"
            }
          ],
          sent: [
            {
              "patient": {
                "first": "Johnny",
                "last": "Roberts",
              },
              "sender": {
                "first": "Sandy",
                "last": "Greene",
                "position": "Pediatrician",
                "photo": "woman1.jpg"
              },
              "message": "Patient needs attention",
              "date": "5/12/2019 2:50 PM",
              "status": "new"
            },
            {
              "patient": {
                "first": "John",
                "last": "Peters",
              },
              "sender": {
                "first": "Gary",
                "last": "Goldstein",
                "position": "Oncologist",
                "photo": "man1.jpg"
              },
              "message": "Please review the blood tests",
              "date": "5/11/2018 6:50 AM",
              "status": "critical"
            },
            {
              "patient": {
                "first": "Jennifer",
                "last": "Gray",
              },
              "sender": {
                "first": "Sarah",
                "last": "Marshal",
                "position": "Nurse",
                "photo": "woman1.jpg"
              },
              "message": "New admit from Dr. Smith",
              "date": "7/16/2018 2:50 PM",
              "status": "new"
            }
          ]
        }
      }
    },
    methods: {
      route(name, transition) {

        this.$router.push({
          name: name, params: {
            transition: transition
          }
        }).catch(err => { console.log('router error: ', err) });
      },
      showDetails(name, transition, user) {

        this.$router.push({
          name: name, params: {
            transition: transition
          }
        }).catch(err => { console.log('router error: ', err) });
      }
    }
  }

</script>

<style scoped>

  .message-item {
    cursor: pointer;
  }

  /*.view {*/
  /*  position: absolute;*/
  /*  width: 100%;*/
  /*  height: 100%;*/
  /*}*/

</style>
