import gql from 'graphql-tag'

export default gql`query ycmdUserSearch($group_ids: [ID], 
										$roles: [YcmdRole], 
										$search: String, 
										$limit: Int, 
										$page: Int,
										$roles: [YcmdRole], 
										$facility_ids: [ID], 
										$user_id: ID,
										$include_searching_user: Boolean,
                        				$exclude_group_filter: Boolean
										) {
	ycmdUserSearch(group_ids: $group_ids, 
					roles: $roles, 
					search: $search, 
					limit: $limit, 
					page: $page,
					roles: $roles, 
					facility_ids: $facility_ids, 
					user_id: $user_id,
					include_searching_user: $include_searching_user,
					exclude_group_filter: $exclude_group_filter
					) {
		group_name
		group_id
		users {
			id
			first_name
			last_name
			group_id
			group_name
		}
	}
}`
