<template>

    <v-layout class="ycmd-blue-gradient full-width full-height">

        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="false"
                 :background-color="`#101622`"
                 :color="`white`"
                 :loader="`bars`">
        </loading>

        <v-navigation-drawer app dark absolute
                             :value="isMenuOpen"
                             :permanent="isMenuOpen && $vuetify.breakpoint.name !== 'xs'"
                             :temporary="isMenuOpen && $vuetify.breakpoint.name === 'xs'"
                             :width="$vuetify.breakpoint.name === 'xs' ? '100%' : 240"
                             class="ycmd-blue-gradient"
                             style="border: 0 !important;">

            <div class="d-flex flex-column fill-height">

                <div class="d-flex pt-3 pl-4 pb-3 pr-4 pb-1">
                    <v-layout align-center>
                        <img src="/images/ycmdlogo.png" class="mt-1" style="height: 32px"/>
                        <v-spacer />
                        <v-tooltip bottom
                                   nudge-bottom="10"
                                   :disabled="$vuetify.breakpoint.xsOnly"
                                   color="ycmd-black"
                                   content-class="fade-0 pa-4 pt-2 pb-2">
                            <template v-slot:activator="{ on }">
                                <v-btn icon class="hidden-sm-and-up" v-on="on" @click="closeMenu">
                                    <v-icon :size="22" color="white">close</v-icon>
                                </v-btn>
                            </template>
                            <span>Close Menu</span>
                        </v-tooltip>
                    </v-layout>
                </div>

                <div class="d-flex relative br-2 ma-1" style="height: 220px">

                    <i-card :viewIndex="card.index"
                            :viewCount="1"
                            :leftPanel="card.panels.left"
                            :rightPanel="card.panels.right"
                            :topPanel="card.panels.top"
                            :bottomPanel="card.panels.bottom"
                            class="i-card">

                        <div class="i-card__main">

                            <div class="i-card__main__content">

                                <div class="d-flex flex-column full-width">

                                    <div class="d-flex white">

                                        <div class="d-flex flex-grow-1 ">
                                            <img v-if="profilePicture === 'F'" src="/images/avatar-woman.png" class="pt-2 pl-2" style="max-height:120px"/>
                                            <img v-else src="/images/avatar-man.png" class="pt-2 pl-2" style="max-height:120px"/>

                                        </div>

                                        <div class="d-flex justify-center pa-3">

                                            <v-tooltip bottom
                                                       nudge-left="26"
                                                       nudge-bottom="0"
                                                       :disabled="$vuetify.breakpoint.xsOnly"
                                                       color="ycmd-black"
                                                       content-class="fade-0 pa-4 pt-2 pb-2">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn icon dark depressed v-on="on" @click="onSwapProfilePicture()" color="white">
                                                        <v-icon :size="22" color="ycmd-blue">person</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Change Profile Image</span>
                                            </v-tooltip>

                                            <v-tooltip bottom
                                                       nudge-left="51"
                                                       nudge-bottom="0"
                                                       :disabled="$vuetify.breakpoint.xsOnly"
                                                       color="ycmd-black"
                                                       content-class="fade-0 pa-4 pt-2 pb-2">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn icon dark depressed v-on="on" @click="card.panels.right = true" color="white">
                                                        <v-icon :size="22" color="ycmd-blue">lock</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Change Password</span>
                                            </v-tooltip>

                                        </div>

                                    </div>

                                    <div class="d-flex">

                                        <div class="hidden-xs-only d-flex flex-column justify-center align-center full-width ycmd-medium-blue white--text pa-2 br_bl-2 br_br-2" style="min-height: 140px;">

                                            <div class="fs-16">{{ myInfo.firstName || 'N/A' }} {{ myInfo.lastName || 'N/A' }} </div>
                                            <div class="fs-12 fade-2">{{ this.roles }}</div>

                                            <v-layout class="full-width pt-3">
                                                <!-- <v-layout>
                                                    <v-tooltip top
                                                               :disabled="$vuetify.breakpoint.xsOnly"
                                                               color="ycmd-black"
                                                               content-class="fade-0 pa-4 pt-2 pb-2">
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn icon dark v-on="on" @click="onClickedTodo">
                                                                <v-icon :size="24">assignment</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Tasks</span>
                                                    </v-tooltip>

                                                </v-layout> -->
                                                <v-layout justify-end>
                                                    <v-tooltip top
                                                               nudge-left="20"
                                                               :disabled="$vuetify.breakpoint.xsOnly"
                                                               color="ycmd-black"
                                                               content-class="fade-0 pa-4 pt-2 pb-2">
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn icon dark v-on="on" @click="logout">
                                                                <v-icon :size="24">power_settings_new</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Logout</span>
                                                    </v-tooltip>
                                                </v-layout>
                                            </v-layout>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <template slot="right-panel">

                            <div class="i-card__panel i-card__panel__right white br-2">

                                <div class="i-card__panel__header" style="min-height: 35px;">

                                    <div class="d-flex align-center" style="width: 100%;">

                                        <div class="d-flex align-center flex-grow-1 pl-2 pr-2" style="width: 100%;">

                                            <v-btn icon @click="() => { card.panels.right = false; this.clearPasswordFields(); }">
                                                <v-icon color="ycmd-blue">close</v-icon>
                                            </v-btn>

                                            <v-spacer />

                                            <v-btn icon @click="changePasswordValidation()">
                                                <v-icon color="ycmd-blue">save</v-icon>
                                            </v-btn>

                                        </div>
                                    </div>
                                </div>

                                <div class="i-card__panel__content">

                                    <div class="ycmd-medium-blue pa-2 pt-3">
                                      <v-form
                                            ref="changePasswordRef"
                                            v-model="changePasswordForm"
                                            style="width: 100%; height: 100%;"
                                      >

                                        <v-text-field
                                                solo light dense flat
                                                class="small-input"
                                                v-model="currentPassword"
                                                :append-icon="showCurrentPassword ? 'visibility' : 'visibility_off'"
                                                :type="showCurrentPassword ? 'text' : 'password'"
                                                :rules="[rules.required, rules.min]"
                                                :tabindex="1"
                                                label="Current Password"
                                                hint="At least 8 characters"
                                                background-color="white"
                                                v-on:keyup.enter="() => changePasswordValidation()"
                                                @click:append="showCurrentPassword = !showCurrentPassword" />
                                        <v-text-field
                                                solo light dense flat
                                                class="small-input"
                                                v-model="newPassword"
                                                :append-icon="showNewPassword ? 'visibility' : 'visibility_off'"
                                                :type="showNewPassword ? 'text' : 'password'"
                                                :rules="[rules.required, rules.min]"
                                                name="input-password"
                                                :tabindex="2"
                                                label="New Password"
                                                hint="At least 8 characters"
                                                background-color="white"
                                                v-on:keyup.enter="() => changePasswordValidation()"
                                                @click:append="showNewPassword = !showNewPassword" />
                                        <v-text-field
                                                solo light dense flat
                                                class="small-input"
                                                v-model="newPasswordConfirm"
                                                :append-icon="showNewPasswordConfirm ? 'visibility' : 'visibility_off'"
                                                :type="showNewPasswordConfirm ? 'text' : 'password'"
                                                :rules="[rules.required, rules.min]"
                                                name="input-password"
                                                :tabindex="2"
                                                label="Confirm New Password"
                                                hint="At least 8 characters"
                                                background-color="white"
                                                v-on:keyup.enter="() => changePasswordValidation()"
                                                @click:append="showNewPasswordConfirm = !showNewPasswordConfirm" />

                                      </v-form>

                                      <v-tooltip bottom
                                                 nudge-bottom="10"
                                                 :disabled="$vuetify.breakpoint.xsOnly"
                                                 color="ycmd-black"
                                                 content-class="fade-0 pa-4 pt-2 pb-2">
                                          <template v-slot:activator="{ on }">
                                              <v-btn icon class="hidden-sm-and-up" v-on="on" @click="closeMenu">
                                                  <v-icon :size="22" color="white">close</v-icon>
                                              </v-btn>
                                          </template>
                                          <span>Close Menu</span>
                                      </v-tooltip>
                                    </div>

                                </div>
                            </div>
                        </template>
                    </i-card>
                </div>

                <v-list dark dense>
                    <v-list-item v-if="!isClinicalAnalyst" link @click="changeMenuItem(1, 'PCPUSER.notifications', 'fade-in-transition')"
                                 :class="`menu-item ${ menuIndex === 1 ? 'active' : '' }`">
                        <v-list-item-action>
                            <v-icon :size="22">chat</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title class="fs-14">Notifications</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="!isClinicalAnalyst" link @click="changeMenuItem(2, 'PCPUSER.notifications-filtered', 'fade-in-transition')"
                                 :class="`menu-item ${ menuIndex === 2 ? 'active' : '' }`">
                        <v-list-item-action>
                            <v-icon :size="22">local_hospital</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title class="fs-14">Active Patients</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <v-spacer />

                <div class="pa-1 pt-0">
                    <v-layout align-center justify-center class="ycmd-dark-blue fs-10 pt-4 pb-4 pl-2 pr-2 br-2 white--text">
                        Copyright 2020 | Version: 1.0.0
                    </v-layout>
                </div>

            </div>

        </v-navigation-drawer>

        <div v-if="!fullscreen && $vuetify.breakpoint.smAndUp">

            <v-app-bar app dark dense text class="ycmd-medium-blue elevation-0 pa-0">

                <div class="pl-2 pr-3">
                    <v-tooltip bottom
                               nudge-bottom="9"
                               nudge-right="32"
                               color="ycmd-black"
                               :disabled="$vuetify.breakpoint.xsOnly"
                               content-class="fade-0 pa-4 pt-2 pb-2">
                        <template v-slot:activator="{ on }">
                            <v-btn icon dark @click="toggleMenu" v-on="on">
                                <v-icon :size="20">menu</v-icon>
                            </v-btn>
                        </template>
                        <span>Toggle Menu</span>
                    </v-tooltip>
                </div>

                <div class="hidden-xs-only d-flex flex-grow-1 align-center ycmd-blue ma-0 mt-2 br_tl-2 br_tr-2">
                    <div class="hidden-xs-only d-flex align-center pt-2 pb-2 pl-4">
                        <div class="pl-4 pr-3 fs-14" style="height: 26px">
                            PCPLink
                            <v-icon color="white" :size="22" class="ml-1 mr-1">arrow_right</v-icon>
                            {{ menuName }}
                        </div>
                    </div>
                </div>

                <div class="hidden-xs-only d-flex justify-end transparent ml-4">

                    <v-tooltip bottom
                               nudge-bottom="9"
                               :disabled="$vuetify.breakpoint.xsOnly"
                               color="ycmd-black"
                               content-class="fade-0 pa-4 pt-2 pb-2">
                        <template v-slot:activator="{ on }">
                            <v-btn icon dark :disabled="$route.name.includes('PCPUSER')" v-on="on">
                                <v-icon :size="28">speaker_phone</v-icon>
                            </v-btn>
                        </template>
                        <span>PCPLink</span>
                    </v-tooltip>

                </div>

            </v-app-bar>
        </div>

        <v-main>

            <transition :name="transition">

                <router-view class="absolute full-width full-height" />

            </transition>

        </v-main>

        <v-navigation-drawer app v-model="drawer" clipped absolute temporary right>

            <div class="d-flex flex-column full-width full-height">

                <v-toolbar dark text class="transparent">

                    <div class="fs-16 uppercase pl-2">Message Menu</div>

                    <v-spacer/>

                    <v-tooltip bottom
                               :disabled="$vuetify.breakpoint.xsOnly"
                               color="ycmd-black"
                               content-class="fade-0 pa-4 pt-2 pb-2">
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" @click="drawer = false">
                                <v-icon :size="20" color="white">close</v-icon>
                            </v-btn>
                        </template>
                        <span>Close Menu</span>
                    </v-tooltip>

                </v-toolbar>

                <v-layout class="blue overflow-none pl-1 pr-1 pb-1" style="height: calc(100% - 56px) !important">

                    <v-layout column align-center justify-center class="full-height white--text">

                        <div class="fs-32 bold">MESSAGE MENU</div>
                        <div>{{getInnerWidth}}</div>

                    </v-layout>

                </v-layout>

            </div>

        </v-navigation-drawer>

        <v-footer v-if="!fullscreen && $vuetify.breakpoint.xsOnly" app dark class="pa-0">

            <div class="d-flex pa-2
                        flex-grow-1
                        fill-height
                        align-center
                        justify-center
                        align-content-space-between
                        ycmd-dark-blue">
                <v-btn icon class="ml-3 mr-3"
                       @click="changeMenuItem(1, 'PCPUSER.notifications', 'fade-in-transition')">
                    <v-icon :class="`menu-icon ${ menuIndex === 1 ? 'active' : '' }`">chat</v-icon>
                </v-btn>
                <v-btn icon class="ml-3 mr-3"
                       @click="changeMenuItem(2, 'PCPUSER.notifications-filtered', 'fade-in-transition')">
                    <v-icon :class="`menu-icon ${ menuIndex === 2 ? 'active' : '' }`">local_hospital</v-icon>
                </v-btn>
            </div>
        </v-footer>

        <v-dialog v-model="dialog" persistent max-width="300">

            <v-card color="ycmd-black" dark style="padding-top: 15px;">
                <v-card-text class="fs-18">
                    Do you want to logout?
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn text color="white" @click="logout">Yes</v-btn>
                    <v-btn text color="white" @click="dialog = false">No</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

    </v-layout>

</template>

<script>

    import { bus } from '../../../eventBus';
    import { clearLocalStorage } from '../../../methods/globalMethods';

    import { mapState, mapActions, mapMutations  } from 'vuex';
    import innerHeight from 'ios-inner-height';
    import Loading from 'vue-loading-overlay';

    import {defaultErrorHandler} from '../../../graphql/handler/errorHandler';
    import CHANGE_PASSWORD from '../../../graphql/mutations/authenticateChangePassword.js';

    const rolesParsed = {
        'pcp_admin': 'PCP Admin',
        'pcp_group_admin': 'Group Admin',
        'pcp_facility_admin': 'Facility Admin',
        'pcp_analyst': 'Clinical Analyst',
        'pcp_user': 'PCP User'
    }

    export default {
        name: 'pcpUser',
        components: {
          Loading
        },
        data: () => ({
            drawer: false,
            menuIndex: -1,
            card: {
                index: 0,
                panels: { left: false, right: false, top: false, bottom: false }
            },
            dialog: false,
            isLoading: false,
            transition: 'fade-in-transition',
            height: 0,
            innerHeight: 0,
            fullscreen: false,
            roles: '',
            isAdminPCP: false,
            isClinicalAnalyst: false,

            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters'
            },
            changePasswordForm: false,

            currentPassword: '',
            newPassword: '',
            newPasswordConfirm: '',

            showCurrentPassword: false,
            showNewPassword: false,
            showNewPasswordConfirm: false,

            profilePicture: localStorage.getItem("lastName") || 'M'
        }),
        created() {
            this.setMenuIndex();
            // console.log('myInfo: ', this.myInfo);
        },
        mounted() {

            this.height = innerHeight();
            this.innerHeight = window.innerHeight;

            // Add or remove the header based on which route meta matches
            this.fullscreen = this.$store.getters['layout/fullscreen'];

            // check profile picture
            const profilePicture = localStorage.getItem("profilePicture");
            if (!profilePicture || profilePicture === 'null') {
              // console.log('setting to M');
              this.profilePicture === 'M';
              localStorage.setItem("profilePicture", "M");
            } else {
              this.profilePicture = profilePicture;
            }

        },
        methods: {
            ...mapActions('menu', ['openMenu','closeMenu','toggleMenu']),
            ...mapMutations('profile', ['setToDoPressed']),
            changePasswordValidation() {
              if (this.$refs.changePasswordRef.validate()) {
                // check if passwords match
                if (this.newPassword === this.newPasswordConfirm) {
                    this.changePassword();
                } else {
                  this.$toasted.error('Passwords do not match.');
                }
              }
            },
            async changePassword() {
              this.isLoading = true;
              try {
                const response = await this.$apollo.mutate({
                    mutation: CHANGE_PASSWORD,
                    variables: {
                        oldpassword: this.currentPassword,
                        newpassword: this.newPassword
                    },
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all'
                });
                // console.log('change password resonse: ', response);
                this.isLoading = false;
                this.clearPasswordFields();

                if (response.data.authenticateChangePassword) {
                    this.$toasted.success(`Successfully changed password!`);
                    this.card.panels.right = false;
                } else if (response.errors && response.errors.length > 0) {
                    if (response.errors[0].message) {
                        this.$toasted.error(response.errors[0].message);
                    } else {
                        this.$toasted.error('There was an error changing password.');
                    }
                } else {
                    // data did not come back
                    console.log('pcpImportProviderUpdate data did not come back: ', response);
                    this.$toasted.error(`There was an error changing the password.`);
                }
              } catch(e) {
                defaultErrorHandler(e);
              }
            },
            clearPasswordFields() {
              this.newPasswordConfirm = '';
              this.newPassword = '';
              this.currentPassword = '';

              this.changePasswordForm = false;
            },
            setMenuIndex() {
                switch (this.$route.name) {
                    case 'PCPUSER.notifications':
                        this.menuIndex = 1;
                        this.menuName = 'Notifications';
                        break;
                    case 'PCPUSER.notifications-filtered':
                        this.menuIndex = 2;
                        this.menuName = 'Active Patients';
                        break;
                }
            },
            onSwapProfilePicture() {
              // console.log(this.profilePicture)
              if (this.profilePicture === 'M') {
                localStorage.setItem("profilePicture", "F");
                this.profilePicture = 'F';
                return;
              }

              if (this.profilePicture === 'F') {
                localStorage.setItem("profilePicture", "M");
                this.profilePicture = 'M';
                return;
              }
            },
            onClickedTodo() {
              // localStorage.setItem("toDoPressed", true);
              // console.log('router: ', this.$route.name);

              // if (!this.toDoPressed) {
              //   if (this.$route.name !== 'ADMIN.providers') {
              //       this.route('ADMIN.providers', 'fade-in-transition');
              //   } else {
              //
              //   }
              //   this.setToDoPressed(true);
              // }
            },
            route(name, transition) {

                this.$router.push({
                    name: name,
                    params: {
                        transition: transition || this.transition
                    }
                }).catch(err => { console.log('router error: ', err) });
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
            changeMenuItem(index, name, transition) {
                // Check if we are already on it
                if (this.menuIndex === index) {
                    return;
                }
                this.menuIndex = index;
                this.route(name, transition);
            },
            logout() {
                clearLocalStorage();
                this.route('login', 'fade-in-transition')
            }
        },
        watch: {
            '$route'(to) {

                this.setMenuIndex();

                this.transition = (to.params.transition) ? to.params.transition : 'fade-in-transition';

                if (to.matched.some(record => record.meta.fullscreen)) {
                    this.fullscreen = true;
                } else {
                    this.fullscreen = false;
                }
            }
        },
        computed: {
            ...mapState({
                isMenuOpen: state => state.menu.open
            }),
            ...mapState('profile', ['toDoPressed']),
            ...mapState('profile', ['myInfo']),
            getInnerWidth: function () {
                return window.innerWidth;
            }
        }
    }

</script>

<style scoped>

    .menu-icon {
        color: rgba(255, 255, 255, 0.7) !important;
        font-size: 24px;
        cursor: pointer;
        transition: all 0.3s cubic-bezier(.25, .8, .50, 1);
    }

    .menu-icon.active {
        color: rgba(255, 255, 255, 1) !important;
        font-size: 28px;
        transition: all 0.3s cubic-bezier(.25, .8, .50, 1);
    }

    .menu-item.active {
        background-color: #177ad5;
    }

    .application--wrap {
        min-height: 100% !important;
        height: 100%;
    }

    .small-input >>> .v-input {
        font-size: 12px !important;
    }
    .small-input >>> .v-input__control {
        min-height: 32px !important;
    }
    .small-input >>> .v-label {
        font-size: 12px !important;
    }

</style>
