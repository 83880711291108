<template>
    <div
        style="width: 100%"
    >
        <metadata-item
            v-for="item in items"
            :metadataItem="item"
            :key="item.name"
            :language="language"
        />
        <div></div>
    </div>
</template>
<script>
import ivrMetadataItem from './ivrMetadataItem'
export default {
    components: {
        'metadata-item': ivrMetadataItem
    },
    props: {
        metadata: {
            type: Array,
            default: () => {}
        },
        language: {
            type: String,
            default: ''
        }
    },
    methods: {
        deleteOption (item) {
            let index = this.options.findIndex(a => {
                return a.number === item.number
            })
            if (index >= 0) {
                let number = this.options[index].number
                this.options.splice(index, 1)
                this.options.map(o => {
                    if (o.number > number) {
                        o.number -= 1
                    }
                })
            }
        },
        addOption () {
            let option = {
                number: this.newNumber,
                action: this.newAction
            }

            switch (this.newAction) {
                case 'menu':
                case 'voicemail':
                case 'transfer_to_number':
                    option.action_value = this.newActionValue
                    break
                default:
                    option.action_value = ''
                    break
            }
            this.options.push(option)
            this.dialog = false
        },
        calculateItems () {
            let arr = []
            for (let itemName in this.all_languages_metadata['en-US']) {
                let item = {
                    name: itemName,
                    label: this.all_languages_metadata['en-US'][itemName].label,
                    prompt: []
                }

                let configured

                if (this.metadata && this.metadata.length) {
                    // console.log('this.metadata', this.metadata)
                    configured = this.metadata.find(m => {
                        return m.name === itemName
                    })
                }
                let defaultMetaEn = this.all_languages_metadata['en-US'][itemName]
                let defaultMetaEs = this.all_languages_metadata['es-US'][itemName]
                let us
                let es

                if (configured) {
                    item.collect = configured.collect
                    
                    if (configured.prompts && configured.prompts.length) {
                        us = configured.prompts.find(p => {
                            return p.language == 'en-US'
                        })
                        es = configured.prompts.find(p => {
                            return p.language == 'es-US'
                        })

                    }
                }
                item.prompt.push({
                    language: 'en-US',
                    text: us ? us.text: defaultMetaEn.prompt,
                    defaultText: defaultMetaEn.prompt,
                })
                item.prompt.push({
                    language: 'es-US',
                    text: es ? es.text: defaultMetaEs.prompt,
                    defaultText: defaultMetaEs.prompt,
                })

                arr.push(item)
            }
            /*
            if (this.metadata && this.metadata.length) {
                this.metadata.map(m => {
                    arr.push(m)
                })
            }
            */
            this.items = arr
        },
        calculateMetadata (items) {
            let mdArray = []
            items.map(item => {
                if (item.collect) {
                    let md = {
                        name: item.name,
                        collect: true
                    }         
                    item.prompt.map(p => {
                        if (p.text !== p.defaultText) {
                            if (!md.prompts) {
                                md.prompts = []
                            }
                            md.prompts.push(
                                {
                                    language: p.language,
                                    text: p.text
                                }
                            )
                        }
                    })
                    mdArray.push(md)
                }
            })
            return mdArray
        }
    },
    computed: {
        headers () {
            let headers = [
                {
                    value: 'collect',
                    text: '',
                    width: '30px',
                    sortable: false
                },
                {
                    text: 'Number',
                    value: 'number',
                    width: '75px',
                    sortable: false
                },
                {
                    text: 'Action',
                    value: 'action',
                    width: '300px',
                    sortable: false
                },
                {
                    text: 'Value',
                    value: 'action_value',
                    width: '500px',
                    sortable: false
                }
            ]
            return headers
        }
    },
    data: () => ({
        all_languages_metadata: {
            'es-US': {
                FirstNameSpelled:
                {
                    prompt: 'Bueno. Por favor diga su nombre.',
                    label: 'Primer nombre'
                },
                LastNameSpelled:
                {
                    prompt: 'Ahora por favor di tu apellido',
                    label: 'Apellido'
                },
                BirthDate:
                {
                    prompt: 'Ahora, por favor, diga su fecha de nacimiento. Puedes decir fechas como el 1 de enero de 2021',
                    label: 'Fecha de nacimiento'
                },        
                AddressStreet:
                {
                    prompt: 'Ahora diga su dirección postal. Puedes decir cosas como uno, cero, tres, calle principal',
                    label: 'Dirección'
                },        
                AddressCity: 
                {
                    prompt: 'Ahora por favor di tu ciudad',
                    label: 'Cuidad'
                },        
                AddressState:
                {
                    prompt: 'Ahora por favor di tu estado',
                    label: 'Estado'
                },        
                AddressZipcode: 
                {
                    prompt: 'Ahora diga su código postal',
                    label: 'Código postal'
                }
            },
            'en-US' : {
                FirstNameSpelled:
                {
                    prompt: 'Okay. Please say your first name.',
                    label: 'Firstname'
                },
                LastNameSpelled: 
                {
                    prompt: 'Now please say your last name',
                    label: 'Lastname'
                },
                BirthDate: {
                    prompt: 'Now please say your date of birth? You can say dates like January 1st, 2021',
                    label: 'Birthdate'
                },        
                AddressStreet: 
                {
                    prompt: 'Now please say your Street Address. You can say things like one zero three main Street',
                    label: 'Street address'
                },        
                AddressCity: 
                {
                    prompt: 'Now please say your city',
                    label: 'City'
                },        
                AddressState: 
                {
                    prompt: 'Now please say your state',
                    label: 'State'
                },        
                AddressZipcode:
                {
                    prompt: 'Now please say your Zipcode',
                    label: 'Zipcode'
                }  
            }
        },
        numbers: ['1','2','3','4','5','6','7','8','9'],
        dialog: false,
        newNumber: '',
        newAction: null,
        newActionValue: null,
        localOptions: [],
        actionLabel: 'Value',
        items: [],
        newMD: []
    }),
    activated() {
    },
    mounted() {
        this.calculateItems()
        this.$emit('metadataChanged', this.calculateMetadata(this.items))
    },
    watch: {
        options (newValue, oldValue) {
            this.localOptions = newValue
        },
        language (newValue, oldValue) {
            this.calculateItems()
        },
        items: {
            handler (newValue, oldValue) {
                this.$emit('metadataChanged', this.calculateMetadata(newValue))
            }, 
            deep: true
        },
        metadata: {
            handler (newValue, oldValue) {
                this.calculateItems()
            }, 
            deep: true
        }

    }
}

</script>
