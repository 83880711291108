<template>
  <div>
      <v-data-table
          :headers="headers"
          :items="paymentMethods"
          dense
          :hide-default-footer="true"
          :disable-pagination="true"
          no-data-text="No Payment Methods"
          :show-expand="false"
          :loading="loading"
      >
        <template v-slot:item.billTo="{ item }">

          <v-container
          class="pa-0 ma-0"
          >
            <v-row
                class="pa-0 ma-0"
                v-if="item.billToName"
            >
              <v-col cols="12"
                  class="pa-0 ma-0"
              >
                <span class="fw-700">{{item.billToName}}</span>
              </v-col>
            </v-row>
            <v-row
                class="pa-0 ma-0"
                v-if="item.billTo.address"
            >
              <v-col cols="12"
                  class="pa-0 ma-0"
              >
                <span>{{item.billTo.address}}</span>
              </v-col>
            </v-row>            

            <v-row
                class="pa-0 ma-0"
            >
              <v-col class="shrink pa-0 ma-0"
                v-if="item.billTo.city"
              >
                <span>{{item.billTo.city}}, </span>
              </v-col>
              <v-col class="shrink pa-0 ma-0"
                v-if="item.billTo.state"
              >
                <span>{{item.billTo.state}}</span>
              </v-col>
              <v-col class="shrink pa-0 ma-0"
                v-if="item.billTo.zipCode"
              >
                <span>&nbsp;{{item.billTo.zipCode}}</span>
              </v-col>
            </v-row>            

          </v-container>

        </template>

        <template v-slot:item.actions="{ item }">


          <v-tooltip bottom
                      nudge-bottom="10"
                      :disabled="$vuetify.breakpoint.xsOnly"
                      color="ycmd-black"
                      content-class="fade-0 pa-4 pt-2 pb-2">
              <template v-slot:activator="{ on }">
                <v-btn icon dark
                        color="error"
                        @click.stop="deletePaymentMethod(item)"
                        >
                  <v-icon :size="20">delete</v-icon>
                </v-btn>
              </template>
              <span>Pay</span>
          </v-tooltip>

        </template>
      </v-data-table>

        <confirmation
            v-model="confirmPMDelete"
            title="Delete Payment Method"
            subtitle="Confirm Payment Method Delete"
            text="Are you sure you want to delete the payment method?"
            @click="confirmationClicked"
            :buttons="confirmationButtons"
            max-width="500"
        />
  </div>
</template>

<script>
import {mapActions} from "vuex";
import payment from "@/components/payment";
import Confirmation from '@/components/confirmation';
export default {
    name: 'PaymentMethods',
    components: {
      payment,
      confirmation: Confirmation
    },
    props: {
      group_id: {
        type: String,
        default: null
      }
    },
    data: () => ({
      loading: false,
      customerProfile: {},
      sending: false,
      confirmPMDelete: false,
      paymentProfileToDelete: null
    }),
    mounted() {
      this.loadPaymentMethods()
    },
    methods: {
      ...mapActions('billing', ['loadCustomerProfile','makePayment','deletePaymentProfile']),
      async loadPaymentMethods () {

        if (!this.group_id) {
          return
        }
        
        this.loading = true
        this.customerProfile = await this.loadCustomerProfile({group_id: this.group_id})

        this.loading = false
      },
      deletePaymentMethod(profile) {
        this.paymentProfileToDelete = profile
        this.confirmPMDelete = true
      },
      async confirmationClicked(btn) {
        if (btn.caption == 'OK') {
          this.sending = true
          await this.deletePaymentProfile({
            paymentProfileId: this.paymentProfileToDelete.paymentProfileId,
            group_id: this.group_id
          })
          this.sending = false

          this.confirmPMDelete = false
          
          this.$toasted.success(`Payment method deleted`);


        } else {
          this.confirmPMDelete = false
        }

      }
    },
    computed: {
      confirmationButtons () {
          return [
              {caption: 'OK', disabled: this.sending},
              {caption: 'Cancel', disabled: this.sending}
          ]
      },

      headers () {
          let headers = [
              {
                  text: 'Type',
                  value: 'typeLabel',
                  width: '15%',
                  sortable: false
              },
              {
                  text: 'Name',
                  value: 'name',
                  width: '15%',
                  sortable: false
              },
              {
                  text: 'Number',
                  value: 'number',
                  width: '15%',
                  sortable: false
              },
              {
                  text: 'Expiration/Routing',
                  value: 'expRout',
                  width: '15%',
                  sortable: false
              },
              {
                  text: 'Bill To',
                  value: 'billTo',
                  width: '40%',
                  sortable: false
              },
              {
                text: "Actions",
                value: 'actions',
               // align: 'right',
                width: '200px',
                sortable: false
              }
          ]
          return headers
      },
      paymentMethods () {
          let arr = []

          if (this.customerProfile && this.customerProfile.paymentProfiles && this.customerProfile.paymentProfiles.length) {
              this.customerProfile.paymentProfiles.map(p => {
                let pm = {
                  billTo: p.billTo,
                  customerProfileId: p.customerProfileId,
                  paymentProfileId: p.paymentProfileId,
                  type: p.type,
                }
                if (pm.billTo) {
                  pm.billToName = `${pm.billTo.first_name} ${pm.billTo.last_name}`.trim()
                }

                console.log('pm', pm)
                if (p.parameters && p.parameters.length) {
                  p.parameters.map(prop => {
                    console.log('prop', prop)
                    pm[prop.id] = prop.value
                  })
                }

                pm.cardType = pm.cardType ? pm.cardType : 'Unknown' 

                switch (pm.type) {
                  case 'creditCard':
                    pm.typeLabel = 'Credit Card'
                    pm.number = pm.cardNumber
                    pm.name = pm.cardType
                    pm.expRout = pm.expirationDate
                    break

                  case 'bankAccount':
                    pm.typeLabel = 'Bank Account'
                    pm.number = pm.accountNumber
                    pm.name = pm.accountType
                    pm.expRout = pm.routingNumber
                    break

                }
                arr.push(pm)
              })
          }

          return arr
      },
    },
    watch: {
      group_id(val) {
        if (val) {
          this.loadPaymentMethods()
        } else {
          this.customerProfile = {}
        }
      }
    }
}
</script>
