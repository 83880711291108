<template>

    <div class="view ycmd-blue-gradient">

        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="false"
                 :background-color="`#101622`"
                 :color="`white`"
                 :loader="`bars`">
        </loading>

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <div class="i-card__main pa-2">

                <div class="i-card__main__content white br-2 pa-2">

                    <div class="i-card__scrollable ycmd-light-gray full-width full-height">

                      <div class="pr-1 pa-2">

                          <div>
                              <v-autocomplete
                                    light dense flat outlined
                                    v-model="selectedGroup"
                                    :disabled="!allGroups.length"
                                    :items="allGroups"
                                    :loading="isLoadingGroupsSearch"
                                    label="Select Group"
                                    item-value="id"
                                    item-text="name"
                                    style="max-width: 400px;"
                                    background-color="white"
                                    clearable
                                    return-object
                              ></v-autocomplete>

                                <v-container
                                    fluid
                                    v-if="(currentBillingGroup && selectedGroup)"
                                >

                                    <v-row
                                        class="pl-0 pr-0 pb-0 pt-0"
                                    >
                                        <v-col
                                            cols="12"
                                            md="6"
                                            class="pa-0 ma-0"
                                        >   
                                            <v-row class="ma-0">
                                                <v-col cols="12">  
                                                    <div class="text-subtitle-2 pb-3">Billing Plan</div>
                                                    <v-data-table
                                                        :headers="bpHeaders"
                                                        :items="bpData"
                                                        dense
                                                        :hide-default-footer="true"
                                                        :disable-pagination="true"
                                                        no-data-text="No plan"
                                                        :show-expand="false"
                                                        class="toplevel"
                                                    >
                                                        <template v-slot:item.actions="{ item }">
                                                            <v-icon
                                                                small
                                                                class="mr-2"
                                                                @click="editItem(item)"
                                                                v-if="false"
                                                            >
                                                                edit
                                                            </v-icon>
                                                        </template>
                                                    </v-data-table>
                                                </v-col>
                                            </v-row>

                                        </v-col>

                                        <v-col
                                            cols="12"
                                            md="6"
                                            class="pa-0 ma-0"
                                        >   

                                            <v-row class="ma-0">
                                                <v-col cols="12">                                        
                                                    <div class="text-subtitle-2 pb-3">Extended Billing Plan</div>
                                                    <v-data-table
                                                        :headers="extendedBpHeaders"
                                                        :items="extendedBpDataWithTiers"
                                                        dense
                                                        :hide-default-footer="true"
                                                        :disable-pagination="true"
                                                        no-data-text="No plan"
                                                        :show-expand="false"
                                                        :item-class="item_class"
                                                        class="toplevel"
                                                    >
                                                        <template v-slot:item.actions="{ item }">
                                                            <v-icon
                                                                small
                                                                class="mr-2"
                                                                @click="editItemExtended(item)"
                                                            >
                                                                edit
                                                            </v-icon>
                                                            <v-icon
                                                                small
                                                                class="mr-2"
                                                                @click="addTier(item)"
                                                                v-if="item.type === 'tiered'"
                                                            >
                                                                add
                                                            </v-icon>
                                                            <v-icon
                                                                small
                                                                class="mr-2"
                                                                @click="deleteTier(item)"
                                                                v-if="!item.type"
                                                            >
                                                                remove
                                                            </v-icon>

                                                        </template>
                                                    </v-data-table>
                                                </v-col>
                                            </v-row>
                                            <v-row class="ma-0">
                                                <v-col cols="12">
                                                    <v-data-table
                                                        :headers="boAdditionalChargeHeaders"
                                                        :items="boAdditionalChargesDisplay"
                                                        dense
                                                        :hide-default-footer="true"
                                                        :disable-pagination="true"
                                                        no-data-text="No Additional Charges"
                                                        :show-expand="false"
                                                    >
                                                        <template v-slot:header="props" >
                                                            <thead>
                                                                <tr>
                                                                    <th colspan="4" style="width: 100%; min-width: 100%;" class="text-center pt-1">Bill on Additional Charges</th>
                                                                </tr>
                                                            </thead>
                                                        </template>
                                                        <template v-slot:footer="props" >
                                                            <div style="width:100%"  class="text-right">
                                                                <v-btn
                                                                class="mr-3"
                                                                    fab
                                                                    dark
                                                                    small
                                                                    color="primary"
                                                                    @click="startAdditionalCharge()"
                                                                >
                                                                    <v-icon dark>add</v-icon>
                                                                </v-btn>
                                                            </div>
                                                        </template>

                                                        <template v-slot:item.actions="{ item }">
                                                            <v-icon
                                                                small
                                                                class="mr-2"
                                                                @click="startAdditionalCharge(item)"
                                                            >
                                                                edit
                                                            </v-icon>
                                                            <v-icon
                                                                small
                                                                class="mr-2"
                                                                @click="deleteAdditionalCharge(item)"
                                                            >
                                                                remove
                                                            </v-icon>
                                                        </template>
                                                    </v-data-table>
                                                    
                                                </v-col>
                                            </v-row>


                                            <v-row class="ma-0">
                                                <v-col cols="12">
                                                    <v-data-table
                                                        :headers="boAdditionalChargeHeaders"
                                                        :items="boAdditionalDiscountsDisplay"
                                                        dense
                                                        :hide-default-footer="true"
                                                        :disable-pagination="true"
                                                        no-data-text="No Additional Discounts"
                                                        :show-expand="false"
                                                    >
                                                        <template v-slot:header="props" >
                                                            <thead>
                                                                <tr>
                                                                    <th colspan="4" style="width: 100%; min-width: 100%;" class="text-center pt-1">Bill on Additional Discounts</th>
                                                                </tr>
                                                            </thead>
                                                        </template>
                                                        <template v-slot:footer="props" >
                                                            <div style="width:100%"  class="text-right">
                                                                <v-btn
                                                                class="mr-3"
                                                                    fab
                                                                    dark
                                                                    small
                                                                    color="primary"
                                                                    @click="startAdditionalDiscount()"
                                                                >
                                                                    <v-icon dark>add</v-icon>
                                                                </v-btn>
                                                            </div>
                                                        </template>

                                                        <template v-slot:item.actions="{ item }">
                                                            <v-icon
                                                                small
                                                                class="mr-2"
                                                                @click="startAdditionalDiscount(item)"
                                                            >
                                                                edit
                                                            </v-icon>
                                                            <v-icon
                                                                small
                                                                class="mr-2"
                                                                @click="deleteAdditionalDiscount(item)"
                                                            >
                                                                remove
                                                            </v-icon>
                                                        </template>
                                                    </v-data-table>
                                                    
                                                </v-col>
                                            </v-row>



                                            <v-btn depressed light class="ycmd-blue white--text mt-5" :disabled="(!selectedGroup || !currentBillingGroup)" @click="updateBillingPlan">Update Extended Plan</v-btn>

                                        </v-col>
                                        
                                    </v-row>
                                    <v-row
                                        class="pl-0 pr-0 pb-0 pt-0"
                                    >
                                        <v-col
                                            cols="12"
                                            class="pa-0 ma-0"
                                        >   

                                            <div v-if="errorMsg" class="mt-3" style="color: red">{{errorMsg}}</div>
                                            <div v-if="successMsg" class="mt-3" style="color: green">{{successMsg}}</div>
                                        </v-col>
                                    </v-row>

                                </v-container>                                

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </i-card>

        <v-dialog v-model="editBPItem" persistent max-width="300">

            <v-card style="padding-top: 15px;">
                <v-card-text>{{editBPLabel}}</v-card-text>
                <v-card-text class="fs-18">
                    <v-text-field
                        light dense flat outlined
                        v-model="editBPIncluded"
                        label="Included"
                        style="width: 300px;"
                    />                    
                    <v-text-field
                        light dense flat outlined
                        v-model="editBPRate"
                        label="Rate"
                        style="width: 300px;"
                    />             
                    <v-text-field
                        light dense flat outlined
                        v-model="editBPValue"
                        label="Value"
                        style="width: 300px;"
                    />                    
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn text @click="doEditBPItem" >OK</v-btn>
                    <v-btn text @click="editBPItem = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="editBPItemExtended" persistent max-width="300">

            <v-card style="padding-top: 15px;">
                <v-card-text>{{editBPLabel}}</v-card-text>
                <v-card-text class="fs-18">
                    
                    <v-text-field
                        light dense flat outlined
                        v-model="editBPDDescription"
                        label="Description"
                        style="width: 300px;"
                        v-if="editBPType=='editTier' || editBPType=='addTier'"
                    />                    

                    <v-text-field
                        light dense flat outlined
                        v-model="editBPIncluded"
                        :label="editBPIncludedLabel"
                        style="width: 300px;"
                        v-if="editBPType!='tiered'"
                    />                    
                    <v-text-field
                        light dense flat outlined
                        v-model="editBPRate"
                        label="Rate"
                        style="width: 300px;"
                        v-if="editBPType!='tiered'"
                    />             
                    <v-text-field
                        light dense flat outlined
                        v-model="editBPValue"
                        label="Value"
                        style="width: 300px;"
                    />                    
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn text @click="doEditBPItemExtended" >OK</v-btn>
                    <v-btn text @click="editBPItemExtended = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>


        </v-dialog>

        <v-dialog v-model="showAdditionalCharge" persistent max-width="300">
            <v-card style="padding-top: 15px;">
                <v-card-title>{{editACTitle}}</v-card-title>

                <v-card-text>{{editACLabel}}</v-card-text>
                <v-card-text class="fs-18">
                    <v-text-field
                        light dense flat outlined
                        v-model="editACDescription"
                        label="Description"
                        style="width: 300px;"
                    />                    
                    <v-text-field
                        light dense flat outlined
                        :prepend-icon="editACChargeType"
                        @click:prepend="toggleType"
                        v-model="editACAmount"
                        :label="editACChargeType === 'percent' ? 'Rate' : 'Amount'"
                        style="width: 300px;"
                    />        

                    <label
                        class="v-label v-label--active theme--light text-caption"
                    >Behavior</label>
                    <v-select
                        light solo dense flat outlined
                        :items="billOnActions"
                        v-model="editACAction"
                        />   

                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn text @click="doAdditionalCharge" :disabled = "!acValid">OK</v-btn>
                    <v-btn text @click="showAdditionalCharge = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


    </div>

</template>

<script>

    import { format, parseISO } from 'date-fns';
    import Loading from 'vue-loading-overlay';

    import {defaultErrorHandler} from '../../../../graphql/handler/errorHandler';
    import {refreshHandler} from '../../../../graphql/handler/refreshHandler';
    import SEARCH_GROUPS from '../../../../graphql/queries/phxGroups';
    import GET_GROUP_BILLING from '../../../../graphql/queries/billing/billingGroup'
    import UPDATE_BILLING_GROUP from '../../../../graphql/mutations/billing/billingGroupUpdate'
    import IvrAdditionalPrompts from '../../scripts/ivr/ivrAdditionalPrompts.vue';
    import { removePropertiesFromObject } from '@/methods/globalMethods';
    import {mapGetters, mapActions} from "vuex";

    export default {
        name: 'updateBillingPlan',
        components: {
            Loading,
                IvrAdditionalPrompts
        },
        props: {
            params: {
                type: Object
            }
        },
        data() {
            return {
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                dialog: false,
                selectedModal: null,
                isLoading: false,
                errorMsg: '',
                successMsg: '',

                allGroups: [],

                selectedGroup: null,
                groupsSearched: [],
                isLoadingGroupsSearch: false,
/*
                name: '',
                altName: '',
                shortCode: '',
*/
                currentBillingGroup: null,
                bpData: [],
                extendedBpData: [],
                editBPItem: false,
                editBPLabel: '',
                editBPIncluded: null,
                editBPRate: null,
                editBPValue: null,
                editBPType: null,
                editingItem: null,
                addingItem: null,
                editBPDDescription: null,
                editBPIncludedLabel: 'Included',

                editBPItemExtended: false,
                boAdditionalCharges: [],
                boAdditionalDiscounts: [],
                showAdditionalCharge: false,
                editACDescription: null,
                editACAmount: null,
                editACLabel: null,
                editACTitle: null,
                editACAction: 'Reset',
                editACChargeType: 'paid',
                billOnActions: ['Reset', 'Persist'],
                additionalMode: 'charge',
                additionalChargeEdit: null
            }
        },
        activated() {

        },
        async mounted() {
            await this.getGroups(null);

            this.selectedGroup = this.billingGroupGetter
        },
        computed: {
            acValid () {
                if (!this.editACDescription || !this.editACAmount) {
                    return false
                }
                return true
            },
            bpHeaders () {
                return [
                    {
                        text: 'Description',
                        align: 'start',
                        sortable: false,
                        width: '40%',
                        value: 'description'
                    },
                    {
                        text: 'Type',
                        align: 'start',
                        sortable: false,
                        width: '10%',
                        value: 'type'
                    },
                    {
                        text: 'Included',
                        align: 'start',
                        sortable: false,
                        width: '10%',
                        value: 'included'
                    },
                    {
                        text: 'Rate',
                        align: 'start',
                        sortable: false,
                        width: '15%',
                        value: 'rate'
                    },
                    {
                        text: 'Value',
                        align: 'start',
                        sortable: false,
                        width: '15%',
                        value: 'value'
                    },
                    {
                        text: '', 
                        value: 'actions',
                        sortable: false,
                        width: '5%',
                    }
                ]
            },
            boAdditionalChargeHeaders () {
                return [
                    {
                        text: 'Description',
                        align: 'start',
                        sortable: false,
                        width: '45%',
                        value: 'description'
                    },
                    {
                        text: 'Amount',
                        align: 'start',
                        sortable: false,
                        width: '15%',
                        value: 'amount'
                    },
                    {
                        text: 'Bill on Behavior',
                        align: 'start',
                        sortable: false,
                        width: '25%',
                        value: 'bill_on_behavior'
                    },
                    {
                        text: '', 
                        value: 'actions',
                        sortable: false,
                        width: '15%',
                    }
                ]

            },
            extendedBpHeaders () {
                return [
                    {
                        text: 'Description',
                        align: 'start',
                        sortable: false,
                        width: '35%',
                        value: 'description'
                    },
                    {
                        text: 'Type',
                        align: 'start',
                        sortable: false,
                        width: '10%',
                        value: 'type'
                    },
                    {
                        text: 'Included / Limit',
                        align: 'start',
                        sortable: false,
                        width: '10%',
                        value: 'included'
                    },
                    {
                        text: 'Rate',
                        align: 'start',
                        sortable: false,
                        width: '13%',
                        value: 'rate'
                    },
                    {
                        text: 'Value',
                        align: 'start',
                        sortable: false,
                        width: '13%',
                        value: 'value'
                    },
                    {
                        text: '', 
                        value: 'actions',
                        sortable: false,
                        width: '14%',
                    }
                ]
            },
                        
            extendedBpDataWithTiers () {
                let arr = []
                this.extendedBpData.map(d => {
                    arr.push(d)
                    if (d.type === 'tiered') {
                        let i = 0
                        d.tiers.map(t => {
                            arr.push({
                                description: t.description,
                                rate: t.rate,
                                value: t.value,
                                included: t.limit,
                                parent: d.name,
                                index: i++,
                                isTier: true
                            })
                        })
                    }
                })
                return arr
            },
            ...mapGetters('profile', ['billingGroupGetter']),
            boAdditionalChargesDisplay () {
                let arr = []
                let id = 0
                this.boAdditionalCharges.map(ac => {
                    arr.push({
                        description: ac.description,
                        amount: `${ac.type === 'paid' ? '$': ''}${ac.amount}${ac.type === 'percent' ? '% ': ''}`,
                        bill_on_behavior: ac.bill_on_behavior === 'persist'? 'Persist': 'Reset',
                        id: id++
                    })
                })
                return arr
            },
            boAdditionalChargesSave () {
                let arr = []
                this.boAdditionalCharges.map(ac => {
                    let obj = {
                        description: ac.description,
                        bill_on_behavior: ac.bill_on_behavior,
                        amount: ac.amount,
                        percent: ac.type === 'percent'
                    }
                    arr.push(obj)
                })
                return arr
            },
            boAdditionalDiscountsSave () {
                let arr = []
                this.boAdditionalDiscounts.map(ac => {
                    let obj = {
                        description: ac.description,
                        bill_on_behavior: ac.bill_on_behavior,
                        amount: ac.amount,
                        percent: ac.type === 'percent'
                    }
                    arr.push(obj)
                })
                return arr
            },
            boAdditionalDiscountsDisplay () {
                let arr = []
                let id = 0
                this.boAdditionalDiscounts.map(ac => {
                    arr.push({
                        description: ac.description,
                        amount: `${ac.type === 'paid' ? '$': ''}${ac.amount}${ac.type === 'percent' ? '% ': ''}`,
                        bill_on_behavior: ac.bill_on_behavior === 'persist'? 'Persist': 'Reset',
                        id: id++
                    })
                })
                return arr
            }

        },
        methods: {
            ...mapActions('billing', ['getAllGroups']),    
            async getGroups(val, persistMessages) {
                this.isLoadingGroupsSearch = true;
                try {
                        this.allGroups = await this.getAllGroups()

                    if (!persistMessages) {
                        this.successMsg = ''
                        this.errorMsg = ''
                    }

                    this.isLoadingGroupsSearch = false;

                } catch (e) {
                    this.isLoadingGroupsSearch = false;
                    defaultErrorHandler(e);
                }
            },                
            convertCharges (charges) {
                let arr = []
                if (charges && charges.length) {
                    charges.map(c => {
                        arr.push({
                        description: c.description,
                        amount: c.amount,
                        type: c.percent ? 'percent' : 'paid',
                        bill_on_behavior: c.bill_on_behavior
                        })
                    })
                }
                return arr
            },
            toggleType () {
                if (this.editACChargeType === 'paid') {
                    this.editACChargeType = 'percent' 
                } else {
                    this.editACChargeType = 'paid'
                }
            },
            deleteAdditionalCharge (charge) {
                this.boAdditionalCharges.splice(charge.id, 1)
            },
            deleteAdditionalDiscount (charge) {
                this.boAdditionalDiscounts.splice(charge.id, 1)
            },
            startAdditionalCharge (charge) {

                this.editACTitle = "Bill on Additional Charge"
                this.additionalMode = 'charge'
                if (charge) {
                    this.editACLabel = 'Edit Additional Charge'
                    this.editACDescription = charge.description
                    this.editACChargeType = (charge.amount+'').indexOf('%') >= 0 ? 'percent' : 'paid'
                    this.additionalChargeEdit = charge
                    this.editACAction = charge.bill_on_behavior
                    this.editACAmount = charge.amount.replace('$','').replace('%','')
                } else {
                    this.editACLabel = 'Add Additional Charge'
                    this.editACDescription = ''
                    this.editACAmount = null
                    this.editACChargeType = 'paid'
                    this.additionalChargeEdit = null
                    this.editACAction = 'persist'
                }
                this.showAdditionalCharge = true
            },
            startAdditionalDiscount (charge) {

                this.editACTitle = "Bill on Additional Discount"
                this.additionalMode = 'discount'
                if (charge) {
                    this.editACLabel = 'Edit Additional Discount'
                    this.editACDescription = charge.description
                    this.editACChargeType = (charge.amount+'').indexOf('%') >= 0 ? 'percent' : 'paid'
                    this.additionalChargeEdit = charge
                    this.editACAction = charge.bill_on_behavior
                    this.editACAmount = charge.amount.replace('$','').replace('%','')
                } else {
                    this.editACLabel = 'Add Additional Discount'
                    this.editACDescription = ''
                    this.editACAmount = null
                    this.editACChargeType = 'paid'
                    this.additionalChargeEdit = null
                    this.editACAction = 'persist'
                }
                this.showAdditionalCharge = true
            },

            doAdditionalCharge () {

                let charge = {
                    description: this.editACDescription,
                    amount: parseFloat( (this.editACAmount + '').replace('$','').replace('%','') ),
                    type: this.editACChargeType,
                    bill_on_behavior: this.editACAction.toLowerCase()
                }

                if (this.additionalMode === 'discount') {
                    if (this.additionalChargeEdit) {
                        this.boAdditionalDiscounts.splice(this.additionalChargeEdit.id, 1, charge)
                    } else {
                        this.boAdditionalDiscounts.push(charge)
                    }

                } else {

                    if (this.additionalChargeEdit) {
                        this.boAdditionalCharges.splice(this.additionalChargeEdit.id, 1, charge)
                    } else {
                        this.boAdditionalCharges.push(charge)
                    }
                }
                this.showAdditionalCharge = false
            },
            item_class (item) {
                if (!item.type) {
                    return ['tier']
                }
            },
            async updateBillingPlan () {
                console.log('this.extendedBpData', this.extendedBpData)
                await this.updateBillingGroup()
            },
            addTier(item) {
                this.editingItem = null
                this.addingItem = item

                this.editBPLabel = 'Add tier'
                this.editBPIncluded = item.included
                this.editBPRate = item.rate
                this.editBPValue = item.value
                this.editBPType = 'addTier'
                this.editBPIncludedLabel = "Limit"
                this.editBPDDescription = ''

                this.editBPItemExtended = true
            },
            deleteTier(item) {
                let editparent = this.extendedBpData.find(i => {
                    return item.parent === i.name
                })

                if (editparent) {
                    editparent.tiers.splice(item.index, 1)
                }
            },
            editItemExtended(item) {
                this.editBPLabel = `Edit ${item.description}`
                this.editBPIncluded = item.included
                this.editBPRate = item.rate
                this.editBPValue = item.value
                this.editBPType = item.type
                this.editBPDDescription = item.description

                if (item.isTier) {
                    this.editBPType = 'editTier'
                }
                
                this.editingItem = item

                this.editBPItemExtended = true
            },
            doEditBPItemExtended () {
                console.log('this.extendedBpData', this.extendedBpData)

                if (this.editBPType === 'addTier') {
                    let parent = this.extendedBpData.find(i => {
                        return this.addingItem.name === i.name
                    })

                    if (parent) {
                        parent.tiers.push({
                            description: this.editBPDDescription,
                            rate: this.editBPRate,
                            value: this.editBPValue,
                            limit: this.editBPIncluded,
                            parent: parent.name,
                            index: parent.tiers.length,
                            isTier: true
                        })
                    }
                } else if (this.editBPType === 'editTier') {
                    let editparent = this.extendedBpData.find(i => {
                        return this.editingItem.parent === i.name
                    })
                    if (editparent) {
                        editparent.tiers[this.editingItem.index].description = this.editBPDDescription
                        editparent.tiers[this.editingItem.index].rate = this.editBPRate
                        editparent.tiers[this.editingItem.index].value = this.editBPValue
                        editparent.tiers[this.editingItem.index].limit = this.editBPIncluded
                        /*
                        parent.tiers.push({
                            description: this.editBPDDescription,
                            rate: this.editBPRate,
                            value: this.editBPValue,
                            limit: this.editBPIncluded,
                            parent: parent.name,
                            index: parent.tiers.length,
                            isTier: true
                        })
                        */
                    }
                } else {
                    let item = this.extendedBpData.find(i => {
                        return this.editingItem.name === i.name
                    })
                    console.log('item', item) 

                    if (item.isTier) {

                    } else {
                        item.rate = this.editBPRate ? this.editBPRate : null
                        item.value = this.editBPValue ? this.editBPValue : null
                        item.included = this.editBPIncluded ? this.editBPIncluded : null
                    }

                }

                this.editBPItemExtended = false
            },

            editItem(item) {
                this.editBPLabel = `Edit ${item.description}`
                this.editBPIncluded = item.included
                this.editBPRate = item.rate
                this.editBPValue = item.value

                this.editBPItem = true
            },
            doEditBPItem () {
            },
            updateObject () {
                let obj = {}
                for (let prop in this.currentBillingGroup) {
                    switch (prop) {
                        default: 
                            obj[prop] = this.currentBillingGroup[prop]
                            break
                            
                        case 'address':
                            let add = {}

                            for (let a in this.currentBillingGroup[prop]) {
                                switch (a) {
                                    case '__typename':
                                        break
                                    default:
                                        add[a] = this.currentBillingGroup[prop][a]
                                        break
                                }
                            }

                            obj[prop] = add

                            break
                        case 'group_id': 
                        case 'group_id_postgres': 
                        case '__typename': 
                            break;
                    }
                }
                return obj
            },
            route(name, transition) {

                this.$router.push({
                    name: name,
                    params: {
                        transition: transition || this.transition
                    }
                }).catch(err => { console.log('router error: ', err) });
            },
            selectAllProviders() {
              this.selectedProviders = this.searchedProviders;
            },
            async getGroupsOld(val, persistMessages) {
                this.isLoadingGroupsSearch = true;
                try {
                    const response = await this.$apollo.query({
                        query: SEARCH_GROUPS,
                        variables: {
                            group_id: null
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoadingGroupsSearch = false;
                    console.log('phxGroups response: ', response);
                    if (response.data.phxGroups) {
                      if (!val) {
                        this.allGroups = response.data.phxGroups.sort(function(a, b) {
                           return a.name.trim().localeCompare(b.name.trim());
                        });
                      }
                    } else if (response.errors && response.errors.length > 0) {
                        if (response.errors[0].message) {
                            this.errorMsg = response.errors[0].message;
                        } else {
                            this.errorMsg = "Error 2E. Invalid response.";
                        }
                    } else {
                        // data did not come back
                        console.log('phxGroups data did not come back: ', response);
                    }
                    if (!persistMessages) {
                        this.successMsg = ''
                        this.errorMsg = ''
                    }
                } catch (e) {
                    this.isLoadingGroupsSearch = false;
                    defaultErrorHandler(e);
                }
            },
            async getBillingGroup(val) {
                // console.log('val: ', val);
                this.isLoading = true;
                try {
                    const response = await this.$apollo.query({
                        query: GET_GROUP_BILLING,
                        variables: {
                            group_id: val.id
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });

                    this.isLoading = false;
                    console.log('billingGroup response: ', response);
                    if (response.data && response.data.billingGroup && response.data.billingGroup.length) {
                      if (response.data.billingGroup[0]) {
                          console.log('currentBillingGroup Data: ', response.data.billingGroup[0]);
                          this.currentBillingGroup = response.data.billingGroup[0];
                            this.successMsg = ''
                            this.errorMsg = ''
                            this.bpData = response.data.billingGroup[0].billing_plan
                            this.extendedBpData = response.data.billingGroup[0].extended_billing_plan
                            this.boAdditionalCharges = this.convertCharges(response.data.billingGroup[0].bill_on_additional_charges)
                            this.boAdditionalDiscounts = this.convertCharges(response.data.billingGroup[0].bill_on_additional_discounts)
                            /*
                          this.altName = this.currentBillingGroup.altName;
                          this.name = this.currentBillingGroup.name;
                          this.altName = val.alt_name;
                          this.name = val.name;
                          this.shortCode = this.currentBillingGroup.shortCode;
                          */
                      }
                    } else if (response.errors && response.errors.length > 0) {
                        this.successMsg = ''
                        if (response.errors[0].message) {
                            this.errorMsg = response.errors[0].message;
                        } else {
                            this.errorMsg = "Error 2E. Invalid response.";
                        }
                    } else {
                        // data did not come back
                        this.successMsg = ''
                        this.errorMsg = "There is no billing data for this group";
                        console.log('billingGroup data did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoading = false;
                    defaultErrorHandler(e);
                }
            },
            async updateBillingGroup() {
                this.isLoading = true;

                let obj = JSON.parse(JSON.stringify(this.extendedBpData)) //quick clone
                removePropertiesFromObject(obj, ['__typename'])

                let acObj = JSON.parse(JSON.stringify(this.boAdditionalChargesSave))
                let adObj = JSON.parse(JSON.stringify(this.boAdditionalDiscountsSave))

                try {
                    const response = await this.$apollo.mutate({
                        mutation: UPDATE_BILLING_GROUP,
                        variables: {
                            group_id: this.selectedGroup.id,
                            //billing_group: this.updateObject() /*{
                            extended_billing_plan: obj ,
                            bill_on_additional_charges: acObj, 
                            bill_on_additional_discounts: adObj                           
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });

                    this.isLoading = false;
                    console.log('billingGroupUpdate response: ', response);
                    if (response.data.billingGroupUpdate) {
                      // this.templateSearched = response.data.phxShifts;
                      this.successMsg = `You have successfully updated the group!`
                      this.getGroups(null, true);
                    } else if (response.errors && response.errors.length > 0) {
                        if (response.errors[0].message) {
                            this.errorMsg = response.errors[0].message;
                        } else {
                            this.errorMsg = "Error 2E. Invalid response.";
                        }
                    } else {
                        // data did not come back
                        console.log('billingGroupUpdate data did not come back: ', response);
                    }
                    this.clearFields();
                } catch (e) {
                    this.isLoading = false;
                    this.clearFields();
                    defaultErrorHandler(e);
                }
            },
            clearFields(fromWatch) {
              if (!fromWatch) {
                this.selectedGroup = null;
              }
              this.currentBillingGroup = null;
              // this.name = '';
              //this.altName = '';
              //this.shortCode = '';
              this.errorMsg = '';
            },
            changeView(view, params) {

                this.$emit('onChangeView', {view: view, params: params});
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
        },
        watch: {
          selectedGroup(val) {
            this.clearFields(true);

            if (!val || !val.id) return;

            this.getBillingGroup(val);

            this.$store.commit('profile/setBillingGroup', val)

          }

        }
    }

</script>

<style>
    tr.tier td:nth-child(1) {text-align: right !important;font-style: italic;}
     .toplevel .v-data-table-header {
      height: 50px;
    }
</style>
</style>
