import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store';

import Splash from './views/splash.vue'
import Authenticate from './views/authentication/authenticate.vue'
import Login from './views/authentication/login.vue'

import Register from './views/authentication/register.vue'
import RegisterAuthenticate from './views/authentication/register_authenticate.vue'
import RegisterPassword from './views/authentication/register_password.vue'
import RegisterForgotPassword from './views/authentication/register_forgot_password.vue'

import PCPOptin from './views/authentication/PCPoptin.vue'
import MFACode from './views/authentication/mfa_code.vue'
import SetPassword from './views/authentication/create_password.vue'
import ForgotPassword from './views/authentication/forgot_password.vue'
import AuthenticationContactUs from './views/authentication/contact.vue'
import AuthenticationFAQ from './views/authentication/faq.vue'
import AuthenticationFAQLogin from './views/authentication/faq_login.vue'
import RedirectMobile from './views/authentication/redirect_mobile.vue'
import Settings from './views/settings.vue'
import Directory from './views/directory.vue'
import Charts from './views/charts.vue'
import Chat from './views/chat.vue'
import Schedule from './views/schedule.vue'
import MessageMenu from './views/messages/message_menu.vue'
import Users from './views/messages/users.vue'
import UserDetails from './views/messages/user_details.vue'

import Admin from './views/apps/pcpAppAdmin/admin.vue'
import Providers from './views/apps/pcpAppAdmin/providers/providers.vue'
import Notifications from './views/apps/pcpAppAdmin/notifications/notifications.vue'
import ManageUsers from './views/apps/pcpAppAdmin/manageUsers/manage-users.vue'
import Dashboard from './views/apps/pcpAppAdmin/dashboard/dashboard.vue'

import Scheduling from './views/apps/scheduling/scheduling.vue'
import SchedulingCalendar from './views/apps/scheduling/calendar/calendar.vue'

import PageNotFound from './views/page-not-found.vue'
import PageNoAccess from './views/page-no-access.vue'

import Scripts from './views/apps/scripts/scripts.vue'
import ProviderLink from './views/apps/scripts/link/provider_link.vue'
import RemoveFromChat from './views/apps/scripts/removeFromChat/remove_from_chat.vue'
import AutoInjectShifts from './views/apps/scripts/autoInjectShifts/auto_inject_shifts.vue'
import CannedResponse from './views/apps/scripts/cannedResponse/canned_response.vue'
import UserImport from './views/apps/scripts/userImport/user-import.vue'
import FacilityDelete from './views/apps/scripts/facilityDelete/facility_delete.vue'
import Broadcast from './views/apps/scripts/broadcast/broadcast.vue'
import AutoEnroll from './views/apps/scripts/autoEnroll/auto_enroll.vue'

import Ivr from './views/apps/scripts/ivr/ivr.vue'

import Group from './views/apps/group/group.vue'
import GroupInbox from './views/apps/group/inbox/inbox.vue'
import GroupEditProfile from './views/apps/group/profile/edit-profile.vue'

import PCPUser from './views/apps/pcpApp/pcp_user.vue';
import PCPUserNotifications from './views/apps/pcpApp/notifications/notifications.vue'
import PCPUserNotificationsFiltered from './views/apps/pcpApp/notificationsFiltered/notifications-filtered.vue'

import Billing from './views/apps/billing/billing.vue'
import UpdateGroup from './views/apps/billing/updateGroup/update-group.vue'
import UpdateBillingPlan from './views/apps/billing/updateGroup/update-billing-plan.vue'
import Invoice from './views/apps/billing/invoice/invoice.vue'
import Invoices from './views/apps/billing/invoice/invoices.vue'
import PaymentMethods from './views/apps/billing/invoice/payment-methods.vue'

import Sample from './views/apps/pcpAppAdmin/sample/sample.vue'
import SampleSubscribe from './views/apps/pcpAppAdmin/sample/subscribeToMessages.vue'

import Reports from './views/apps/reports/reports.vue'
import Report from './views/apps/reports/report/report.vue'


import { checkHasTokensPhoenix, checkHasTokensPCP } from './methods/globalMethods';

const adminRoles = ['pcp_admin', 'pcp_group_admin', 'pcp_facility_admin', 'pcp_analyst']
const adminRolesNoAnalyst = ['pcp_admin', 'pcp_group_admin', 'pcp_facility_admin']
const groupRoles = ['member', 'member.admin']

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/auth'
  },
  {
    path: '/auth',
    component: Authenticate,
    redirect: '/auth/login',
    meta: { fullscreen: true },
    children: [
      {
        path: 'login',
        name: 'login',
        component: Login
      },
      {
        path: 'register',
        name: 'register',
        component: Register
      },
      {
        path: 'registerAuth',
        name: 'registerAuthenticate',
        component: RegisterAuthenticate
      },
      {
        path: 'registerPassword',
        name: 'registerPassword',
        component: RegisterPassword
      },
      {
        path: 'registerForgotPassword',
        name: 'registerForgotPassword',
        component: RegisterForgotPassword
      },
      {
        path: 'pcpoptin',
        name: 'pcpoptin',
        component: PCPOptin
      },
      {
        path: 'forgot',
        name: 'forgotPassword',
        component: ForgotPassword
      },
      {
        path: 'code',
        name: 'mfaCode',
        component: MFACode
      },
      {
        path: 'newpassword',
        name: 'newPass',
        component: SetPassword
      },
      {
        path: 'faq',
        name: 'authFAQ',
        component: AuthenticationFAQ
      },
      {
        path: 'faqLogin',
        name: 'authFAQLogin',
        component: AuthenticationFAQLogin
      },
      {
        path: 'redirectmobilepcp',
        name: 'redirectMobile',
        component: RedirectMobile
      },
      {
        path: 'contactUs',
        name: 'authContactUs',
        component: AuthenticationContactUs
      }
    ]
  },
  {
    path: '/admin',
    component: Admin,
    redirect: '/admin/providers',
    meta: {
      fullscreen: false,
       authentication: true,
       whitelistRoles: adminRoles
    },
    children: [
      {
        path: 'dashboard',
        name: 'ADMIN.dashboard',
        component: Dashboard,
        meta: {
          authentication: true,
          whitelistRoles: adminRoles
        }
      },
      {
        path: 'providers',
        name: 'ADMIN.providers',
        component: Providers,
        meta: {
          authentication: true,
          whitelistRoles: adminRoles
        }
      },
      {
        path: 'notifications',
        name: 'ADMIN.notifications',
        component: Notifications,
        meta: {
          authentication: true,
          whitelistRoles: adminRolesNoAnalyst,
          blacklistRoles: ['pcp_analyst']
        }
      },
      {
        path: 'sample',
        name: 'ADMIN.sample',
        component: Sample,
        meta: {
          authentication: true,
          whitelistRoles: adminRoles
        }
      },
      {
        path: 'sampleSubscribe',
        name: 'sampleSubscribeToMessages',
        component: SampleSubscribe,
        meta: {
          authentication: true,
          whitelistRoles: adminRoles
        }
      },
      {
        path: 'mngusers',
        name: 'ADMIN.manageUsers',
        component: ManageUsers,
        meta: {
          authentication: true,
          whitelistRoles: adminRolesNoAnalyst,
          blacklistRoles: ['pcp_analyst']
        }
      }
    ]
  },
  {
    path: '/scheduling',
    component: Scheduling,
    redirect: '/scheduling/calendar',
    meta: { fullscreen: false },
    children: [
      {
        path: 'calendar',
        name: 'SCHEDULING.calendar',
        component: SchedulingCalendar
      }
    ]
  },
  {
    path: '/scripts',
    component: Scripts,
    redirect: '/scripts/link-providers',
    meta: {
      authentication: true,
      fullscreen: false,
      whitelistRoles: ['pcp_admin']
    },
    children: [
      {
        path: 'link-providers',
        name: 'SCRIPTS.link-providers',
        component: ProviderLink,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_admin']
        }
      },
      {
        path: 'remove-from-chat',
        name: 'SCRIPTS.remove-from-chat',
        component: RemoveFromChat,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_admin']
        }
      },
      {
        path: 'auto-inject-shifts',
        name: 'SCRIPTS.auto-inject-shifts',
        component: AutoInjectShifts,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_admin']
        }
      },
      {
        path: 'ivr',
        name: 'SCRIPTS.ivr',
        component: Ivr,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_admin']
        }
      },
      {
        path: 'add-canned-response',
        name: 'SCRIPTS.add-canned-response',
        component: CannedResponse,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_admin']
        }
      },
      {
        path: 'user-import',
        name: 'SCRIPTS.user-import',
        component: UserImport,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_admin']
        }
      },
      {
        path: 'facility-delete',
        name: 'SCRIPTS.facility-delete',
        component: FacilityDelete,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_admin']
        }
      },
      {
        path: 'broadcast',
        name: 'SCRIPTS.broadcast',
        component: Broadcast,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_admin']
        }
      },
      {
        path: 'auto-enroll',
        name: 'SCRIPTS.auto-enroll',
        component: AutoEnroll,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_admin']
        }
      },
    ]
  },
  {
    path: '/billing',
    component: Billing,
    redirect: '/billing',
    meta: {
      authentication: true,
      fullscreen: false,
      whitelistRoles: ['pcp_admin']
    },
    children: [
      {
        path: 'update-group',
        name: 'BILLING.group-update',
        component: UpdateGroup,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_admin']
        }
      },
      {
        path: 'update-billing-plan',
        name: 'BILLING.group-billing-plan',
        component: UpdateBillingPlan,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_admin']
        }
      },
      {
        path: 'payment-methods',
        name: 'BILLING.payment-methods',
        component: PaymentMethods,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_admin']
        }
      },
      {
        path: 'invoice',
        name: 'BILLING.invoice',
        component: Invoice,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_admin']
        }
      },
      {
        path: 'invoices',
        name: 'BILLING.invoices',
        component: Invoices,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_admin']
        }
      }
    ]
  },
  {
    path: '/reports',
    component: Reports,
    redirect: '/reports',
    meta: {
      authentication: true,
      fullscreen: false,
      whitelistRoles: ['pcp_admin']
    },
    children: [
      {
        path: 'report',
        name: 'REPORTS.report',
        component: Report,
        props: true,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_admin']
        }
      },
      {
        path: 'update-billing-plan',
        name: 'REPORTS.group-billing-plan',
        component: UpdateBillingPlan,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_admin']
        }
      },
      {
        path: 'invoice',
        name: 'REPORTS.invoice',
        component: Invoice,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_admin']
        }
      }
    ]
  },
  {
    path: '/pcp-user',
    component: PCPUser,
    redirect: '/pcp-user',
    meta: {
      authentication: true,
      fullscreen: false,
      whitelistRoles: ['pcp_user']
    },
    children: [
      {
        path: 'notifications',
        name: 'PCPUSER.notifications',
        component: PCPUserNotifications,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_user']
        }
      },
      {
        path: 'notifications-filtered',
        name: 'PCPUSER.notifications-filtered',
        component: PCPUserNotificationsFiltered,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_user']
        }
      }
    ]
  },
  {
    path: '/group',
    component: Group,
    redirect: '/group',
    meta: {
      phoenixAuthentication: true,
      fullscreen: false,
      whitelistRoles: groupRoles
    },
    children: [
      {
        path: 'edit-profile',
        name: 'GROUP.edit-profile',
        component: GroupEditProfile,
        meta: {
          phoenixAuthentication: true,
          whitelistRoles: groupRoles
        }
      },
      {
        path: 'inbox',
        name: 'GROUP.inbox',
        component: GroupInbox,
        meta: {
          phoenixAuthentication: true,
          whitelistRoles: groupRoles
        }
      }
    ]
  },
  {
    path: "/no-access",
    name: '403',
    component: PageNoAccess
  },
  {
    path: "*",
    name: '404',
    component: PageNotFound
  }
];

const Router = new VueRouter({
  mode: 'history',
  routes
});

function route(name, direction) {
    Router.push({
        name: name, params: {
            transition: direction
        }
    }).catch(err => { console.log('router error: ', err) });
}

Router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.fullscreen)) {
    store.commit('layout/setFullscreen', true)
  }
  else {
    store.commit('layout/setFullscreen', false)
  }

  // console.log('to: ', to.name, 'from: ', from.name);

  ////
  // check authentication
  ////
  // check if access token is needed for PCP
  if (to.meta.authentication && !checkHasTokensPCP()) {
    console.log('PCP token does not exist');
    next({ name: 'login' });
    return;
  }
  // check if phoenix token is needed
  if (to.meta.phoenixAuthentication && !checkHasTokensPhoenix()) {
    console.log('Phoenix token does not exist');
    next({ name: 'login' });
    return;
  }
  ////

  // check if you have roles
  const roles = JSON.parse(localStorage.getItem("userRoles"));
  console.log('roles? : ', roles);
  if (!roles && (to.meta.whitelistRoles || to.meta.blacklistRoles)) {
    if (to.name !== 'login') {
        next({ name: 'login' });
        console.log('Roles do not exist, routing back to login...');
        return;
    }
    next();
    return;
  };
  // check whitelist
  console.log('to: ', to);
  if (to.meta && to.meta.whitelistRoles) {
    for (let role in to.meta.whitelistRoles) {
      const currentRole = to.meta.whitelistRoles[role];
      // if you have the role, go next
      if (roles[currentRole]) {
        next();
        return;
      }
    }
    // if did not pass the whitelist check
    console.log('did not pass wl');
    next({ name: '403' });
    return;
  }
  // check blacklist
  if (to.meta && to.meta.blacklistRoles) {
    for (let role in to.meta.blacklistRoles) {
      const currentRole = to.meta.blacklistRoles[role];
      // if you have the role, go next
      if (roles[currentRole]) {
        next({ name: '403' });
        return;
      }
    }
  }

  next();
});

export default Router
