<template>

    <v-app light class="relative full-width"  :style="`height: ${ innerHeight }px !important`">

        <router-view class="absolute full-width full-height" />

    </v-app>

</template>


<script>

    import {mapActions} from "vuex";
    import debounce from 'debounce';
    import innerHeight from 'ios-inner-height';

    export default {
        name: 'app',
        data: () => ({
            transition: 'fade-transition',
            height: 0,
            innerHeight: 0,
            fullscreen: false,
            prompt: false
        }),
        created() {
            let installPrompt;

            window.addEventListener("beforeinstallprompt", e => {
                e.preventDefault();
                installPrompt = e;
                this.installBtn = "block";
            });

            this.installer = () => {
                this.installBtn = "none";
                installPrompt.prompt();
                installPrompt.userChoice.then(result => {
                    if (result.outcome === "accepted") {
                        console.log("Install accepted!")
                    } else {
                        console.log("Install denied!")
                    }
                });
            };
        },
        mounted() {

            this.height = innerHeight();
            this.innerHeight = window.innerHeight;

            window.onresize = debounce(this.updateResize, 30);

            // Add or remove the header based on which route meta matches
            this.fullscreen = this.$store.getters['layout/fullscreen'];

            // Determine whether the menu is open by default based on screen size
            //this.$vuetify.breakpoint.xsOnly ? this.closeMenu() : this.openMenu();

        },
        methods: {
            ...mapActions('menu', ['openMenu','closeMenu','toggleMenu']),
            updateResize() {

                this.innerHeight = window.innerHeight;
            },
            route(name, transition) {
                console.log('name: ', name);

                this.$router.push({
                    name: name,
                    params: {
                        transition: transition || this.transition
                    }
                }).catch(err => { console.log('router error: ', err) });
            },
            async accept() {
                this.showUpgradeUI = false
                await this.$workbox.messageSW({ type: "SKIP_WAITING" });
            }
        },
        watch: {
            '$route'(to) {
                this.transition = (to.params.transition) ? to.params.transition : 'fade-in-transition';
            }
        },
        computed: {}
    }
</script>

<style>

    .v-application--wrap {
        min-height: auto !important;
        height: 100% !important;
    }

    .view {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex: 1 1 auto;
        flex-wrap: nowrap;
        min-width: 0;
    }

    ::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #eceff1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #b4c1c7;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #177ad5;
    }

    .search-bar.v-text-field--solo > .v-input__control > .v-input__slot {

        background: #125ea3 !important;
        border-right: 0;
    }

    .v-app-bar .v-toolbar__content {
        padding-left: 0 !important;
    }

    .v-navigation-drawer__border {
        width: 0;
    }

    .v-input, .v-label {
        font-size: 12px;
    }

    .v-text-field--outlined fieldset  {
        border-color: #ced8dd;
    }

    .v-menu__content.menuable__content__active {
        max-height: 420px !important;
    }

    /*.v-select.v-text-field input {*/
    /*    display: none;*/
    /*}*/

    .ycmd-light-gray {
        background-color: #F0F6FA !important;
    }

    .ycmd-gray {
        background-color: #cdd6da !important;
    }

    .ycmd-green {
        background-color: #32cd32 !important;
    }

    .ycmd-green--text {
        color: #32cd32 !important;
    }

    .ycmd-light-blue {
        background-color: #83a9d8 !important;
    }

    .ycmd-light-blue--text {
        color: #00BFFF !important;
    }

    .ycmd-blue {
        background-color: #177ad5 !important;
    }

    .ycmd-blue--text {
        color: #177ad5 !important;
    }

    .ycmd-medium-blue {
        background-color: #125ea3 !important;
    }

    .ycmd-medium-blue--text {
        color: #125ea3 !important;
    }

    .ycmd-dark-blue {
        background-color: #125EA3 !important;
    }

    .ycmd-dark-blue--text {
        color: #0d406f !important;
    }

    .ycmd-black {
        background-color: #101622 !important;
    }

    .ycmd-black--text {
        color: #101622 !important;
    }

    .ycmd-blue-gradient {
        background: linear-gradient(145deg, #177ad5 35%, #00bfff 80%);
    }

    .ycmd-black-gradient {
        background: radial-gradient(ellipse at center, #4c586a 30%, #101622 100%);
    }

    section {
        /*min-height: 100%;*/
        display: grid;
        grid-template: "section";
        flex: 1;
        position: relative;
        z-index: 0;
    }

    section > * {
        grid-area: section; /* Transition: make sections overlap on same cell */
        position: relative;
    }

    section > :first-child {
        z-index: 1; /* Prevent flickering on first frame when transition classes not added yet */
    }

    .next-leave-to {
        /*animation: leaveToLeft 200ms both cubic-bezier(0.165, 0.84, 0.44, 1);*/
        transform: translateX(0);
        z-index: 0;
    }

    .next-enter-to {
        animation: enterFromRight 200ms both cubic-bezier(0.165, 0.84, 0.44, 1);
        z-index: 1;
    }

    .prev-leave-to {
        /*animation: leaveToRight 200ms both cubic-bezier(0.165, 0.84, 0.44, 1);*/
        transform: translateX(0);
        z-index: 1;
    }

    .prev-enter-to {
        animation: enterFromLeft 200ms both cubic-bezier(0.165, 0.84, 0.44, 1);
        z-index: 0;
    }

    .up-leave-to {
        animation: leaveToTop 200ms both cubic-bezier(0.165, 0.84, 0.44, 1);
        z-index: 0;
    }

    .up-enter-to {
        animation: enterFromBottom 200ms both cubic-bezier(0.165, 0.84, 0.44, 1);
        z-index: 1;
    }

    .down-leave-to {
        animation: leaveToBottom 200ms both cubic-bezier(0.165, 0.84, 0.44, 1);
        z-index: 0;
    }

    .down-enter-to {
        animation: enterFromTop 200ms both cubic-bezier(0.165, 0.84, 0.44, 1);
        z-index: 1;
    }

    @keyframes leaveToLeft {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(-25%);
            /*filter: brightness(0.5);*/
        }
    }

    @keyframes enterFromLeft {
        from {
            transform: translateX(-25%);
            /*filter: brightness(0.5);*/
        }
        to {
            transform: translateX(0);
        }
    }

    @keyframes leaveToRight {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(100%);
        }
    }

    @keyframes enterFromRight {
        from {
            transform: translateX(100%);
        }
        to {
            transform: translateX(0);
        }
    }

    @keyframes leaveToTop {
        from {
            transform: translateY(0);
        }
        to {
            transform: translateY(100%);
        }
    }

    @keyframes enterFromTop {
        from {
            transform: translateY(-25%);
            /*filter: brightness(0.5);*/
        }
        to {
            transform: translateY(0);
        }
    }

    @keyframes enterFromBottom {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(0);
        }
    }

    @keyframes leaveToBottom {
        from {
            transform: translateY(0);
        }
        to {
            transform: translateY(-25%);
            /*transform: translateY(-25%);*/
            /*filter: brightness(0.5);*/
        }
    }

</style>
