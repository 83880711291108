<template>

  <div class="view ycmd-blue-gradient">

    <i-card :viewIndex="card.index"
            :viewCount="1"
            :leftPanel="card.panels.left"
            :rightPanel="card.panels.right"
            :topPanel="card.panels.top"
            :bottomPanel="card.panels.bottom"
            class="i-card">

      <div class="i-card__main pa-2">

        <div class="i-card__main__header">

          <div class="d-flex align-center flex-grow-1 ml-1">

            <v-tooltip bottom
                       nudge-right="72"
                       nudge-bottom="6"
                       :disabled="$vuetify.breakpoint.xsOnly"
                       color="ycmd-black"
                       content-class="fade-0 pa-4 pt-2 pb-2">
              <template v-slot:activator="{ on }">
                <v-btn dark icon depressed v-on="on"
                       @click="() => { messages = []; loadedData = false; changeView('list-view'); }" color="white">
                  <v-icon :size="22">arrow_back</v-icon>
                </v-btn>
              </template>
              <span>Show Notification List</span>
            </v-tooltip>
            <div class="white--text fs-16 ml-4">
              Notification Details
            </div>
          </div>
        </div>

        <div class="i-card__main__content white br-2 pa-2">
          <div v-if="!this.params.data.visit_id">Cannot look up, missing Visit ID.</div>

          <div v-if="isLoadingInit" style="margin-left: auto; margin-right: auto; margin-bottom: 50px;"
               class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>

          <div v-if="loadedData" class="i-card__scrollable pr-1">

            <v-container fluid class="pa-0">
              <div>
                <v-col cols="12" sm="12"
                       :class="`d-flex flex-column pb-2 pl-0 pr-0 ${ $vuetify.breakpoint.smAndUp ? '' : ''}`">
                  <div class="d-flex align-center pa-2 pt-0">
                    <div class="fs-14 fw-500">Provider</div>
                    <v-spacer/>
                    <div class="fs-12">
                      <span class="fw-500">ID: </span> {{ messages[0] ? messages[0].pcp_id : 'N/A' }}
                    </div>
                  </div>
                  <v-container fluid class="flex-grow-1 ycmd-light-gray pa-4 pb-3 ma-0 mb-2 br-2 b b-1">
                    <v-row>
                      <v-col cols="9">
                        <div class="label-title">Name</div>
                        <div class="label-value">
                          {{ messages[0] ? messages[0].pcp_prefix : '' }}
                          {{ messages[0] ? messages[0].pcp_given_name : 'N/A' }}
                          {{ messages[0] ? messages[0].pcp_family_name : 'N/A' }}
                          {{ messages[0] ? messages[0].pcp_suffix : '' }}
                        </div>
                      </v-col>
                      <v-col cols="3">
                        <div class="label-title">Degree</div>
                        <div class="label-value">{{ messages[0].pcp_degree ? messages[0].pcp_degree : 'N/A' }}</div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col cols="12" sm="12" :class="`pb-2 pb-2 pl-0 pr-0 ${ $vuetify.breakpoint.smAndUp ? '' : ''}`">
                  <div class="d-flex align-center pa-2 pt-0">
                    <div class="fs-14 fw-500">Patient</div>
                    <v-spacer/>
                    <div class="fs-12">
                      <span class="fw-500">ID: </span> {{ messages[0] ? messages[0].patient_id : 'N/A' }}
                    </div>
                  </div>
                  <v-container fluid class="ycmd-light-gray b b-1 br-2 pa-2 pl-4 pr-4">
                    <v-row>
                      <v-col cols="6" sm="3" md="3" lg="3">
                        <div class="label-title">Name</div>
                        <div class="label-value">
                          {{ messages[0] ? messages[0].patient_given_name : 'N/A' }}
                          {{ messages[0] ? messages[0].patient_middle_name : 'N/A' }}
                          {{ messages[0] ? messages[0].patient_family_name : 'N/A' }}
                        </div>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" lg="1">
                        <div class="label-title">Sex</div>
                        <div class="label-value">{{ messages[0] ? messages[0].patient_sex : 'N/A' }}</div>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" lg="2">
                        <div class="label-title">DOB</div>
                        <div class="label-value">{{ messages[0] ? messages[0].patient_dob : 'N/A' }}</div>
                      </v-col>
                      <v-col cols="6" sm="3" md="3" lg="3">
                        <div class="label-title">Visit ID</div>
                        <div class="label-value">{{ messages[0] ? messages[0]._visit : 'N/A' }}</div>
                      </v-col>
                      <v-col cols="6" sm="3" md="3" lg="3">
                        <div class="label-title">Hospital Service</div>
                        <div class="label-value">{{ messages[0] ? messages[0].visit_hospital_service : 'N/A' }}</div>
                      </v-col>
                      <v-col cols="12" sm="6" md="9" lg="6">
                        <div class="label-title">Reason</div>
                        <div class="label-value">{{ messages[0] ? messages[0].visit_admit_reason : 'N/A' }}</div>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="3">
                        <div class="label-title">Attending Doctors</div>
                        <div class="label-value">{{
                            (messages[0] && messages[0].visit_attending && messages[0].visit_attending.filter((item) => item.given_name || item.family_name).length) ? messages[0].visit_attending.map(val => val.given_name + ' ' + val.family_name).join(', ') : 'N/A'
                          }}
                        </div>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="3">
                        <div class="label-title">Admitting Doctors</div>
                        <div class="label-value">{{
                            (messages[0] && messages[0].visit_admitting && messages[0].visit_admitting.filter((item) => item.given_name || item.family_name).length) ? messages[0].visit_admitting.map(val => val.given_name + ' ' + val.family_name).join(', ') : 'N/A'
                          }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col v-if="(messages[0] && messages[0].diagnoses && messages[0].diagnoses.length)" cols="12" sm="12"
                       :class="`pb-2 pb-2 pl-0 pr-0 ${ $vuetify.breakpoint.smAndUp ? '' : ''}`">
                  <div class="d-flex align-center pa-2 pt-3">
                    <div class="fs-14 fw-500">Diagnoses</div>
                    <v-spacer/>
                  </div>
                  <v-container v-for="(diagnose, i) in messages[0].diagnoses" :key="`diagnose_${i}`" fluid
                               class="ycmd-light-gray b b-1 br-2 pa-2 pl-4 pr-4 mb-2">
                    <v-row>
                      <!-- <v-col cols="6" sm="6" md="3">
                          <div class="label-title">Name</div>
                          <div class="label-value">
                              {{ diagnose.clinician_given_name || 'N/A' }} {{ diagnose.clinician_family_name || 'N/A' }}
                          </div>
                      </v-col> -->
                      <v-col cols="6" sm="6" md="3">
                        <div class="label-title">Code</div>
                        <div class="label-value">{{ diagnose.code || 'N/A' }}</div>
                      </v-col>
                      <v-col cols="6" sm="6" md="3">
                        <div class="label-title">Coding Method</div>
                        <div class="label-value">{{ diagnose.coding_method || 'N/A' }}</div>
                      </v-col>
                      <v-col cols="6" sm="6" md="3">
                        <div class="label-title">Description</div>
                        <div class="label-value">{{ diagnose.description || 'N/A' }}</div>
                      </v-col>
                      <v-col cols="6" sm="6" md="3">
                        <div class="label-title">Priority</div>
                        <div class="label-value">{{ diagnose.priority || 'N/A' }}</div>
                      </v-col>
                      <v-col cols="6" sm="6" md="3">
                        <div class="label-title">Time</div>
                        <div class="label-value">{{
                            diagnose.time ? format(parseISO(diagnose.time), 'iii MMM d, yyyy h:mm a') + ' PST' : 'N/A'
                          }}
                        </div>
                      </v-col>
                      <v-col cols="6" sm="6" md="3">
                        <div class="label-title">Type</div>
                        <div class="label-value">{{ diagnose.type || 'N/A' }}</div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </div>
            </v-container>

            <div v-for="(message, i) in messages" :key="`user_${i}`" class="pt-3">
              <div class="d-flex align-center pa-2 pt-0">
                <div class="fs-14 fw-500">{{ getEventType[message.message_trigger_event] || 'N/A' }}</div>
                <v-spacer/>
                <div class="fs-12">
                  <span class="fw-500">ID: </span> {{ message._id }}
                </div>
              </div>
              <v-container fluid class="ycmd-light-gray pa-4 pb-3 ma-0 mb-2 br-2 b b-1">

                <v-row>
                  <v-col cols="6" md="2">
                    <div class="label-title">Facility</div>
                    <div class="label-value">
                      {{ message.message_sending_facility || 'N/A' }}
                    </div>
                  </v-col>
                  <v-col cols="6" md="2" class="hidden-xs-only">
                    <div class="label-title">Event</div>
                    <div class="label-value">{{ message.message_type || 'N/A' }} |
                      {{ message.message_trigger_event || 'N/A' }}
                    </div>
                  </v-col>
                  <v-col cols="6" md="4">
                    <div class="label-title">Occurred</div>
                    <div class="label-value">{{
                        format(parseISO(message.event_recorded_time), 'iii MMM d, yyyy h:mm a')
                      }} PST
                    </div>
                  </v-col>
                  <v-col cols="6" md="4" class="hidden-sm-and-up">
                    <div class="label-title pt-3">Event</div>
                    <div class="label-value">{{ message.message_type || 'N/A' }} |
                      {{ message.message_trigger_event || 'N/A' }}
                    </div>
                  </v-col>
                  <v-col cols="6" md="3">
                    <div :class="`label-title ${ $vuetify.breakpoint.xsOnly ? 'pt-3' : ''}`">Confirmed</div>
                    <div class="label-value">
                      {{
                        message.confirmed_time ? format(parseISO(message.confirmed_time), 'iii MMM d, yyyy h:mm a') + ' PST' : 'N/A'
                      }}
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </div>
        </div>

      </div>

      <template slot="right-panel">

        <div class="i-card__panel i-card__panel__right ycmd-blue-gradient pa-2">

          <div class="i-card__panel__header">

            <div class="d-flex align-center flex-grow-1">

              <v-tooltip bottom
                         nudge-bottom="10"
                         nudge-right="50"
                         :disabled="$vuetify.breakpoint.xsOnly"
                         color="ycmd-black"
                         content-class="fade-0 pa-4 pt-2 pb-2">
                <template v-slot:activator="{ on }">
                  <v-btn icon dark depressed v-on="on" @click="card.panels.right = false" class="mr-2">
                    <v-icon :size="22">close</v-icon>
                  </v-btn>
                </template>
                <span>Close Statistics</span>
              </v-tooltip>

              <div class="white--text fs-18 ml-3">
                Statistics
              </div>
            </div>
            <div class="d-flex align-center justify-end ml-2">

            </div>
          </div>

          <div class="i-card__panel__content white br-2 pa-2">

            <div class="i-card__scrollable">
              <div class="pa-3">
                Content
              </div>
            </div>
          </div>
        </div>

      </template>
    </i-card>
  </div>

</template>

<script>

import {format, parseISO} from 'date-fns';

import {defaultErrorHandler} from '../../../../graphql/handler/errorHandler';
import {refreshHandler} from '../../../../graphql/handler/refreshHandler';
import GET_MESSAGES from '../../../../graphql/queries/pcpMessagesBrowse';

export default {
  name: 'notificationDetails',
  props: {
    params: {
      type: Object
    }
  },
  data() {
    return {
      format,
      parseISO,
      isLoadingInit: false,
      getEventType: {
        "A01": "Admitted",
        "A02": "Patient Transfer",
        "A03": "Discharged",
        "A04": "Registered",
        "A06": "Outpatient to Inpatient",
        "A07": "Inpatient to Outpatient"
      },
      card: {
        index: 0,
        panels: {left: false, right: false, top: false, bottom: false}
      },
      messages: [],
      loadedData: false
    }
  },
  activated() {
    //console.log('activated: ', this.params.data.visit_id);
    // console.log('ACTIVATED', this.params.data.visit_id);
    this.loadedData = false;
    this.messages = [];
    this.fetchMessages();
  },
  methods: {
    async fetchMessages() {
      if (!this.params.data.visit_id) return;

      this.isLoadingInit = true;

      try {
        const response = await this.$apollo.query({
          query: GET_MESSAGES,
          variables: {
            limit: 50,
            visit_id: this.params.data.visit_id,
            event_type_code: ['A01', 'A02', 'A03', 'A04', 'A06', 'A07']
          },
          fetchPolicy: 'no-cache',
          errorPolicy: 'all'
        });
        this.isLoadingInit = false;
        // console.log('pcpMessagesBrowse - response for pcpImportProviders: ', response);

        if (response.data.pcpMessagesBrowse && response.data.pcpMessagesBrowse.messages && response.data.pcpMessagesBrowse.messages.length) {
          this.messages = response.data.pcpMessagesBrowse.messages;
          this.loadedData = true;
        } else {
          // data did not come back
          //console.log('pcpMessagesBrowse data did not come back or is finished: ', response);
        }
      } catch (e) {
        this.isLoadingInit = false;
        defaultErrorHandler(e);
      }
    },
    route(name, transition) {

      this.$router.push({
        name: name, params: {
          transition: transition
        }
      }).catch(err => {
        console.log('router error: ', err)
      });
    },
    changeView(view, params) {

      this.$emit('onChangeView', {view: view, params: params});
    },
    slide(direction) {
      this.card.panels[direction] = !this.card.panels[direction];
    }
  }
}

</script>

<style scoped>

.item-box {
  background-color: #fff;
  border-bottom: #177ad5 solid 1px;
}

.item-box.odd {
  background-color: #ECEFF1;
}

.item-box:hover {
  background-color: rgba(255, 255, 255, 0.8)
}

</style>
