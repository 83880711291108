<template>

  <v-layout column class="blue full-height">

    <v-toolbar dark text class="transparent">

      <v-btn icon depressed @click="route('users', 'prev')">
        <v-icon color="white">chevron_left</v-icon>
      </v-btn>

      <div class="fs-16 class pl-2">Dr. John Smith</div>

    </v-toolbar>

    <v-layout column class="white">

      <v-layout shrink class="white" style="height: 120px">
        <img src="/images/photos/woman1.jpg" style="height:100%"  />
      </v-layout>

      <v-layout class="pl-1 pr-1 pb-1">

        <v-layout class="blue br-2">


        </v-layout>

      </v-layout>

    </v-layout>

  </v-layout>

</template>

<script>

  export default {
    name: 'userDetails',
    methods: {
      route(name, transition) {

        this.$router.push({
          name: name, params: {
            transition: transition
          }
        }).catch(err => { console.log('router error: ', err) });
      }
    }
  }

</script>

<style scoped>

  /*.view {*/
  /*  position: absolute;*/
  /*  width: 100%;*/
  /*  height: 100%;*/
  /*}*/


</style>
