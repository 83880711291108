<template>
    <v-card
        dense
        class="pa-0"
    >
        <v-card-title
            class="pa-0"
        >
            {{prompt.label}}
            <v-switch
              v-model="prompt.override"
              label="Override"
              color="primary"
              hide-details
              class="pb-3 pl-3"
            ></v-switch>
        </v-card-title>
        <v-card-actions
            class="pa-0"        
        >
        </v-card-actions>
        <v-card-text
            class="pa-0"
        >
            <ivr-prompt
                :language="language"
                :prompt="prompt.prompt"
                :label="prompt.description"
                :disabled="!prompt.override"
                :default="prompt.defaultText"
                :restrictPrompt="false"
            />

        </v-card-text>
    </v-card>
</template>
<script>
import ivrPrompt from './ivrPrompt'

export default {
    components: {
        'ivr-prompt': ivrPrompt
    },
    props: {
        prompt: {
            type: Object,
            default: () => {}
        },
        language: {
            type: String,
            default: ''
        }
    },
    methods: {
    },
    computed: {
    },
    data: () => ({
    }),
    activated() {
    },
    mounted() {
    },
    watch: {
    }
}

</script>
