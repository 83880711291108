<template>

    <v-layout class="ycmd-black full-width full-height">
          <!-- style="background-color: ##0F7FA;" -->
          <v-navigation-drawer
            mobile-break-point="960"
            width="200"
            fixed
            hide-overlay
            light
            app
          >
            <v-list dense>
              <v-list-item link @click="changeMenuItem(0, 'ADMIN.verification', 'fade-in-transition')" style="" :class="`drawer-item ${ menuIndex === 0 ? 'active' : '' }`">
                <v-list-item-action>
                  <v-icon>mail</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Verifications</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item link @click="changeMenuItem(1, 'ADMIN.providers', 'fade-in-transition')" style="" :class="`drawer-item ${ menuIndex === 1 ? 'active' : '' }`">
                <v-list-item-action>
                  <v-icon>person</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Providers</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item link @click="changeMenuItem(2, 'ADMIN.notifications', 'fade-in-transition')" style="" :class="`drawer-item ${ menuIndex === 2 ? 'active' : '' }`">
                <v-list-item-action>
                  <v-icon>assessment</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Notifications</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item link @click="changeMenuItem(3, 'ADMIN.manageUsers', 'fade-in-transition')" :class="`drawer-item ${ menuIndex === 3 ? 'active' : '' }`">
                <v-list-item-action>
                  <v-icon>supervisor_account</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Manage Users</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>


          <!-- Header -->

          <v-app-bar app v-if="fullscreen === false" dark text class="ycmd-black">
            <!-- @click="changeMenuItem(-1, 'dashboard', 'fade-in-transition')" -->
            <v-toolbar-title class="mt-2">
              <img src="/images/ycmdlogo.png" style="height: 32px"/>
            </v-toolbar-title>

            <v-spacer/>

            <v-btn icon @click="dialog = true">
              <v-icon color="white">power_settings_new</v-icon>
            </v-btn>

            <v-btn icon @click="drawer = true">
              <v-icon color="white">settings</v-icon>
            </v-btn>

          </v-app-bar>

          <v-main class="" style="">

              <transition :name="transition">

                <router-view class="absolute full-width full-height"></router-view>

              </transition>

          </v-main>

          <v-navigation-drawer app v-model="drawer" clipped absolute temporary right style="">

            <v-layout column class="ycmd-black full-width full-height">

              <v-toolbar dark text class="transparent">

                <div class="fs-16 uppercase pl-2">Message Menu</div>

                <v-spacer />

                <v-btn icon @click="drawer = false">
                  <v-icon color="white">close</v-icon>
                </v-btn>

              </v-toolbar>

              <v-layout class="blue overflow-none pl-1 pr-1 pb-1" style="height: calc(100% - 56px) !important">

                <v-layout column align-center justify-center class="full-height white--text">

                  <div class="fs-32 bold">MESSAGE MENU</div>
                  <div>{{getInnerWidth}}</div>

                </v-layout>

              </v-layout>

            </v-layout>

          </v-navigation-drawer>

          <!-- Footer navigation -->

          <v-footer v-if="fullscreen === false && isSmallMobile" app dark height="75" class="blue" style="padding: 5px;">

            <v-layout justify-center align-content-space-between class="ycmd-black ma-1 br-2" style="height: calc(100% - 8px); padding-top: 5px;">

              <div class="pl-2 pr-2">
                <v-btn icon @click="changeMenuItem(0, 'ADMIN.verification', 'fade-in-transition')">
                  <v-icon :class="`menu-icon ${ menuIndex === 0 ? 'active' : '' }`">mail</v-icon>
                </v-btn>
              </div>
              <div class="pl-2 pr-2">
                <v-btn icon @click="changeMenuItem(1, 'ADMIN.providers', 'fade-in-transition')">
                  <v-icon :class="`menu-icon ${ menuIndex === 1 ? 'active' : '' }`">person</v-icon>
                </v-btn>
              </div>
              <div class="pl-2 pr-2">
                <v-btn icon @click="changeMenuItem(2, 'ADMIN.notifications', 'fade-in-transition')">
                  <v-icon :class="`menu-icon ${ menuIndex === 2 ? 'active' : '' }`">assessment</v-icon>
                </v-btn>
              </div>
              <div class="pl-2 pr-2">
                <v-btn icon @click="changeMenuItem(3, 'ADMIN.manageUsers', 'fade-in-transition')">
                  <v-icon :class="`menu-icon ${ menuIndex === 3 ? 'active' : '' }`">supervisor_account</v-icon>
                </v-btn>
              </div>

            </v-layout>

          </v-footer>

          <v-dialog v-model="dialog" persistent max-width="300">

            <v-card color="ycmd-black" dark style="padding-top: 15px;">
              <v-card-text class="fs-18">
                Do you want to logout?
              </v-card-text>
              <v-card-actions>
                <v-spacer/>
                <v-btn text color="white" @click="logout">Yes</v-btn>
                <v-btn text color="white" @click="dialog = false">No</v-btn>
              </v-card-actions>
            </v-card>

          </v-dialog>

    </v-layout>

</template>

<script>

import innerHeight from 'ios-inner-height';
import {mapState} from 'vuex'

export default {
  name: 'scheduling',
  data: () => ({
    drawer: false,
    menuIndex: -1,
    dialog: false,
    transition: '',
    height: 0,
    innerHeight: 0,
    fullscreen: false
  }),
  created() {
    this.setMenuIndex();
  },
  mounted() {

    this.height = innerHeight();
    this.innerHeight = window.innerHeight;

    // Add or remove the header based on which route meta matches
    this.fullscreen = this.$store.getters['layout/fullscreen'];

  },
  methods: {
    setMenuIndex() {
      switch (this.$route.name) {
        case 'ADMIN.verification':
          this.menuIndex = 0;
        break;
        case 'ADMIN.providers':
          this.menuIndex = 1;
        break;
        case 'ADMIN.notifications':
          this.menuIndex = 2;
        break;
        case 'ADMIN.manageUsers':
          this.menuIndex = 3;
        break;
      }
    },
    route(name, transition) {

      this.$router.push({
        name: name,
        params: {
          transition: transition
        }
      }).catch(err => { console.log('router error: ', err) });
    },
    changeMenuItem(index, name, transition) {
      // Check if we are already on it
      if (this.menuIndex === index) {
        return;
      }
      this.menuIndex = index;
      this.route(name, transition);
    },
    logout() {
      this.dialog = false;
      this.route('login', 'fade-in-transition')
    }
  },
  watch: {
    '$route'(to) {

      this.setMenuIndex();

      this.transition = (to.params.transition) ? to.params.transition : 'fade-in-transition';

      if (to.matched.some(record => record.meta.fullscreen)) {
        this.fullscreen = true;
      }
      else {
        this.fullscreen = false;
      }
    }
  },
  computed: {
    getInnerWidth: function () {
      return window.innerWidth;
    },
    isSmallMobile () {
      return this.$vuetify.breakpoint.smAndDown;
    }
  }
}

</script>

<style scoped>



</style>
