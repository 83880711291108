<template>
    <v-card
        dense
        class="pa-0"
    >
        <v-card-title
            class="pa-0"
        >
            <v-switch
              v-model="override"
              label="Override"
              color="primary"
              hide-details
              class="pb-3 pl-3"
            ></v-switch>
        </v-card-title>
        <v-card-actions
            class="pa-0"        
        >
        </v-card-actions>
        <v-card-text
            class="pa-0"
        >
            <ivr-prompt
                :language="language"
                :prompt="localPrompt"
                label="Greeting"
                :disabled="!override"
                default="defaultText"
                :restrictPrompt="false"
                @changed="changed"
            />

        </v-card-text>
    </v-card>
</template>
<script>
import ivrPrompt from './ivrPrompt'

export default {
    components: {
        'ivr-prompt': ivrPrompt
    },
    props: {
        greeting: {
            type: Array,
            default: () => []
        },
        mainGreeting: {
            type: Array,
            default: () => []
        },
        language: {
            type: String,
            default: ''
        }
    },
    methods: {
        changed (value) {
            this.$nextTick(()=> {
                this.$emit('greetingChanged', this.localPrompt)
            })
        },
        loadGreeting (greeting) {
            let index = 0
            if (greeting && greeting.length ) {
                greeting.map(dg => {
                    index = (dg.language === 'es-US') ? 1 : 0

                    this.$set(this.localPrompt[index], 'text', dg.text)                   
                })
                this.override = true
            }
        },
        loadDefaultGreeting () {
            let index=0
            if (this.mainGreeting && this.mainGreeting.length ) {
                this.mainGreeting.map(dg => {
                    index = (dg.language === 'es-US') ? 1 : 0

                    this.$set(this.localPrompt[index], 'text', dg.text)                    
                })
            }
        }

    },
    computed: {
    },
    data: () => ({
        override: false,
        localPrompt: [
            {
                language: 'en-US',
                text: ''
            },
            {
                language: 'es-US',
                text: ''
            }
        ]
    }),
    activated() {
    },
    mounted() {
        this.loadDefaultGreeting()
        this.loadGreeting(this.greeting)
    },
    watch: {
        defaultGreeting: {
            handler (newValue, oldValue) {
                this.loadDefaultGreeting()
            }, 
            deep: true
        },
        greeting: {
            handler (newValue, oldValue) {
                this.loadGreeting(newValue)
            }, 
            deep: true
        }
    }
}

</script>
