import jwt from 'jsonwebtoken';
import REFRESH_TOKEN from '../mutations/authenticateRefreshToken';
import SubscriptionService from '@/methods/subscriptionInstance'

async function refreshHandler(apollo) {

  // Check expiration of auth token
  const refreshToken = localStorage.getItem("refreshToken");
  const accessToken = localStorage.getItem("accessToken");
  // console.log('access token: ', accessToken);
  const username = localStorage.getItem("username");

  if (accessToken && refreshToken && username && accessToken !== 'null') {

      let decoded = jwt.decode(accessToken);

      const currentTime = Math.floor(new Date().getTime() / 1000);
      const timeLeft = decoded.exp - currentTime;

      //console.log('time left access: ', timeLeft);

      if (timeLeft < 30) {
          // Refresh the token

          // We are setting the accesstoken to null here because we don't want access token header to send for login

        // console.log('api key?: ', localStorage.apiKey);
          try {
              // apollo.client = 'publicClient';
              if (!apollo) return true;
              const response = await apollo.mutate({
                  mutation: REFRESH_TOKEN,
                  variables: {
                      email: username,
                      refreshToken: refreshToken
                  },
                  errorPolicy: 'all'
              });
              // apollo.client = 'privateClient';

              const { authenticateRefreshToken } = response.data;

              if (authenticateRefreshToken && authenticateRefreshToken.accessToken) {

                console.log('refreshed the token');

                localStorage.setItem("accessToken", authenticateRefreshToken.accessToken);
                localStorage.setItem("idToken", authenticateRefreshToken.idToken);

                let userId = localStorage.getItem("userID")
                if (userId) {
                    SubscriptionService.init({userID: userId, groupID: null});
                }

                return true;
              }
              else {
                console.log('REFRESH HANDLER: Unable to successfully get a new access token');
                return false;
              }
          }
          catch (e) {
            // apollo.client = 'privateClient';
            console.log('WARNING: There was an error refreshing token: ', e);
          }

          return false;

      }
      else {
        // token still has time
        return true;
      }
  }
  else if (refreshToken && username) {

    // if you have the bare minimum needed to get a new access token
    // We are setting the accesstoken to null here because we don't want access token header to send for login
    try {
        // apollo.client = 'publicClient';
        if (!apollo) return true;
        const response = await apollo.mutate({
            mutation: REFRESH_TOKEN,
            variables: {
                email: username,
                refreshToken: refreshToken
            },
            errorPolicy: 'all'
        });
        // apollo.client = 'privateClient';

        const { authenticateRefreshToken } = response.data;

        if (authenticateRefreshToken && authenticateRefreshToken.accessToken) {

          localStorage.setItem("accessToken", authenticateRefreshToken.accessToken);
          localStorage.setItem("idToken", authenticateRefreshToken.idToken);

          return true;
        }
        else {

          console.log('REFRESH HANDLER: Unable to successfully get a new access token');
          // apollo.client = 'privateClient';
          return false;
        }
    }
    catch (e) {
      // apollo.client = 'privateClient';
      console.log('WARNING: There was an error refreshing token: ', e);
    }
    // apollo.client = 'privateClient';
    return false;
  }
  else {
    // cant get new access token or refresh token doesn't exist
    // apollo.client = 'privateClient';
    return false;
  }
}

export { refreshHandler }
