import gql from 'graphql-tag'

export default gql`query billingGroup($group_id: ID) {
	billingGroup(group_id: $group_id)
    {
      name
      altName
      shortCode
      group_id
      group_id_postgres
      bill_on_day
      bill_on_additional_charges {
        description
        amount
        percent
        bill_on_behavior
      } 
      bill_on_additional_discounts {
        description
        amount
        percent
        bill_on_behavior
      } 
      billing_contact_name
      billing_contact_salutation
      billing_email_address
      address {
        id
        address
        addressCont
        city
        state
        zipCode
        legacy_id
      }
      billing_plan {
        name
        type
        included
        value
        description
        rate
      }
      extended_billing_plan {
        name
        type
        included
        value
        description
        rate
        tier_break
        tiers {
          limit
          value
          rate
          description
        }
      }
    }
}`
