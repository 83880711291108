<template>
  <v-dialog
      v-model="value"
      persistent
      :max-width="maxWidth"
      >
        <v-form
            ref="frmValidatePaymentMethod"
            @submit.prevent="doAddPaymentMethod"
        >

          <v-card>
              <v-card-title class="text-h5">
                Add Payment Method
              </v-card-title>

              <v-card-text>

               <v-container
                class="pa-0 ma-0"
                >
                  <v-row
                      class="pa-0 ma-0"
                  >
                    <v-col cols="6"
                        class="pa-0 ma-0 pr-1"
                    >
                      <v-text-field
                        light
                        solo
                        dense
                        flat
                        outlined
                        v-model="first_name"
                        label="First Name"
                        />
                    </v-col>
                    <v-col cols="6"
                        class="pa-0 ma-0 pl-1"
                    >
                      <v-text-field
                        light
                        solo
                        dense
                        flat
                        outlined
                        v-model="last_name"
                        label="Last Name"
                        />
                    </v-col>                    
                  </v-row>
                  <v-row
                      class="pa-0 ma-0"
                  >
                    <v-col cols="12"
                        class="pa-0 ma-0"
                    >
                      <v-text-field
                        light
                        solo
                        dense
                        flat
                        outlined
                        v-model="address"
                        label="Address"
                        />
                    </v-col>
                  </v-row>   
                  <v-row
                      class="pa-0 ma-0"
                  >
                    <v-col cols="4"
                        class="pa-0 ma-0 pr-1"
                    >
                      <v-text-field
                        light
                        solo
                        dense
                        flat
                        outlined
                        v-model="city"
                        label="City"
                        />
                    </v-col>
                    <v-col cols="4"
                        class="pa-0 ma-0 pl-1"
                    >
                      <state-picker
                          v-model="state"
                          :includeCustomIcon="false"
                          :outlined="true"
                          backgroundColor="ycmd-very-lightest-blue"
                          iconStyle="inner"
                          label="State"
                      />
                    </v-col>        
                    <v-col cols="4"
                        class="pa-0 ma-0 pl-1"
                    >
                      <v-text-field
                        light
                        solo
                        dense
                        flat
                        outlined
                        v-model="zipCode"
                        label="Zip"
                        />
                    </v-col>                                        
                  </v-row>         
                  <v-row
                      class="pa-0 ma-0"
                  >
                    <v-col cols="6"
                        class="pa-0 ma-0 pr-1"
                    >
                      <v-text-field
                        light
                        solo
                        dense
                        flat
                        outlined
                        v-model="phone"
                        label="Phone Number"
                        />
                    </v-col>
                    <v-col cols="6"
                        class="pa-0 ma-0 pl-1"
                    >
                      <v-text-field
                        light
                        solo
                        dense
                        flat
                        outlined
                        v-model="fax"
                        label="Fax Number"
                        />
                    </v-col>                    
                  </v-row>

                  <v-row
                      class="pa-0 ma-0"
                  >
                    <v-col cols="4"
                        class="pa-0 ma-0"
                    >
                      <v-select 
                        light
                        solo
                        dense
                        flat
                        outlined                      
                        :items="paymentTypes"
                        v-model="paymentType"
                        item-text="name"
                        item-value="value"
                      />         

                    </v-col>
                  </v-row>

                  <v-row
                      class="pa-0 ma-0"
                      v-if="paymentType=='creditCard'"    
                  >
                    <v-col cols="12"
                        class="pa-0 ma-0"
                    >
                      <v-text-field
                        light
                        solo
                        dense
                        flat
                        outlined
                        v-model="cardNumber"
                        type="number"
                        label="Card Number"
                        />
                    </v-col>
                  </v-row>   
                  <v-row
                      class="pa-0 ma-0"
                      v-if="paymentType=='creditCard'"                      
                  >
                    <v-col
                      cols="12"
                        class="pa-0 ma-0"
                    >
                      <label class="v-label v-label--active theme--light " style="font-size: 10px;font-weight: 400;">Expires:</label>
                    </v-col>
                  </v-row>

                  <v-row
                      class="pa-0 ma-0"
                      v-if="paymentType=='creditCard'"
                  >
                    <v-col
                        class="pa-0 ma-0 shrink"
                    >
                      <v-select 
                        light
                        solo
                        dense
                        flat
                        outlined                      
                        :items="months"
                        v-model="expMonth"
                        item-text="name"
                        item-value="value"
                        label="Month"
                        style="min-width: 100px;"
                        class="pr-1"
                      />      

                    </v-col>
                    <v-col
                        class="pa-0 ma-0 shrink"
                    >
                      <v-select 
                        light
                        solo
                        dense
                        flat
                        outlined                      
                        :items="years"
                        v-model="expYear"
                        label="Year"
                        style="min-width: 100px;"
                        class="pl-1"
                      />      

                    </v-col>                    
                  </v-row> 

                  <v-row
                      class="pa-0 ma-0"
                      v-if="paymentType=='bankAccount'"
                  >
                    <v-col cols="3"
                        class="pa-0 ma-0 pr-2"
                    >
                      <v-select 
                        light
                        solo
                        dense
                        flat
                        outlined                      
                        :items="accountTypes"
                        v-model="accountType"
                        item-text="name"
                        item-value="value"
                      />         

                    </v-col>
                    <v-col cols="5"
                        class="pa-0 ma-0 pr-1"
                    >
                      <v-text-field
                        light
                        solo
                        dense
                        flat
                        outlined
                        v-model="accountNumber"
                        label="Account Number"
                        />
                    </v-col>
                    <v-col cols="4"
                        class="pa-0 ma-0 pl-1"
                    >
                      <v-text-field
                        light
                        solo
                        dense
                        flat
                        outlined
                        v-model="routingNumber"
                        label="Routing Number"
                        />
                    </v-col>                    

                  </v-row>                    

               </v-container>

              </v-card-text>

            <v-card-subtitle
              v-if="error"
            >
              <div
                class="error white--text"
              >
                {{error}}
              </div>
            </v-card-subtitle>


              <v-card-actions>
                <v-spacer></v-spacer>
                  <v-btn        
                    color="primary"            
                    type="submit"
                    :loading="sending"
                  >                    
                  OK
                  </v-btn>
                  <v-btn
                      @click="cancel"
                      :disabled="sending"
                  >
                    Cancel
                  </v-btn>
              </v-card-actions>
          </v-card>
        </v-form>
  </v-dialog>

</template>

<script>

  import {mapActions} from "vuex";
  import paymentProfileSelect from "@/components/paymentProfileSelect";
  import StatePicker from '@/components/state-picker';
  export default {
    props: {
      value: {
        default: false,
        type: Boolean
      },
      'max-width': {
        default: '500',
        type: String
      },
      group_id: {
        type: String,
        default: null
      }
    },
    components: {
      'payment-profile-select': paymentProfileSelect,
      'state-picker': StatePicker
    },
    data: () => ({
      selectedPaymentProfile: '',
      error: '',
      rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 8 || 'Min 8 characters',
          phone: number => {
            const pattern = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/gm;
            return pattern.test(number) || 'Invalid phone number';
          }
      },
      paymentTypes: [{value: 'creditCard', name: 'Credit card'},
                      {value: 'bankAccount', name: 'Bank Account'}],
      accountTypes: [{value: 'checking', name: 'Checking'},
                      {value: 'savings', name: 'Savings'}],                      
      months: [
        {value: '01', name: 'January'},
        {value: '02', name: 'February'},
        {value: '03', name: 'March'},
        {value: '04', name: 'April'},
        {value: '05', name: 'May'},
        {value: '06', name: 'June'},
        {value: '07', name: 'July'},
        {value: '08', name: 'August'},
        {value: '09', name: 'September'},
        {value: '10', name: 'October'},
        {value: '11', name: 'November'},
        {value: '12', name: 'December'},
      ],                      
      first_name: '',
      last_name: '',
      address: '',
      city: '',
      zipCode: '',
      state: null,
      paymentType: 'creditCard',
      phone: '',
      fax: '',
      cardNumber: '',
      expMonth: '',
      expYear: '',
      accountType: 'checking',
      routingNumber: null,
      accountNumber: null,
      sending: false
    }),
    mounted() {
    },
    methods: {
      ...mapActions('billing', ['addPaymentProfile']),      
      cancel () {
        this.$emit('cancel')
      },
      async doAddPaymentMethod () {
        this.error = ''
        this.sending = true

        let payload = {
          group_id: this.group_id,
          billTo: JSON.stringify(this.billTo),
          first_name: this.first_name,
          last_name: this.last_name,
          paymentType: this.paymentType,
          phone: this.phone,
          fax: this.fax,
          //creditCard: JSON.stringify(this.creditCard)
        }

        if (this.paymentType === 'creditCard') {
          payload.creditCard = this.creditCard
        } else if (this.paymentType === 'bankAccount') {
          payload.bankAccount = this.bankAccount
        }

        let paymentMethod = await this.addPaymentProfile(payload)
        this.sending = false

        if (paymentMethod && paymentMethod.error) {
          this.error = paymentMethod.error.message ? paymentMethod.error.message : 'An error occurred'
        } else {
          this.$emit('added', paymentMethod)
        }
      }
    },
    computed: {
      years () {
        let year = (new Date()).getFullYear()
        let items = []
        for (let i=0;i<10;i++) {
          items.push(year + i)
        }

        return items
      },
      billTo () {
        let ret = null
        //if (true) { //(this.first_name || this.last_name)
          ret = {
            address: this.address,
            city: this.city,
            zipCode: this.zipCode,
            state: this.state,
          }
        //} 
        return ret
      },
      creditCard () {
        let ret = null
        if (this.paymentType === 'creditCard') {
          ret = {
            cardNumber: this.cardNumber,
            expirationDate: `${this.expYear}-${this.expMonth}`
          }
        }
        return ret
      },
      bankAccount () {
        let ret = null
        if (this.paymentType === 'bankAccount') {
          ret = {
            accountType: this.accountType,
            routingNumber: this.routingNumber,
            accountNumber: this.accountNumber,
          }
        }
        return ret
      }
    },
    watch: {
      value (newValue, oldValue) {
        this.selectedPaymentProfile = ''
      this.error = ''
      this.first_name = ''
      this.last_name = ''
      this.address = ''
      this.city = ''
      this.zipCode = ''
      this.state = null
      this.paymentType = 'creditCard'
      this.phone = ''
      this.fax = ''
      this.cardNumber = ''
      this.expMonth = ''
      this.expYear = ''
      this.accountType = 'checking'
      this.routingNumber = null
      this.accountNumber = null
      this.sending = false
      }
    }
  }
</script>

