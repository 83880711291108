// Initial state
const state = {
  pcpData: {

  },
  groups: [],
  globalEvent: null
};

const getters = {
  getPcpData: state => state.pcpData,
  getGroups: state => state.groups,
  globalEvent: (state) => {
    if (!state.globalEvent) {
        return {}
    }
    let pieces = state.globalEvent.split('|')

    let data = {}
    if (pieces[3]) {
      data = JSON.parse(pieces[3])
    }

    return {
        app: pieces[0],
        item: pieces[1],
        command: pieces[2],
        data: data,
        time: pieces[4]
    }
  },  
};

const actions = {
  triggerGlobalEvent: ({commit, getters, dispatch, rootGetters}, {app, item, command, data}) => {
    let time = (new Date()).getTime()

    let dataString = ''
    if (data) {
      dataString = JSON.stringify(data)        
    }

    commit('setGlobalEvent', `${app}|${item}|${command}|${dataString}|${time}`)
  },
};

const mutations = {

  setPcpData (state, data) {
    state.pcpData = data;
  },  

  setGroups (state, data) {
    state.groups = data;
  },

  setGlobalEvent(state, data) {
    state.globalEvent = data;
  }

  /*
  async addSchedule (state, data) {
    if (state.reportDefinitions && state.reportDefinitions.length) {
      let def = state.reportDefinitions.find(d => {
        return d.id === data.report_id
      })

      if (def) {
        def.schedules.push(data.schedule)
      }
    }
  },
  async removeSchedule (state, data) {
    if (state.reportDefinitions && state.reportDefinitions.length) {
      let def = state.reportDefinitions.find(d => {
        return d.id === data.report_id
      })
      
      if (def) {
        let index = def.schedules.findIndex((s => {
          return s.schedule_id === data.schedule_id
        }))

        if (index >= 0) {
          def.schedules.splice(index, 1)
        }
      }
    }
  },
  */
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
