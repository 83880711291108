import gql from 'graphql-tag'

export default gql`mutation ycmdChatPostCreate(
	$room_id: ID!, 
	$text: String, 
	$attachment_id: ID, 
	$message_id: ID,
	$key: String
	$reply_to_id: ID) {
	ycmdChatPostCreate(
		room_id: $room_id, 
		text: $text, 
		attachment_id: $attachment_id,
		message_id: $message_id,
		key: $key
		reply_to_id: $reply_to_id) {
		text
		date_created
		id
		first_name
		last_name
		user_id
		type
		system_type
		attachment_id
		key
	}
}`
