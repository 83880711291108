<template>

    <div class="view ycmd-blue-gradient">

        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="false"
                 :background-color="`#101622`"
                 :color="`white`"
                 :loader="`bars`">
        </loading>

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <div class="i-card__main pa-2">

                <div class="i-card__main__content white br-2 pa-2">

                    <div class="i-card__scrollable ycmd-light-gray full-width full-height">

                        <v-container
                            fluid
                            class="ma-2"
                        >

                            <v-row
                                class="pl-0 pr-0 pb-0 pt-0 align-center"
                            >
                                <v-col
                                    cols="12"
                                    lg="3"
                                    md="4"
                                    class="pa-0 ma-0"
                                >   
                                    <v-autocomplete
                                        light dense flat outlined
                                        v-model="selectedGroup"
                                        :disabled="!allGroups.length"
                                        :items="allGroups"
                                        :loading="isLoadingGroupsSearch"
                                        label="Select Group"
                                        item-value="id"
                                        item-text="name"
                                        background-color="white"
                                        clearable
                                        return-object
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>

                            <v-row
                                class="pl-0 pr-0 pb-0 pt-0 align-center"
                            >
                                <v-col
                                    cols="12"
                                    class="pa-0 ma-0"
                                >   
                                    <invoices
                                        :group_id="group_id"
                                    />
                                </v-col>
                            </v-row>


                        </v-container>

                    </div>
                </div>
            </div>

        </i-card>

    </div>

</template>

<script>

    import Loading from 'vue-loading-overlay';

    import {defaultErrorHandler} from '../../../../graphql/handler/errorHandler';

    import SEARCH_GROUPS from '../../../../graphql/queries/phxGroups';
    import DatePicker from "@/components/date-picker";
    import Invoices from "@/components/invoices";
    import {mapGetters, mapActions} from "vuex";
    export default {
        name: 'updateGroup',
        components: {
            Loading,
            'date-picker': DatePicker,
            invoices: Invoices
        },
        props: {
            params: {
                type: Object
            }
        },
        data() {
            return {
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                isLoading: false,
                errorMsg: '',
                successMsg: '',
                allGroups: [],
                selectedGroup: null,
                isLoadingGroupsSearch: false
            }
        },
        mounted() {
            this.getGroups(null);
            this.selectedGroup = this.billingGroupGetter
            
            /*
            let group = this.allGroups.find(g => {
                return g.id == this.billingGroupGetter
            })

            this.selectedGroup = group
            */
        },
        computed: {
            ...mapGetters('profile', ['billingGroupGetter']),
            group_id () {
                if (this.selectedGroup && this.selectedGroup.id) {
                    return this.selectedGroup.id
                }
                return null
            }
        },
        methods: {
            ...mapActions('billing', ['getAllGroups']),     

            async getGroups(val, persistMessages) {

                this.isLoadingGroupsSearch = true;
                try {
                        this.allGroups = await this.getAllGroups()

                    if (!persistMessages) {
                        this.successMsg = ''
                        this.errorMsg = ''
                    }

                    this.isLoadingGroupsSearch = false;

                } catch (e) {
                    this.isLoadingGroupsSearch = false;
                    defaultErrorHandler(e);
                }
            },            
        },
        watch: {
            selectedGroup(val) {
                this.$store.commit('profile/setBillingGroup', val)
            }

        }
    }

</script>

<style scoped>

</style>
