import Vue from 'vue'
import dataClient from '@/graphql/clients/axios';

const state = {
    reportDefinitions: null,
    jobs: [],
    referentialCache: {}
};

const getters = {
  getReportDefinitions: state => state.reportDefinitions,
  getJobs: state => state.jobs,
  getJobIndex: (state) => (id) => {

    if (state.jobs && state.jobs.length) {
        let index = state.jobs.findIndex(r => {
            return r.job_id === id
        })
        return index

    }
    return null
  },
  getReferentialByKey: (state) => (key) => {
    return state.referentialCache[key]
  },
};

const actions = {
  async findSpecificDefinition ({ commit, getters, dispatch }, payload) {
    //sometimes the definition can change with the variables
    let def = await dataClient('ycmdReportDefinitionGet', payload)

    return def
  },
  async getDefinitions ({ commit, getters }) {
    if (!getters.getReportDefinitions) {
      let defs = await dataClient('ycmdReportDefinitionGet', {report_id: null, parameters:null})
      commit('setReportDefinitions', defs)
      return defs
    }
    return getters.getReportDefinitions
  },

  async findReportDefinition ({ commit, getters, dispatch }, payload) {
    let defs = await dispatch('getDefinitions')

    let def
    if (defs && defs.length) {
      def = defs.find(d => {
        return d.id === payload.report_id
      })
    }
    return def
  },
  async submitReportToBackEnd ({ commit, getters, dispatch }, payload) {
    let job = await dataClient('ycmdReportRun', {
        report_id: payload.report_id,
        parameters: payload.parameters,
        format: payload.reportFormat
    })
    console.log('job', job)          
    
    commit('setJobPropertyByIndex',{
      index: payload.jobIndex,
      property: 'job_id',
      value: job.job_id
    })
    commit('setJobPropertyByIndex',{
      index: payload.jobIndex,
      property: 'error',
      value: null
    })

  },
  async submitReport ({ commit, getters, dispatch }, payload) {
    let definition = await dispatch('findReportDefinition', payload)

    let jobIndex = getters.getJobs.length

    let tempJob = {
                    type: "asynchronous_report",
                    user_id: localStorage.getItem("userID"),
                    report: {
                      report_name: definition.name,
                      report_id: definition.id,
                      properties: payload.parameters,
                      definition: definition,
                      output_type: payload.format
                    },
                    key: payload.key,
                    progress: null
                  }
 
    commit('addJob',tempJob)  
    
    commit('setJobPropertyByIndex',{
      index: jobIndex,
      property: 'job_id',
      value: null
    })
    commit('setJobPropertyByIndex',{
      index: jobIndex,
      property: 'running',
      value: true
    })
    commit('setJobPropertyByIndex',{
      index: jobIndex,
      property: 'activity_date',
      value: null
    })
    commit('setJobPropertyByIndex',{
      index: jobIndex,
      property: 'activity_date_end',
      value: null
    })    
    commit('setJobPropertyByIndex',{
      index: jobIndex,
      property: 'columns',
      value: null
    })    
    commit('setJobPropertyByIndex',{
      index: jobIndex,
      property: 'lines',
      value: null
    })    
    commit('setJobPropertyByIndex',{
      index: jobIndex,
      property: 'url',
      value: null
    })    
    commit('setJobPropertyByIndex',{
      index: jobIndex,
      property: 'running',
      value: true
    })
    commit('setJobPropertyByIndex',{
      index: jobIndex,
      property: 'output',
      value: 'none'
    })
    commit('setJobPropertyByIndex',{
      index: jobIndex,
      property: 'output_type',
      value: payload.format
    })

    let progress = {
      type: definition.progress.type,
      max: 10,
      min: 0,
      current: 0,
      description: 'Starting Report'
    }
    //to make sure the props are response, use vue.set 
    commit('setJobPropertyByIndex',{
      index: jobIndex,
      property: 'progress',
      value: progress
    })
    
    payload.jobIndex = jobIndex
    payload.report_id = definition.id

    //dispatch('data/triggerGlobalEvent', {app: 'Report', item: job.job_id, command: 'started', data: progress  }, {root: true})
    dispatch('submitReportToBackEnd', payload)

    return tempJob  
  },
  updateJob ({ commit, getters, dispatch }, activity) {

    let jobIndex = getters.getJobIndex(activity.job_id)

    if (jobIndex >= 0) {
      let jobBefore = getters.getJobs[jobIndex]

      //let newReport = jobBefore.report
      //newReport.properties 

      if (activity && activity.report && activity.report.properties) {
        //these are formatted by the reporting engine
        commit('setJobReportPropertiesByIndex',{
          index: jobIndex,
          property: 'activity_date',
          value: activity.report.properties
        })  
      }


      commit('setJobPropertyByIndex',{
        index: jobIndex,
        property: 'activity_date',
        value: activity.activity_date
      })
      commit('setJobPropertyByIndex',{
        index: jobIndex,
        property: 'activity_date_end',
        value: activity.activity_date_end
      }) 

      if (activity.report) {
        commit('setJobPropertyByIndex',{
          index: jobIndex,
          property: 'columns',
          value: activity.report.columns
        })    
        commit('setJobPropertyByIndex',{
          index: jobIndex,
          property: 'lines',
          value: activity.report.lines
        })    
        commit('setJobPropertyByIndex',{
          index: jobIndex,
          property: 'url',
          value: activity.report.url
        })   


        if (activity.report.lines) {
          if(activity.report.url) {
            commit('setJobPropertyByIndex',{
              index: jobIndex,
              property: 'output',
              value: 'both'
            })
          } else {
            commit('setJobPropertyByIndex',{
              index: jobIndex,
              property: 'output',
              value: 'json'
            })
          }
        } else {
          if(activity.report.url) {
            commit('setJobPropertyByIndex',{
              index: jobIndex,
              property: 'output',
              value: 'url'
            })
          } else {
            commit('setJobPropertyByIndex',{
              index: jobIndex,
              property: 'output',
              value: 'none'
            })
          }
        }


      }

      let progress = {
        type: jobBefore.progress.type,
        max: jobBefore.progress.max,
        min: jobBefore.progress.min,
        current: jobBefore.progress.max,
        description: 'Report Finished'
      }
      //to make sure the props are response, use vue.set 
      commit('setJobPropertyByIndex',{
        index: jobIndex,
        property: 'progress',
        value: progress
      })
  
      commit('setJobPropertyByIndex',{
        index: jobIndex,
        property: 'running',
        value: false
      })

      if (activity.error_message) {
        commit('setJobPropertyByIndex',{
          index: jobIndex,
          property: 'error',
          value: activity.error_message
        })
      }
      
      dispatch('data/triggerGlobalEvent', {app: 'Report', item: activity.job_id, command: 'finished', data: {}}, {root: true})
    }
  },
  updateJobProgress ({ commit, getters, dispatch }, activity) {

    let jobIndex = getters.getJobIndex(activity.job_id)

    if (jobIndex >= 0) {

      let progress = {
        type: activity.progress.type,
        max: activity.progress.max,
        min: activity.progress.min,
        current: activity.progress.current,
        description: activity.progress.description
      }
      //to make sure the props are response, use vue.set 
      commit('setJobPropertyByIndex',{
        index: jobIndex,
        property: 'progress',
        value: progress
      })
        
      //dispatch('data/triggerGlobalEvent', {app: 'Report', item: activity.job_id, command: 'progress', data: progress}, {root: true})
    }
  },  
  async loadReferential ({ commit, getters, dispatch }, payload) {

    let query = payload.query
    let variables = payload.variables

    let cacheKey = `${query}_${JSON.stringify(variables)}`  //this may vary due to property order but worst case is we cache same data twice

    let referential = getters.getReferentialByKey(cacheKey)

    if (referential) {
      console.log('data from cache', payload)
      return referential
    }

    let data = await dataClient(query, variables)
    console.log('data', data)


    switch (query) {
      case 'phxGroups':
        if (data) {
          let sorted = data.sort(function(a, b) {
            return a.name.trim().localeCompare(b.name.trim());
          });

          data = sorted
        }
    }

    commit('setReferentialByKey', {key: cacheKey, value: data})

    return data
  },
  async addSchedule (state, data) {
    if (state.reportDefinitions && state.reportDefinitions.length) {
      let def = state.reportDefinitions.find(d => {
        return d.id === data.report_id
      })

      if (def) {
        def.schedules.push(data.schedule)
      }
    }
  },
  async removeSchedule (state, data) {
    if (state.reportDefinitions && state.reportDefinitions.length) {
      let def = state.reportDefinitions.find(d => {
        return d.id === data.report_id
      })
      
      if (def) {
        let index = def.schedules.findIndex((s => {
          return s.schedule_id === data.schedule_id
        }))

        if (index >= 0) {
          def.schedules.splice(index, 1)
        }
      }
    }
  },  
};

const mutations = {
  setReportDefinitions (state, data) {
    state.reportDefinitions = data;
  },
  removeSchedule (state, data) {
    if (state.reportDefinitions && state.reportDefinitions.length) {
      let def = state.reportDefinitions.find(d => {
        return d.id === data.report_id
      })
      
      if (def) {
        let index = def.schedules.findIndex((s => {
          return s.schedule_id === data.schedule_id
        }))

        if (index >= 0) {
          def.schedules.splice(index, 1)
        }
      }
    }
  },
  addSchedule (state, data) {
    if (state.reportDefinitions && state.reportDefinitions.length) {
      let def = state.reportDefinitions.find(d => {
        return d.id === data.report_id
      })
      
      if (def) {
        def.schedules.push(data.schedule)
      }
    }
  },
  addJob (state, job) {      
    state.jobs.push(job)
  },
  setJobPropertyByIndex(state, payload) {
    Vue.set(state.jobs[payload.index], payload.property, payload.value);
  },
  setReferentialByKey: (state, payload) => {
    state.referentialCache[payload.key] = payload.value
  },
  setJobReportPropertiesByIndex(state, payload) {

    let job = state.jobs[payload.index]
    let newPropertyMap = payload.value
    let map = {}
    newPropertyMap.map(p=> {
      map[p.id] = p
    })
    for (let i=0;i<job.report.properties.length;i++) {
      let p = job.report.properties[i]
      let newP = map[p.id]

      Vue.set(state.jobs[payload.index].report.properties,i,newP)      
    }

  },  
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
