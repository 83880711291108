<template>
    <v-card
        dense
        class="pa-0"
    >
        <v-card-title
            class="pa-0"
        >
            {{metadataItem.label}}
            <v-switch
              v-model="metadataItem.collect"
              label=""
              color="primary"
              hide-details
              class="pb-3 pl-3"
            ></v-switch>
        </v-card-title>
        <v-card-actions
            class="pa-0"        
        >
        </v-card-actions>
        <v-card-text
            class="pa-0"
        >
            <ivr-prompt
                :language="language"
                :prompt="metadataItem.prompt"
                :label="metadataItem.label"
                :disabled="!metadataItem.collect"
                :default="metadataItem.defaultText"
                :restrictPrompt="true"
            />

        </v-card-text>
    </v-card>
</template>
<script>
import ivrPrompt from './ivrPrompt'

export default {
    components: {
        'ivr-prompt': ivrPrompt
    },
    props: {
        metadataItem: {
            type: Object,
            default: () => {}
        },
        language: {
            type: String,
            default: ''
        }
    },
    methods: {
    },
    computed: {
    },
    data: () => ({
    }),
    activated() {
    },
    mounted() {
    },
    watch: {
    }
}

</script>
