<template>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            :label="label"
            prepend-icon="event"
            @click:prepend="menu=!menu"
            readonly
            v-bind="attrs"
            v-on="on"
            background-color="white"

          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          no-title
          scrollable
          @change="datePicked"
        >
 
        </v-date-picker>
      </v-menu>

</template>

<script>
export default {
    name: 'datePicker',
    components: {
    },
    props: {
      label: {
          type: String,
          default: 'Date'
      },
      value: {
        type: String,
        default: null
      }         
    },
    data: () => ({
        menu: false,
        date: null
    }),
    mounted() {
      this.date = this.value
    },
    methods: {
      datePicked(props) {
        this.menu = false
      }
    },
    computed: {
    },
    watch: {
        date (newValue, oldValue) {
          this.$emit('input', newValue)
          this.$emit('changed', newValue)
        }
    }
}
</script>
