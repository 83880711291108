import './assets/transitions/transitions.styl'
import './assets/css/vuetify-extensions.css'
import './plugins/i-card/i-card.css'
import './assets/css/ellipsis-spinner.css'
import './assets/css/helpers.css'
import './assets/css/app.css'

import Vue from 'vue'
import router from './router'
import store from './store'
import App from './app.vue'
import { fadeInTransition } from "./assets/transitions/transitions";
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from "apollo-link-context";
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import VueApollo from 'vue-apollo';
import jwt from 'jsonwebtoken';
import vuetify from './plugins/vuetify';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import Toasted from 'vue-toasted';
import iCard from './plugins/i-card/i-card';
import './registerServiceWorker'

import { refreshHandler } from './graphql/handler/refreshHandler';

import SubscriptionService from '@/methods/subscriptionInstance'

Vue.use(VueApollo);
Vue.use(iCard);
Vue.use(Toasted, {
    position: 'top-center',
    duration: 5000,
    fullWidth: true,
    fitToScreen: true
});

// Register global components
Vue.component('fade-in-transition', fadeInTransition);

localStorage.setItem("apiKey", process.env.VUE_APP_APPSYNC_API_KEY);

const httpLink = createHttpLink({ uri: process.env.VUE_APP_APPSYNC_URL });

let privateClient = null;
let publicClient = null;
let phoenixClient = null;

const publicLink = ApolloLink.from([
    createAuthLink({
        url: process.env.VUE_APP_APPSYNC_URL,
        region: process.env.VUE_APP_APPSYNC_REGION,
        auth: {
            type: 'API_KEY',
            apiKey: localStorage.apiKey
        },
        disableOffline: true
    }),
    httpLink
]);

const privateLink = ApolloLink.from([
    createAuthLink({
        url: process.env.VUE_APP_APPSYNC_URL,
        region: process.env.VUE_APP_APPSYNC_REGION,
        auth: {
            type: 'AMAZON_COGNITO_USER_POOLS',
            jwtToken: async () => {
              const refreshHandlerResponse = await refreshHandler(publicClient);
              if (!refreshHandlerResponse) return;
              return localStorage.accessToken
            }
        },
        disableOffline: true
    }),
    createSubscriptionHandshakeLink({
        url: process.env.VUE_APP_APPSYNC_URL,
        region: process.env.VUE_APP_APPSYNC_REGION,
        auth: {
            type: 'AMAZON_COGNITO_USER_POOLS',
            jwtToken: async () => localStorage.accessToken
        },
        disableOffline: true
    })
]);

const phoenixLink = ApolloLink.from([
    setContext((_request, previousContext) => ({
      headers: {
        ...previousContext.headers,
        Authorization: localStorage.phoenixToken
      }
    })),
    createAuthLink({
        url: process.env.VUE_APP_APPSYNC_URL,
        region: process.env.VUE_APP_APPSYNC_REGION,
        auth: {
            type: 'API_KEY',
            apiKey: localStorage.apiKey
        },
        disableOffline: true
    }),
    httpLink
]);

publicClient = new ApolloClient({
    link: publicLink,
    cache: new InMemoryCache()
});

privateClient = new ApolloClient({
    link: privateLink,
    cache: new InMemoryCache()
});

phoenixClient = new ApolloClient({
    link: phoenixLink,
    cache: new InMemoryCache()
});

let userId = localStorage.getItem("userID")
if (userId) {
    SubscriptionService.init({userID: userId, groupID: null});
}

router.beforeEach((to, from, next) => {

    // Check expiration of auth token
    // let decoded = null;
    //
    // const accessToken = localStorage.accessToken;
    // const refreshToken = localStorage.refreshToken;
    // const username = localStorage.username;
    //
    // if (accessToken && accessToken !== 'null') {
    //
    //     decoded = jwt.decode(accessToken);
    //     const currentTime = Math.floor(new Date().getTime() / 1000);
    //     const timeLeft = decoded.exp - currentTime;
    //
    //     if (timeLeft < 1800 && timeLeft > 0) {
    //         // Refresh the token
    //     }
    //
    //     else if (timeLeft <= 0) {
    //         // Your access token has expired, check the refresh token and then use it
    //     }
    // } else if (!refreshToken || !username) {
    //     // lost access token somehow, need to log in again (COMMENTED OUT BECAUSE YOU CANNOT NAVIGATE PROPERLY WITH THIS ON)
    //     // if (to.name !== 'login' && from.name !== 'login') {
    //     //   route('login', 'next');
    //     //   return;
    //     // }
    // }
    next();
});

Vue.config.productionTip = false;

new Vue({
    el: '#app',
    router,
    store,
    vuetify,
    apolloProvider: new VueApollo({
        clients: {
            publicClient,
            privateClient,
            phoenixClient
        },
        defaultClient: privateClient
    }),
    render: h => h(App)
});
