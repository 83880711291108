import gql from 'graphql-tag'

export default gql`mutation billingOperation($operation: String!, $parameters: [NameIdValueInput]) {
	billingOperation(operation: $operation, parameters: $parameters )
    {
		operation
		refId
    	customerProfiles {
			profileType
			customerProfileId
			merchantCustomerId
			groupId
			description
			email
    		shipTo {
				id
				address
				addressCont
				city
				state
				zipCode
				legacy_id
				first_name
				last_name 
				company
				phone_number
				fax_number 
			}
		}
    	paymentProfiles {
			paymentProfileId
			customerProfileId
			type
			billTo {
				id
				address
				addressCont
				city
				state
				zipCode
				legacy_id
				first_name
				last_name 
				company
				phone_number
				fax_number 
			}
			parameters {
				name
				id
				value
			}
		}
		transactions {
			paymentProfileId
			customerProfileId
			responseCode
			authCode
			transId
			parameters {
				name
				id
				value
			}
		}
		payments {
			id
			date_added
			date_updated
			date_deleted
			deleted
			added_user_id
			deleted_user_id 
			updated_user_id
			amount_paid
			auth_code
			couchbase_id     
			group_id
			legacy_id
			payment_method
			success
			group_sql_id
		}
		invoices {
			number
			amount
			id
			date_service_start
			date_service_end
			date_due
			date_added
			status
			past_due
			remaining
			usage
			amount_due
		}
    }
}`
