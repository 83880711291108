<template>

    <div class="d-flex fill-height ycmd-dark-blue full-width pa-2 br-2">

        <div class="d-flex fill-height full-width flex-column">

            <div class="d-flex align-center white--text pa-2 pb-3">

                <div class="fs-16 fw-500">FAQ</div>
                <v-spacer />
                <v-btn dark icon text @click="route('login', 'down')">
                    <v-icon>close</v-icon>
                </v-btn>
            </div>

            <div class="d-flex  flex-column flex-grow-1 full-width relative">

                <div class="absolute full-width full-height">
                    <div class="d-flex flex-column fill-height flex-grow-1 ycmd-medium-blue white--text full-width pa-3 pb-4 br-2" style="overflow: auto">

                        <div class="d-flex flex-column pt-3 pb-3">
                            <div class="fs-16">Why can't I login immediately?</div>
                            <div class="pt-3 fs-13 fade-2">Gaining access to the PCPLink portal is a multi step process.
                                After submitting your information, it must be reviewed by your Group Administrator. If you are accepted,
                                you will receive an email with a link to complete the registration process and set a password.
                                You will now be able to access the portal/mobile app depending on you role.
                            </div>
                        </div>

                        <div class="d-flex flex-column pt-4 pb-3">
                            <div class="fs-16">How long will it take to complete registration?</div>
                            <div class="pt-3 fs-13 fade-2">Typically this takes 2 business days, however since verification
                                is a manual process performed by your Group Administator this time can vary. If it takes much longer
                                than this we would encourage you to reach out to your Group Administrator.
                            </div>
                        </div>

                        <div class="d-flex flex-column pt-4 pb-3">
                            <div class="fs-16">I never got an email to complete registration?</div>
                            <div class="pt-3 fs-13 fade-2">This may have happened for a number of reasons:
                                <ul class="pt-2 pb-2">
                                    <li>You have not yet been verified. Please note this typically takes 2 business days.</li>
                                    <li>Check your spam email. Depending on your email provider, sometimes
                                        automated messages can get lost there.
                                    </li>
                                    <li>For security reasons, if you have taken too long to complete the registration
                                        process then the token sent to you by email may have expired.
                                    </li>
                                </ul>
                                If you are still having issues or need further assistance, please contact your Group
                                Administrator and they can resend the email with the link.
                            </div>
                        </div>

                        <div class="d-flex flex-column pt-4 pb-3">
                            <div class="fs-16">Why can't I join the PCPLink program?</div>
                            <div class="pt-3 fs-13 fade-2">We are sorry but this is not yet available to all PCPs in all areas/groups.
                                If you would like to inquire on how you could join please contact us.
                            </div>
                        </div>

                        <div class="d-flex flex-column pt-4 pb-3">
                            <div class="fs-16">Who verifies my registration information?</div>
                            <div class="pt-3 fs-13 fade-2">All your information is sent directly to your Group Administrator. YouCallMD
                                cannot verify your identity nor accept/deny your registration request since this is at the descretion of your
                                Group Administrator.
                            </div>
                        </div>

                        <div class="d-flex flex-column pt-4 pb-3">
                            <div class="fs-16">Why do I have to provide my License number?</div>
                            <div class="pt-3 fs-13 fade-2">We use this data along with other key information so that your Group
                                Analyst is able to verify your identity. At the same time, they may also add you to their
                                EMR or update their existing records with the information you have provided.
                            </div>
                        </div>

                        <div class="d-flex flex-column pt-4 pb-3">
                            <div class="fs-16">I have registered but can't access the portal?</div>
                            <div class="pt-3 fs-13 fade-2">Only Administrators currently have access to the portal. If this is your role and you
                                still do not have access please contact us.
                            </div>
                        </div>

                        <div class="d-flex flex-column pt-4 pb-3">
                            <div class="fs-16">I have registered but can't access the mobile app?</div>
                            <div class="pt-3 fs-13 fade-2">Only Primary Care Physicians have access to the mobile app. If you are a PCP and you
                                still do not have access please contact us.
                            </div>
                        </div>

                        <div class="d-flex flex-column pt-4 pb-3">
                            <div class="fs-16">What if I already use the YouCallMD mobile app?</div>
                            <div class="pt-3 fs-13 fade-2">Perfect! The PCPLink feature is designed to work in harmony
                                with the existing mobile app. You will not have to download anything additional. Although both programs
                                are merged into the same UI, we still treat each as separate accounts so you will never have to worry about
                                mixing data/messages.
                            </div>
                        </div>

                        <div class="d-flex flex-column pt-4 pb-3">
                            <div class="fs-16">What if I am a YouCallMD user in multiple groups?</div>
                            <div class="pt-3 fs-13 fade-2">We have you covered! If you are apart of multiple groups then you are probably already
                                setup as a 'Linked' account in YouCallMD. By joining the PCPLink program, all you accounts will continue to work
                                the same way. The only difference, is that you will now have a section dedicated to PCPLink messages.
                            </div>
                        </div>

                        <div class="d-flex flex-column pt-4 pb-3">
                            <div class="fs-16">Having other issues?</div>
                            <div class="pt-3 fs-13 fade-2">Please contact us and we will get back to you as soon as possible.
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <div class="d-flex flex-column align-center">
                <v-btn dark block depressed color="ycmd-green" class="mt-1" @click="route('authContactUs', 'up')">Contact Group</v-btn>
            </div>
        </div>
    </div>

</template>

<script>

    export default {
        name: 'authFAQ',
        data: () => ({
            phone: '',
            challengeName: '',
            errorMessage: '',
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                emailMatch: () => ('The email and password you entered don\'t match')
            }
        }),
        apollo: {
            $client: 'publicClient'
        },
        methods: {
            route(name, direction) {

                this.$router.push({
                    name: name, params: {
                        transition: direction
                    }
                }).catch(err => { console.log('router error: ', err) });
            }
        }
    }

</script>

<style scoped>



</style>
