import gql from 'graphql-tag'

export default gql`mutation
ycmdVoicemailSettingsUpdate(
	$group_id: ID!,
    $operation: VoicemailOperation,
    $description: String,
	$backup_id: ID,
	$aws_connect_phone: String,
	$nextToken: String,
	$voicemailSettings: VoicemailSettingsInput)
{
	ycmdVoicemailSettingsUpdate(
		group_id: $group_id,
    	operation: $operation,
    	description: $description,
		backup_id: $backup_id,
		aws_connect_phone: $aws_connect_phone,
		nextToken: $nextToken,
		voicemailSettings: $voicemailSettings
		){
		status
		data
	}
}`

