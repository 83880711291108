import gql from 'graphql-tag'

export default gql`mutation authenticateBegin($email: String!, $password: String!) {
	authenticateBegin(email: $email, password: $password) {
    ChallengeName
    Session
			AuthenticateResult {
				ycmdAuthenticate {
					accessToken
					accepted_terms_of_service_pcp
					is_device_registered_for_pp
					tokenType
					refreshToken
					idToken
					roles {
					role
					role_label
					group{
					 id
					 name
					}
					facilities{
					 id
					 name
					}
				}
			}
		}
  }
}`

// phoenixAuthenticate{
//  accepted_terms_of_service
//  is_device_registered
//  token
//  user_id
//  link_id
//  linked_users
//  provider_id
//  member_id
//  nurse_id
//  pcp_user_id
//  pcp_analyst_id
//  roles
// }
