<template>

    <div class="ycmd-blue-gradient full-width full-height">

        <div class="absolute full-width full-height">
            <img src="/images/overlay-shape.png" class="full-width" style="margin-top: 5%" />
        </div>

        <div class="d-flex flex-column align-center full-width full-height pa-4">

            <div class="d-flex flex-column align-center justify-center relative logo-container">
                <img src="/images/ycmdlogo_transparent.png" class="logo-image" />
                <div class="fs-12 fw-300 white--text" style="padding-left: 90px">Clinical Communications, Simplified.</div>
            </div>

            <div class="d-flex fill-height auth-container overflow-hidden relative">

                <transition :name="transition">

                    <router-view class="absolute full-width full-height" />

                </transition>
            </div>
        </div>
    </div>

</template>

<script>

  import { clearLocalStorage } from '../../methods/globalMethods';

    export default {
        name: 'authenticate',
        data: () => ({
            transition: ''
        }),
        mounted() {
          // if (this.checkHasTokens()) {
          //   this.route('ADMIN.providers', 'fade-in-transition');
          // } else {
          //   clearLocalStorage();
          // }
        },
        methods: {
            route(name, direction) {

                this.$router.push({
                    name: name, params: {
                        transition: direction
                    }
                }).catch(err => { console.log('router error: ', err) });
            }
        },
        watch: {
            '$route'(to) {

                this.transition = (to.params.transition) ? to.params.transition : 'fade-in-transition';
            }
        },
    }

</script>

<style scoped>

    .auth-container {
        width: 100%;
        min-width: 340px;
        max-width: 414px;
    }

    .logo-image {
      height:72px
    }

    .logo-container {
      height: 30%;
      min-height: 140px;
    }

    @media only screen and (max-height: 700px) {
      .logo-image {
        height: 52px;
      }
      .logo-container {
        margin-bottom: 16px;
        height: 15%;
        min-height: 100px;
      }
    }

</style>
