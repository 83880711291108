<template>
  <div>
      <v-data-table
          :headers="headers"
          :items="items"
          item-class="itemClass"
          dense
          :hide-default-footer="true"
          :disable-pagination="true"
          no-data-text="No invoices"
          :show-expand="false"
          :loading="loading"
          show-select
          selectable-key="isSelectable"
          v-model="selectedInvoices"
          item-key="number"
      >
        <template v-slot:item.actions="{ item }">

          <v-tooltip bottom
                      nudge-bottom="10"
                      :disabled="$vuetify.breakpoint.xsOnly"
                      color="ycmd-black"
                      content-class="fade-0 pa-4 pt-2 pb-2">
              <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="download(item, 'invoice')">
                      <v-icon :size="22" color="blue">article</v-icon>
                  </v-btn>
              </template>
              <span>Download Invoice</span>
          </v-tooltip>
          <v-tooltip bottom
                      nudge-bottom="10"
                      :disabled="$vuetify.breakpoint.xsOnly"
                      color="ycmd-black"
                      content-class="fade-0 pa-4 pt-2 pb-2">
              <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="download(item, 'statistics')">
                      <v-icon :size="22" color="blue">grid_on</v-icon>
                  </v-btn>
              </template>
              <span>Download Spreadsheet</span>
          </v-tooltip>          
<!--

          <v-tooltip bottom
                      nudge-bottom="10"
                      :disabled="$vuetify.breakpoint.xsOnly"
                      color="ycmd-black"
                      content-class="fade-0 pa-4 pt-2 pb-2">
              <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="pay(item)">
                      <v-icon :size="22" color="blue">attach_money</v-icon>
                  </v-btn>
              </template>
              <span>Pay</span>
          </v-tooltip>
-->
        </template>
        <template v-slot:footer="props" >
            <div
              class="text-center"
              style="width:100%"
              v-if="nextToken && group_id"
            >
              <v-btn
                @click="more"
                text
                class="ycmd-blue--text text-none text-decoration-underline"
                :disabled="loading"
              >
                Load more
              </v-btn>

            </div>
        </template>
      </v-data-table>

      <v-btn        
        color="primary"            
        type="submit"
        :disabled="selectedInvoices.length<=0 ? true : false"
        @click="payInvoices"
      >                    
      Pay Selected Invoices
      </v-btn>

      <payment v-model="openPayment" :group_id="group_id" :invoices="selectedInvoices" @cancel="cancelPayment" @success="paymentMade"/>

  </div>
</template>

<script>
import { format } from 'date-fns';
import BILLING_INVOICE_GET from '@/graphql/queries/billing/billingInvoiceGet'
import GET_RECORDING_URL from '@/graphql/queries/phoenix/ycmdRecordingUrlGet';
import payment from "@/components/payment";

export default {
    name: 'datePicker',
    components: {
      payment
    },
    props: {
      group_id: {
        type: String,
        default: null
      }
    },
    data: () => ({
      invoices: [],
      loading: false,
      itemsPerPage: 10,
      nextToken: null,
      currentInvoice: null,
      openPayment: false,
      selectedInvoices: []
    }),
    mounted() {
      this.loadInvoices()
    },
    methods: {
      payInvoices () {
        this.openPayment = true
      },
      cancelPayment() {
        this.openPayment = false
      },
      paymentMade(payload) {
        this.openPayment = false

        payload.invoices.map(u => {
          let index = this.invoices.findIndex(i => {
            return i.number == u.number
          })

          if (index >= 0) {
            this.$set(this.invoices, index, u)
          }

        })

        this.selectedInvoices = []

        this.$toasted.success(`Made payment for invoice(s): ${(JSON.parse(payload.invoiceNumbers)).join(',')}`);

      },
      pay (invoice) {
        this.currentInvoice = invoice
        this.openPayment = true
      },
      more () {
        if (this.nextToken) {
          this.loadInvoices(this.nextToken)
        }
      },
      async loadInvoices (nextToken) {

        if (!this.group_id) {
          this.invoices = []
          return
        }

        this.loading = true

        let response = await this.$apollo.query({
            query: BILLING_INVOICE_GET,
            variables: {
                group_id: this.group_id,
                page: this.itemsPerPage,
                nextToken
            },
            fetchPolicy: 'no-cache',
            errorPolicy: 'all'
        });
        if (response && response.data && response.data.billingInvoiceGet && response.data.billingInvoiceGet.invoices && response.data.billingInvoiceGet.invoices.length) {
          if (nextToken) {
            this.invoices.push(...response.data.billingInvoiceGet.invoices)
          } else {
            this.invoices = response.data.billingInvoiceGet.invoices
          }
          this.nextToken = response.data.billingInvoiceGet.nextToken ? response.data.billingInvoiceGet.nextToken : null 
        }

        this.loading = false
      },
      async download (item, type) {
        let response = await this.$apollo.query({
            query: GET_RECORDING_URL,
            variables: {
                invoice_id: `${item.number}|${type}`,
                params: [{name: 'check_alternate', value: 'false'}]
            },
            fetchPolicy: 'no-cache',
            errorPolicy: 'all'
        });

        if (response && response.data && response.data.ycmdRecordingUrlGet) {
          this.$toasted.success(`Downloaded invoice. Please make sure pop-up blocker is not enabled`);
          window.open(response.data.ycmdRecordingUrlGet, '_blank');
        } else {
            if (response && response.errors && response.errors.length) {
                this.$toasted.error(`Error: ${response.errors[0].message}`);
            }
        }

      }
    },
    computed: {
      headers () {
          let headers = [
              {
                  text: 'Number',
                  value: 'number',
                  width: '8%',
                  sortable: false
              },
              {
                  text: 'Service Period',
                  value: 'period',
                  width: '20%',
                  sortable: false
              },
              {
                  text: 'Created',
                  value: 'date_added',
                  width: '19%',
                  sortable: false
              },
              {
                  text: 'Due',
                  value: 'date_due',
                  width: '9%',
                  sortable: false
              },
              {
                  text: 'Status',
                  value: 'status',
                  width: '9%',
                  sortable: false
              },
              /*
              {
                  text: 'Past Due',
                  value: 'past_due',
                  width: '10%',
                  sortable: false
              },
              */
              {
                  text: 'Invoice Amount',
                  value: 'amount',
                  align: 'right',
                  width: '10%',
                  sortable: false
              },
              {
                  value: 'amount_due',
                  align: 'right',
                  text: 'Invoice Remaining',
                  width: '10%',
                  sortable: false
              },
              {
                text: "Actions",
                value: 'actions',
               // align: 'right',
                width: '150px',
                sortable: false
              }
          ]
          return headers
      },
      items () {
          let arr = []
          let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          });

          if (this.invoices && this.invoices.length) {
              this.invoices.map(i => {
                let invoice = {
                  number: i.number,
                  period: `${format(new Date(i.date_service_start), 'MM/dd/yy' )} - ${format(new Date(i.date_service_end), 'MM/dd/yy' )}`,
                  date_added: format(new Date(i.date_added), 'MM/dd/yy hh:mm a' ),
                  date_due: format(new Date(i.date_due), 'MM/dd/yy' ),
                  status: i.status,
                  amount: formatter.format(i.amount),
                  amount_due: i.amount_due,
                  isSelectable: i.amount_due > 0,
                  itemClass: i.amount_due > 0 ? 'ycmd-light-red': null
                }

                arr.push(invoice)
              })
          }

          return arr
      },
    },
    watch: {
      group_id(val) {
        this.selectedInvoices = []
        if (val) {
          this.loadInvoices()
        } else {
          this.invoices = []
        }
      }
    }
}
</script>
