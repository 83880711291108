import gql from 'graphql-tag'

export default gql`mutation ycmdChatRoomCreate(
    $room_type: ChatRoomType!, 
    $name: String, 
    $participants: [ID], 
    $post_text: String,
    $meta: [NameValueInput],
    $tags: [String]) {
	ycmdChatRoomCreate(
      room_type: $room_type, 
      name: $name, 
      participants: $participants,
      post_text: $post_text, 
      meta: $meta,
      tags: $tags) {
    id
    created_by_id
    date_created
    date_updated
    last_post_id
    last_post_time
    last_post_text
    last_post_type
    unread_count
    name
    room_type
    participants {
        date_joined
        date_left
        date_last_seen
        last_post_seen
        user_id
        first_name
        last_name
        creator
        title
        specialty
        group_id
        group_name
        online_status
        status
        ooo
    }
    meta {
        name
        value
    }
    tags
	}
}`
