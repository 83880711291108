<template>
    <div
        style="width: 100%"
    >
        <additional-prompt-item
            v-for="item in mergedPrompts"
            :prompt="item"
            :key="item.key"
            :language="language"
        />
    </div>
</template>
<script>
import ivrAdditionalPromptItem from './ivrAdditionalPromptItem'

export default {
    components: {
        'additional-prompt-item': ivrAdditionalPromptItem
    },
    props: {
        prompts: {
            type: Array,
            default: () => []
        },
        language: {
            type: String,
            default: ''
        },
        allPrompts: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        calculatePrompts (prompts) {
            let arr = []
            let english = this.collectEnglish
            let spanish = this.collectSpanish

            prompts.map(mp => {
                if (mp.override) {
                    mp.prompt.map(p => {
                        if (english && p.language === 'en-US') {
                            arr.push({
                                language: 'en-US',
                                key: mp.key,
                                text: p.text
                            })
                        }
                        if (spanish && p.language === 'es-US') {
                            arr.push({
                                language: 'es-US',
                                key: mp.key,
                                text: p.text
                            })
                        }
                    })
                }
            })

            return arr
        }
    },
    computed: {
        collectEnglish () {
            return !this.language || this.language === 'en-US'
        },
        collectSpanish () {
            return !this.language || this.language === 'es-US'
        },
        mergedPrompts () {
            let arr = []

            let prompts = {}

            this.prompts.map(p => {
                prompts[p.key] = p
            })

            this.allPrompts.map(p => {
                console.log('p', p)
                this.$set(p, 'override', false)
                //p.override = true

                let custom = prompts[p.key]

                if (custom) {
                    this.$set(p, 'override', true)
                    this.$set(p, 'text', custom.text)
                }

                arr.push(p)
            })
            return arr
        }
    },
    data: () => ({
    }),
    activated() {
    },
    mounted() {
    },
    watch: {
        mergedPrompts: {
            handler (newValue, oldValue) {
                let prompts = this.calculatePrompts(newValue)
                this.$emit('promptsChanged', prompts)
            }, 
            deep: true
        }
    }
}

</script>
