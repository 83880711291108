<template>

    <div class="view ycmd-blue-gradient">

        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="false"
                 :background-color="`#101622`"
                 :color="`white`"
                 :loader="`bars`">
        </loading>

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <div class="i-card__main pa-2">

                <div class="i-card__main__content white br-2 pa-2">

                    <div class="i-card__scrollable ycmd-light-gray full-width full-height">

                        <v-container
                            fluid
                            class="ma-2"
                        >

                            <v-row
                                class="pl-0 pr-0 pb-0 pt-0 align-center"
                            >
                                <v-col
                                    cols="12"
                                    lg="3"
                                    md="4"
                                    class="pa-0 ma-0"
                                >   
                                    <v-autocomplete
                                        light dense flat outlined
                                        v-model="selectedGroup"
                                        :disabled="!allGroups.length"
                                        :items="allGroups"
                                        :loading="isLoadingGroupsSearch"
                                        label="Select Group"
                                        item-value="id"
                                        item-text="name"
                                        background-color="white"
                                        clearable
                                        return-object
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                            
                            <v-row
                                class="pl-0 pr-0 pb-0 pt-0 align-center"
                            >
                                <v-col
                                    cols="6"
                                    lg="3"
                                    md="4"
                                    class="pa-0 ma-0"
                                >
                                    <date-picker v-model="start" label="Start Date" />
                                </v-col>
                                <v-col
                                    cols="6"
                                    lg="3"
                                    md="4"
                                    class="py-0 ma-0 ml-5"
                                >
                                    <date-picker v-model="end" label="End Date" />
                                </v-col>

                            </v-row>

                            <v-row
                                class="pl-0 pr-0 pb-0 align-center pt-5"
                            >
                                <v-col
                                    cols="12"
                                    class="pa-0 ma-0"
                                >   
                                    <label
                                        class="v-label v-label--active theme--light text-caption"
                                    >Additional Charges</label>
                                </v-col>
                            </v-row>


                            <v-row
                                class="pl-0 pr-0 pb-0 pt-0 align-center"
                            >
                                <v-col
                                    cols="12"
                                    lg="6"
                                    md="8"
                                    class="py-0 ma-0"

                                >

                                    <v-simple-table>
                                        <template v-slot:default>
                                        <thead
                                            style="background-color: #F0F6FA"
                                        >
                                            <tr>
                                            <th class="text-left"
                                                style="border-bottom: none;width: 20px;"
                                            >
                                            &nbsp;
                                            </th>
                                            <th class="text-left"
                                                style="border-bottom: none"
                                            >
                                                Description
                                            </th>
                                            <th class="text-left"
                                                style="border-bottom: none"
                                            >
                                                Amount
                                            </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            v-for="(item, i) in additionalCharges"
                                            :key="`ac_${i}`"
                                            >
                                            <td><v-icon
                                                    :size="20"
                                                    color="error"
                                                    style="cursor: pointer;"
                                                    @click="removeCharge(i)"
                                                >delete</v-icon></td>
                                            <td>{{ item.description }}</td>
                                            <td>{{item.chargeType === 'paid' ? '$' : ''}}{{ item.amount }}{{item.chargeType === 'paid' ? '' : '%'}} </td>
                                            </tr>
                                        </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>

                            <v-row
                                class="pl-0 pr-0 pb-0 pt-5 align-center my-0"
                            >
                                <v-col
                                    cols="6"
                                    lg="3"
                                    md="4"
                                    class="pa-0 ma-0"
                                >   

                                    <v-text-field
                                        label="Description"
                                        light dense flat outlined
                                        v-model="description"
                                        background-color="white"
                                        :disabled="disableControls"
                                    />

                                </v-col>
                                <v-col
                                    cols="6"
                                    lg="3"
                                    md="4"
                                    class="py-0 ma-0 ml-5"
                                >   

                                    <v-text-field
                                        :prepend-icon="chargeType"
                                        @click:prepend="toggleType"
                                        label="Amount"
                                        light dense flat outlined
                                        v-model="amount"
                                        :disabled="disableControls"
                                        background-color="white"
                                    >

                                    </v-text-field>

                                </v-col>


                            </v-row>

                            <v-row
                                class="pl-0 pr-0 pb-0 pt-2 align-center my-0"
                            >
                                <v-col
                                    cols="6"
                                    lg="3"
                                    md="4"
                                    class="pa-0 ma-0"
                                >

                                </v-col>

                                <v-col
                                    cols="6"
                                    lg="3"
                                    md="4"

                                    class="py-0 ma-0 ml-5"
                                >
                                    <v-btn
                                        color="primary"
                                        @click="addCharge"
                                        class="float-right"
                                        :disabled="!isValid"
                                    >
                                        Add
                                    </v-btn>

                                </v-col>
                            </v-row>

                            <v-row
                                class="pl-0 pr-0 pb-0 align-center pt-5"
                            >
                                <v-col
                                    cols="12"
                                    class="pa-0 ma-0"
                                >   
                                    <label
                                        class="v-label v-label--active theme--light text-caption"
                                    >Additional Discounts</label>
                                </v-col>
                            </v-row>


                            <v-row
                                class="pl-0 pr-0 pb-0 pt-0 align-center"
                            >
                                <v-col
                                    cols="12"
                                    lg="6"
                                    md="8"
                                    class="py-0 ma-0"
                                >
                                    <v-simple-table>
                                        <template v-slot:default>
                                        <thead
                                            style="background-color: #F0F6FA;"
                                        >
                                            <tr>
                                            <th class="text-left"
                                                style="border-bottom: none;width: 20px;"
                                            >
                                            &nbsp;
                                            </th>
                                            <th class="text-left"
                                                style="border-bottom: none"
                                            >
                                                Description
                                            </th>
                                            <th class="text-left"
                                                style="border-bottom: none"                                            
                                            >
                                                Amount
                                            </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            v-for="(item, i) in additionalDiscounts"
                                            :key="`ad_${i}`"
                                            >
                                            <td><v-icon
                                                    :size="20"
                                                    color="error"
                                                    style="cursor: pointer;"
                                                    @click="removeDiscount(i)"
                                                >delete</v-icon></td>
                                            <td>{{ item.description }}</td>
                                            <td>{{item.discountType === 'paid' ? '$' : ''}}{{ item.amount }}{{item.discountType === 'paid' ? '' : '%'}} </td>
                                            </tr>
                                        </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>

                            <v-row
                                class="pl-0 pr-0 pb-0 pt-2 align-center"
                            >
                                <v-col
                                    cols="6"
                                    lg="3"
                                    md="4"
                                    class="pa-0 ma-0"
                                >   

                                    <v-text-field
                                        label="Description"
                                        light dense flat outlined
                                        v-model="discountDescription"
                                        background-color="white"
                                        :disabled="disableControls"
                                    />

                                </v-col>
                                <v-col
                                    cols="6"
                                    lg="3"
                                    md="4"
                                    class="py-0 ma-0 ml-5"
                                >   

                                    <v-text-field
                                        :prepend-icon="discountType"
                                        @click:prepend="toggleDiscount"
                                        label="Amount"
                                        light dense flat outlined
                                        v-model="discountAmount"
                                        :disabled="disableControls"
                                        background-color="white"
                                        class="ma-0"
                                    >

                                    </v-text-field>

                                </v-col>


                            </v-row>


                            <v-row
                                class="pl-0 pr-0 pb-0 pt-2 align-center my-0"
                            >
                                <v-col
                                    cols="6"
                                    lg="3"
                                    md="4"
                                    class="pa-0 ma-0"
                                >

                                </v-col>

                                <v-col
                                    cols="6"
                                    lg="3"
                                    md="4"
                                    class="py-0 ma-0 ml-5"
                                >
                                    <v-btn
                                        color="primary"
                                        @click="addDiscount"
                                        class="float-right"
                                        :disabled="!isValidDiscount"
                                    >
                                        Add
                                    </v-btn>

                                </v-col>
                            </v-row>


                            <v-row
                                class="pl-0 pr-0 pb-0 align-center"
                            >
                                <v-col
                                    cols="12"
                                    class="pa-0 ma-0"

                                >   
                                    <v-btn
                                        depressed 
                                        light 
                                        class="ycmd-blue white--text ma-0" 
                                        :disabled="disableControls || chargeInProcess || !isValidInvoice"
                                        style="width: 180px;"
                                        @click="invoice">Invoice</v-btn>
                                </v-col>
                            </v-row>

                            <v-row
                                class="pl-0 pr-0 pb-0 align-center"
                                v-if="invoiceResult"
                            >
                                <v-col
                                    class="shrink"
                                >   
                                    Invoice
                                </v-col>
                                <v-col
                                    class="shrink"
                                >   
                                    {{invoiceResult.number}}
                                </v-col>
                                <v-col
                                    class="shrink"
                                >   
                                    Amount
                                </v-col>
                                <v-col
                                    class="shrink"
                                >   
                                    {{invoiceResult.amount}}
                                </v-col>

                            </v-row>
                            <v-row
                                class="pl-0 pr-0 pb-0 align-center"
                                v-if="invoiceResult"
                            >
                                <v-col
                                    cols="12"
                                >
                                    <v-list dense
                                        max-width="400px"
                                    >

                                        <v-list-item
                                            v-for="line in invoiceResult.lines"
                                            :key="line.key"
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title class="fs-14">{{line.description}}</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                {{line.amount}}
                                            </v-list-item-action>

                                        </v-list-item>

                                    </v-list>
                                </v-col>

                            </v-row>



                        </v-container>

                    </div>
                </div>
            </div>

        </i-card>

    </div>

</template>

<script>

    import Loading from 'vue-loading-overlay';

    import {defaultErrorHandler} from '../../../../graphql/handler/errorHandler';

    import SEARCH_GROUPS from '../../../../graphql/queries/phxGroups';
    import GET_GROUP_BILLING from '../../../../graphql/queries/billing/billingGroup'
    import BILLING_INVOICE from '@/graphql/mutations/billing/billingInvoice'
    import DatePicker from "@/components/date-picker";
    import {mapGetters, mapActions} from "vuex";
    export default {
        name: 'updateGroup',
        components: {
            Loading,
            'date-picker': DatePicker
        },
        props: {
            params: {
                type: Object
            }
        },
        data() {
            return {
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                start: null,
                end: null,
                isLoading: false,
                sending: false,
                errorMsg: '',
                successMsg: '',
                description: null,
                amount: null,
                discountDescription: null,
                discountAmount: null,
                allGroups: [],

                selectedGroup: null,
                isLoadingGroupsSearch: false,

                currentBillingGroup: null,
                chargeType: 'paid',
                additionalCharges: [],
                additionalDiscounts: [],
                discountType: 'paid',
                invoiceResult: null,
                invoiceResultTest: {
                    number: 9999999,
                    amount: 6,
                    lines: [
                        {
                            description: "line 1",
                            amount: 1,
                            key: 'bi1'                
                        },
                        {
                            description: "line 2",
                            amount: 2,
                            key: 'bi2'                
                        },
                        {
                            description: "line 3",
                            amount: 3,
                            key: 'bi3'                
                        }
                    ],
                    id: '9999999'
                }
            }
        },
        mounted() {
          this.getGroups(null);
          
          if (this.billingGroupGetter) {
              this.selectedGroup = this.billingGroupGetter
          }
        },
        computed: {
            isValidInvoice () {
                if (!this.selectedGroup) {
                    return false
                }
                if (!this.start) {
                    return false
                }
                if (!this.end) {
                    return false
                }

                return true
            },
            chargeInProcess () {
                return !!(this.description || this.amount || this.discountDescription || this.discountAmount)
            },
            disableControls () {
                if (!this.selectedGroup || !this.currentBillingGroup) {
                    return true
                }

                if (this.sending) {
                    return true
                }

                return false
            },
            isValid () {
                if (!this.description) {
                    return false
                }
                if (!this.amount) {
                    return false
                }
                return true
            },
            isValidDiscount () {
                if (!this.discountDescription) {
                    return false
                }
                if (!this.discountAmount) {
                    return false
                }
                return true
            },
            ...mapGetters('profile', ['billingGroupGetter']),
        },
        methods: {
            ...mapActions('billing', ['getAllGroups']),     
            removeDiscount(index) {
                this.additionalDiscounts.splice(index,1)
            },
            removeCharge(index) {
                this.additionalCharges.splice(index,1)
            },
            convertCharges( charges, chargeTypeField) {
                let arr = []
                charges.map(c => {
                    arr.push({
                        description: c.description,
                        amount: c.amount,
                        percent: c[chargeTypeField] === 'percent' ? true : false
                    })
                })

                return arr
            },
            addDiscount () {
                this.additionalDiscounts.push({description: this.discountDescription, amount: this.discountAmount, discountType: this.discountType })
                this.discountDescription = ''
                this.discountAmount = ''
                this.discountType = 'paid'
            },
            addCharge () {
                this.additionalCharges.push({description: this.description, amount: this.amount, chargeType: this.chargeType })
                this.description = ''
                this.amount = ''
                this.chargeType = 'paid'
            },
            toggleType () {
                if (this.chargeType === 'paid') {
                    this.chargeType = 'percent' 
                } else {
                    this.chargeType = 'paid'
                }
            },
            toggleDiscount () {
                if (this.discountType === 'paid') {
                    this.discountType = 'percent' 
                } else {
                    this.discountType = 'paid'
                }
            },
            updateObject () {
                let obj = {}
                for (let prop in this.currentBillingGroup) {
                    switch (prop) {
                        default: 
                            obj[prop] = this.currentBillingGroup[prop]
                            break
                            
                        case 'address':
                            let add = {}

                            for (let a in this.currentBillingGroup[prop]) {
                                switch (a) {
                                    case '__typename':
                                        break
                                    default:
                                        add[a] = this.currentBillingGroup[prop][a]
                                        break
                                }
                            }

                            obj[prop] = add

                            break
                        case 'group_id': 
                        case 'group_id_postgres': 
                        case '__typename': 
                            break;
                    }
                }
                return obj
            },
            route(name, transition) {

                this.$router.push({
                    name: name,
                    params: {
                        transition: transition || this.transition
                    }
                }).catch(err => { console.log('router error: ', err) });
            },
            selectAllProviders() {
              this.selectedProviders = this.searchedProviders;
            },
            async getGroups(val, persistMessages) {

                this.isLoadingGroupsSearch = true;
                try {

                    this.allGroups = await this.getAllGroups()

                    if (!persistMessages) {
                        this.successMsg = ''
                        this.errorMsg = ''
                    }

                    this.isLoadingGroupsSearch = false;

                } catch (e) {
                    this.isLoadingGroupsSearch = false;
                    defaultErrorHandler(e);
                }
            },
            async getBillingGroup(val) {
                // console.log('val: ', val);
                this.isLoading = true;
                try {
                    const response = await this.$apollo.query({
                        query: GET_GROUP_BILLING,
                        variables: {
                            group_id: val.id
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });

                    this.isLoading = false;
                    console.log('billingGroup response: ', response);
                    if (response.data && response.data.billingGroup && response.data.billingGroup.length) {
                      if (response.data.billingGroup[0]) {
                          console.log('currentBillingGroup Data: ', response.data.billingGroup[0]);
                          this.currentBillingGroup = response.data.billingGroup[0];
                            this.successMsg = ''
                            this.errorMsg = ''
                            /*
                          this.altName = this.currentBillingGroup.altName;
                          this.name = this.currentBillingGroup.name;
                          this.altName = val.alt_name;
                          this.name = val.name;
                          this.shortCode = this.currentBillingGroup.shortCode;
                          */
                         console.log('this.currentBillingGroup', this.currentBillingGroup)

                         let additionalCharges = []
                         if (this.currentBillingGroup.bill_on_additional_charges && this.currentBillingGroup.bill_on_additional_charges.length) {
                            additionalCharges = this.currentBillingGroup.bill_on_additional_charges.filter(charge => {
                                return charge.bill_on_behavior === 'persist'
                            })
                         }
                         additionalCharges.map(c => {
                            this.additionalCharges.push({description: c.description, amount: parseFloat(c.amount), chargeType: c.percent ? 'percent' : 'paid' })
                         })

                         let additionalDiscounts = []
                         if (this.currentBillingGroup.bill_on_additional_discounts && this.currentBillingGroup.bill_on_additional_discounts.length) {
                            additionalDiscounts = this.currentBillingGroup.bill_on_additional_discounts.filter(charge => {
                                return charge.bill_on_behavior === 'persist'
                            })
                         }
                         additionalDiscounts.map(c => {
                            this.additionalDiscounts.push({description: c.description, amount: c.amount, discountType: c.percent ? 'percent' : 'paid' })
                         })

                      }
                    } else if (response.errors && response.errors.length > 0) {
                        this.successMsg = ''
                        if (response.errors[0].message) {
                            this.errorMsg = response.errors[0].message;
                        } else {
                            this.errorMsg = "Error 2E. Invalid response.";
                        }
                    } else {
                        // data did not come back
                        this.successMsg = ''
                        this.errorMsg = "There is no billing data for this group";
                        console.log('billingGroup data did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoading = false;
                    defaultErrorHandler(e);
                }
            },
            async invoice() {
                this.sending = true
                this.invoiceResult = null
                console.log('this.additionalCharges', this.additionalCharges)
                console.log('this.additionalDiscounts', this.additionalDiscounts)
                let charges = this.convertCharges(this.additionalCharges, 'chargeType')
                let discounts = this.convertCharges(this.additionalDiscounts, 'discountType')

                const response = await this.$apollo.mutate({
                    mutation: BILLING_INVOICE,
                    variables: {
                        group_id: this.selectedGroup.id,
                        operation: 'test',
                        start: this.start,
                        end: this.end,
                        additional_charges: charges, 
                        additional_discounts: discounts
                    },
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all'
                });

                if (response && response.data && response.data.billingInvoice) {
                    this.invoiceResult = response.data.billingInvoice
                }                
                this.sending = false
            },
            clearFields(fromWatch) {
              if (!fromWatch) {
                this.selectedGroup = null;
              }
              this.currentBillingGroup = null;
              // this.name = '';
              //this.altName = '';
              //this.shortCode = '';
              this.errorMsg = '';
              this.additionalCharges = []
              this.additionalDiscounts = []
            },
            changeView(view, params) {

                this.$emit('onChangeView', {view: view, params: params});
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
        },
        watch: {
          selectedGroup(val) {
            this.clearFields(true);

            if (!val || !val.id) return;

            this.getBillingGroup(val);
            this.$store.commit('profile/setBillingGroup', val)
          }

        }
    }

</script>

<style scoped>

    .item-box {
        background-color: #fff;
        border-bottom: #177ad5 solid 1px;
    }

    .item-box.odd {
        background-color: #ECEFF1;
    }

    .item-box:hover {
        background-color: rgba(255, 255, 255, 0.8)
    }

</style>
