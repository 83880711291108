import gql from 'graphql-tag'

export default gql`mutation billingInvoice($group_id: ID, $operation: String, $start: AWSDate, $end: AWSDate, $additional_charges: [BillingCharge], $additional_discounts: [BillingCharge]) {
	billingInvoice(group_id: $group_id, operation: $operation, start: $start, end: $end, additional_charges: $additional_charges, additional_discounts: $additional_discounts) 
  {
    number
    amount
    id
    lines {
      description
      amount
      key
    }
  }
}`
