<template>

  <v-layout column class="blue full-width full-height">

    <v-toolbar dark text class="transparent">

      <div class="fs-16 uppercase pl-2">Directory</div>

      <v-spacer />

    </v-toolbar>

    <v-layout class="overflow-none pl-1 pr-1 pb-1" style="height: calc(100% - 56px) !important">

      <v-layout column align-center justify-center class="full-height white--text">

        <div class="fs-32 bold">DIRECTORY</div>
        <div>Coming Soon</div>

      </v-layout>

    </v-layout>

  </v-layout>

</template>

<script>

  export default {
    name: 'directory',
    data () {
      return {

      }
    },
    methods: {
      route(name, transition) {

        this.$router.push({
          name: name, params: {
            transition: transition
          }
        }).catch(err => { console.log('router error: ', err) });
      }
    }
  }

</script>

<style scoped>



</style>
