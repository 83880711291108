import gql from 'graphql-tag'

export default gql`query pcpImportProviders($status: [PCPStatus], $sort_field: PCPImportSortField, $sort_direction: SortDirection, $page: Int, $limit: Int, $search: String) {
	pcpImportProviders(status: $status, sort_field: $sort_field, sort_direction: $sort_direction, page: $page, limit: $limit, search: $search) {
		pcp_imports {
			id
			first_name
			last_name
			email
			title
			group_name
			phone
			fax
			specialty
			license_number
			status
			source
			reference_id
			registration_code_expiration_date
		}
		total_count
	}
}`
