<template>

    <div class="view ycmd-blue-gradient">

        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="false"
                 :background-color="`#101622`"
                 :color="`white`"
                 :loader="`bars`">
        </loading>

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <div class="i-card__main pa-2">

                <div class="i-card__main__content white br-2 pa-2">

                    <div class="i-card__scrollable ycmd-light-gray full-width full-height">

                      <div class="pr-1 pa-2">

                          <div>
                              <v-row>
                                  <v-col
                                    class="shrink"
                                  >
                                        <v-autocomplete
                                                light dense flat outlined
                                                v-model="selectedGroup"
                                                :disabled="!allGroups.length"
                                                :items="filteredGroups"
                                                :loading="isLoadingGroupsSearch"
                                                label="Select Group"
                                                item-value="id"
                                                item-text="name"
                                                style="width: 400px;"
                                                background-color="white"
                                                clearable
                                                return-object
                                        ></v-autocomplete>
                                  </v-col>
                                  <v-col
                                    class="shrink"
                                  >    
                                        <v-select 
                                                light solo flat outlined dense
                                                style="width: 200px;"
                                                :items="billOnItems"
                                                v-model="filterBillOnDay"
                                                item-text="label"
                                                item-value="value"
                                                label="Bill on day"
                                        />
                                  </v-col>
                              </v-row>


                              <div v-if="(currentBillingGroup && selectedGroup)">
                                <v-text-field
                                        light dense flat outlined
                                        class="small-input"
                                        style="max-width: 400px;"
                                        v-model="currentBillingGroup.name"
                                        label="Name"
                                        background-color="white" />

                                <v-text-field
                                        light dense flat outlined
                                        v-if="selectedGroup"
                                        class="small-input"
                                        style="max-width: 400px;"
                                        v-model="currentBillingGroup.altName"
                                        label="Alt Name"
                                        background-color="white" />

                                <v-text-field
                                        light dense flat outlined
                                        v-if="selectedGroup"
                                        class="small-input"
                                        style="max-width: 400px;"
                                        v-model="currentBillingGroup.shortCode"
                                        label="Short Code"
                                        background-color="white" />
                                        
                                <v-text-field
                                        light dense flat outlined
                                        v-if="selectedGroup"
                                        class="small-input"
                                        style="max-width: 400px;"
                                        v-model="currentBillingGroup.address.address"
                                        label="Address"
                                        background-color="white" />

                                <v-text-field
                                        light dense flat outlined
                                        v-if="selectedGroup"
                                        class="small-input"
                                        style="max-width: 400px;"
                                        v-model="currentBillingGroup.address.addressContinue"
                                        label="Address Continued"
                                        background-color="white" />

                                <v-text-field
                                        light dense flat outlined
                                        v-if="selectedGroup"
                                        class="small-input"
                                        style="max-width: 400px;"
                                        v-model="currentBillingGroup.address.city"
                                        label="City"
                                        background-color="white" />

                                <v-text-field
                                        light dense flat outlined
                                        v-if="selectedGroup"
                                        class="small-input"
                                        style="max-width: 400px;"
                                        v-model="currentBillingGroup.address.state"
                                        label="State"
                                        background-color="white" />


                                <v-text-field
                                        light dense flat outlined
                                        v-if="selectedGroup"
                                        class="small-input"
                                        style="max-width: 400px;"
                                        v-model="currentBillingGroup.address.zipCode"
                                        label="Zipcode"
                                        background-color="white" />

                                <v-select v-if="selectedGroup"
                                        light solo flat outlined dense
                                        style="max-width: 400px;"
                                        :items="invoice_days"
                                        v-model="currentBillingGroup.bill_on_day"
                                        item-text="label"
                                        item-value="value"
                                        label="Invoice on day"
                                        />
                                <v-text-field
                                        light dense flat outlined
                                        v-if="selectedGroup"
                                        class="small-input"
                                        style="max-width: 400px;"
                                        v-model="billing_email_address_first_element"
                                        label="Email invoice to"
                                        background-color="white" /> 
                                <v-text-field
                                        light dense flat outlined
                                        v-if="selectedGroup"
                                        class="small-input"
                                        style="max-width: 400px;"
                                        v-model="currentBillingGroup.billing_contact_salutation"
                                        label="Billing contact salutation"
                                        background-color="white" />                                                                       
                                <v-text-field
                                        light dense flat outlined
                                        v-if="selectedGroup"
                                        class="small-input"
                                        style="max-width: 400px;"
                                        v-model="currentBillingGroup.billing_contact_name"
                                        label="Billing contact name"
                                        background-color="white" />                                                                       

                              </div>

                                <v-btn depressed light class="ycmd-blue white--text" :disabled="(!selectedGroup || !currentBillingGroup)" style="width: 180px;" @click="updateBillingGroup">Update Group</v-btn>

                                <div v-if="errorMsg" class="mt-3" style="color: red">{{errorMsg}}</div>
                                <div v-if="successMsg" class="mt-3" style="color: green">{{successMsg}}</div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </i-card>

    </div>

</template>

<script>

    import { format, parseISO } from 'date-fns';
    import Loading from 'vue-loading-overlay';

    import {defaultErrorHandler} from '../../../../graphql/handler/errorHandler';
    import {refreshHandler} from '../../../../graphql/handler/refreshHandler';
    import SEARCH_GROUPS from '../../../../graphql/queries/phxGroups';
    import GET_GROUP_BILLING from '../../../../graphql/queries/billing/billingGroup'
    import UPDATE_BILLING_GROUP from '../../../../graphql/mutations/billing/billingGroupUpdate'
    import {mapGetters, mapActions} from "vuex";

    export default {
        name: 'updateGroup',
        components: {
            Loading
        },
        props: {
            params: {
                type: Object
            }
        },
        data() {
            return {
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                dialog: false,
                selectedModal: null,
                isLoading: false,
                errorMsg: '',
                successMsg: '',

                allGroups: [],

                selectedGroup: null,
                groupsSearched: [],
                isLoadingGroupsSearch: false,
/*
                name: '',
                altName: '',
                shortCode: '',
*/
                billing_email_address_first_element: '',
                currentBillingGroup: null,
                invoice_days: [{label: 'Do not invoice', value: 0 }, {label: '2', value: 2}, {label: '15', value: 15}],
                billOnItems: [{label: 'Any', value: 0 }, {label: 'Bill on 2nd', value: 2}, {label: 'Bill on 15th', value: 15}],
                filterBillOnDay: null
            }
        },
        activated() {

        },
        async mounted() {
            await this.getGroups(null);
            this.selectedGroup = this.billingGroupGetter
        },
        computed: {
            filteredGroups () {
                let arr = []
                if (this.allGroups && this.allGroups.length) {
                    this.allGroups.map(g => {
                        if (!this.filterBillOnDay || g.bill_on_day == this.filterBillOnDay) {
                            console.log(`pushing group ${g.name}: `, !this.filterBillOnDay || g.bill_on_day == this.filterBillOnDay )
                            arr.push(g)
                        }
                    })
                }
                return arr
            },
            ...mapGetters('profile', ['billingGroupGetter']),
        },
        methods: {
            ...mapActions('billing', ['getAllGroups']),                 
            updateObject () {
                let obj = {}
                for (let prop in this.currentBillingGroup) {
                    console.log('prop', prop)
                    switch (prop) {
                        default: 
                            obj[prop] = this.currentBillingGroup[prop]
                            break
                            
                        case 'address':
                            let add = {}

                            for (let a in this.currentBillingGroup[prop]) {
                                switch (a) {
                                    case '__typename':
                                        break
                                    default:
                                        add[a] = this.currentBillingGroup[prop][a]
                                        break
                                }
                            }

                            obj[prop] = add

                            break
                        case 'group_id': 
                        case 'group_id_postgres': 
                        case '__typename': 
                        case 'billing_plan':
                        case 'extended_billing_plan':
                        case 'bill_on_additional_charges':
                        case 'bill_on_additional_discounts':
                            break;
                    }
                }

                return obj
            },
            route(name, transition) {

                this.$router.push({
                    name: name,
                    params: {
                        transition: transition || this.transition
                    }
                }).catch(err => { console.log('router error: ', err) });
            },
            selectAllProviders() {
              this.selectedProviders = this.searchedProviders;
            },
            async getGroupsOld(val, persistMessages) {
                this.isLoadingGroupsSearch = true;
                try {
                    const response = await this.$apollo.query({
                        query: SEARCH_GROUPS,
                        variables: {
                            group_id: null
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoadingGroupsSearch = false;
                    console.log('phxGroups response: ', response);
                    if (response.data.phxGroups) {
                      if (!val) {
                        this.allGroups = response.data.phxGroups.sort(function(a, b) {
                           return a.name.trim().localeCompare(b.name.trim());
                        });
                      }
                    } else if (response.errors && response.errors.length > 0) {
                        if (response.errors[0].message) {
                            this.errorMsg = response.errors[0].message;
                        } else {
                            this.errorMsg = "Error 2E. Invalid response.";
                        }
                    } else {
                        // data did not come back
                        console.log('phxGroups data did not come back: ', response);
                    }
                    if (!persistMessages) {
                        this.successMsg = ''
                        this.errorMsg = ''
                    }
                } catch (e) {
                    this.isLoadingGroupsSearch = false;
                    defaultErrorHandler(e);
                }
            },
            async getGroups(val, persistMessages) {
                this.isLoadingGroupsSearch = true;
                try {

                    this.allGroups = await this.getAllGroups()

                    if (!persistMessages) {
                        this.successMsg = ''
                        this.errorMsg = ''
                    }

                    this.isLoadingGroupsSearch = false;

                } catch (e) {
                    this.isLoadingGroupsSearch = false;
                    defaultErrorHandler(e);
                }
            },            
            async getBillingGroup(val) {
                // console.log('val: ', val);
                this.isLoading = true;
                try {
                    const response = await this.$apollo.query({
                        query: GET_GROUP_BILLING,
                        variables: {
                            group_id: val.id
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoading = false;
                    console.log('billingGroup response: ', response);
                    if (response.data && response.data.billingGroup && response.data.billingGroup.length) {
                      if (response.data.billingGroup[0]) {
                          console.log('currentBillingGroup Data: ', response.data.billingGroup[0]);
                          this.currentBillingGroup = response.data.billingGroup[0];

                            if (!this.currentBillingGroup.address) {
                                this.currentBillingGroup.address = {}
                            }

                          this.billing_email_address_first_element = this.currentBillingGroup.billing_email_address && this.currentBillingGroup.billing_email_address.length ? this.currentBillingGroup.billing_email_address[0]: ''
                            this.successMsg = ''
                            this.errorMsg = ''
                            /*
                          this.altName = this.currentBillingGroup.altName;
                          this.name = this.currentBillingGroup.name;
                          this.altName = val.alt_name;
                          this.name = val.name;
                          this.shortCode = this.currentBillingGroup.shortCode;
                          */
                      }
                    } else if (response.errors && response.errors.length > 0) {
                        this.successMsg = ''
                        if (response.errors[0].message) {
                            this.errorMsg = response.errors[0].message;
                        } else {
                            this.errorMsg = "Error 2E. Invalid response.";
                        }
                    } else {
                        // data did not come back
                        this.successMsg = ''
                        this.errorMsg = "There is no billing data for this group";
                        console.log('billingGroup data did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoading = false;
                    defaultErrorHandler(e);
                }
            },
            async updateBillingGroup() {
                this.isLoading = true;
                try {

                    const response = await this.$apollo.mutate({
                        mutation: UPDATE_BILLING_GROUP,
                        variables: {
                            group_id: this.selectedGroup.id,
                            billing_group: this.updateObject() /*{
                            	name: this.name,
                            	altName: this.altName,
                            	shortCode: this.shortCode
                            }*/
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoading = false;
                    console.log('billingGroupUpdate response: ', response);
                    if (response.data.billingGroupUpdate) {
                      // this.templateSearched = response.data.phxShifts;
                      this.successMsg = `You have successfully updated the group!`
                      this.getGroups(null, true);
                    } else if (response.errors && response.errors.length > 0) {
                        if (response.errors[0].message) {
                            this.errorMsg = response.errors[0].message;
                        } else {
                            this.errorMsg = "Error 2E. Invalid response.";
                        }
                    } else {
                        // data did not come back
                        console.log('billingGroupUpdate data did not come back: ', response);
                    }
                    this.clearFields();
                } catch (e) {
                    this.isLoading = false;
                    this.clearFields();
                    defaultErrorHandler(e);
                }
            },
            clearFields(fromWatch) {
              if (!fromWatch) {
                this.selectedGroup = null;
              }
              this.currentBillingGroup = null;
              // this.name = '';
              //this.altName = '';
              //this.shortCode = '';
              this.errorMsg = '';
            },
            changeView(view, params) {

                this.$emit('onChangeView', {view: view, params: params});
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
        },
        watch: {
          selectedGroup(val) {
            this.clearFields(true);

            if (!val || !val.id) return;

            this.getBillingGroup(val);

            this.$store.commit('profile/setBillingGroup', val)
          },
          billing_email_address_first_element (newVal, oldVal) {
            this.currentBillingGroup.billing_email_address = newVal
          }

        }
    }

</script>

<style scoped>

    .item-box {
        background-color: #fff;
        border-bottom: #177ad5 solid 1px;
    }

    .item-box.odd {
        background-color: #ECEFF1;
    }

    .item-box:hover {
        background-color: rgba(255, 255, 255, 0.8)
    }

</style>
