<template>

    <div class="view ycmd-blue-gradient">

        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="false"
                 :background-color="`#101622`"
                 :color="`white`"
                 :loader="`bars`">
        </loading>

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <div class="i-card__main pa-2">

                <div class="i-card__main__content white br-2 pa-2">

                    <div class="i-card__scrollable ycmd-light-gray full-width full-height">

                        <v-container
                            fluid
                            class="ma-2"
                        >

                            <v-row
                                class="pl-0 pr-0 pb-0 pt-0 align-center"
                            >
                                <v-col
                                    cols="12"
                                    lg="3"
                                    md="4"
                                    class="pa-0 ma-0"
                                >   
                                    <v-autocomplete
                                        light dense flat outlined
                                        v-model="selectedGroup"
                                        :disabled="!allGroups.length"
                                        :items="allGroups"
                                        :loading="isLoadingGroupsSearch"
                                        label="Select Group"
                                        item-value="id"
                                        item-text="name"
                                        background-color="white"
                                        clearable
                                        return-object
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>

                            <v-row
                                class="pl-0 pr-0 pb-0 pt-0 align-center"
                            >
                                <v-col
                                    cols="12"
                                    class="pa-0 ma-0"
                                >   
                                    <payment-methods
                                        :group_id="group_id"
                                    />
                                </v-col>
                            </v-row>

                            <v-row
                                class="pl-0 pr-0 pb-0 pt-2 align-center"
                            >
                                <v-col
                                    cols="12"
                                    class="pa-0 ma-0"
                                >   
                                    <v-btn
                                        color="primary"
                                        @click="addPaymentMethod"
                                    >
                                        Add Payment Method
                                    </v-btn>

                                </v-col>
                            </v-row>

                        </v-container>

                    </div>
                </div>
            </div>

        </i-card>

        <payment-method-add
            v-model="openAddPaymentMethod"
            @cancel="openAddPaymentMethod=false"
            @added="openAddPaymentMethod=false"
            :group_id="group_id"
            />

    </div>

</template>

<script>

    import Loading from 'vue-loading-overlay';

    import {defaultErrorHandler} from '../../../../graphql/handler/errorHandler';

    import SEARCH_GROUPS from '../../../../graphql/queries/phxGroups';
    import PaymentMethods from "@/components/payment-methods";
    import PaymentMethodAdd from "@/components/payment-method-add";
    import {mapGetters, mapActions} from "vuex";
    export default {
        name: 'updateGroup',
        components: {
            Loading,
            'payment-methods': PaymentMethods,
            'payment-method-add': PaymentMethodAdd
        },
        props: {
            params: {
                type: Object
            }
        },
        data() {
            return {
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                isLoading: false,
                errorMsg: '',
                successMsg: '',
                allGroups: [],
                selectedGroup: null,
                isLoadingGroupsSearch: false,
                openAddPaymentMethod: false
            }
        },
        mounted() {
            this.getGroups(null);
            this.selectedGroup = this.billingGroupGetter
            
            /*
            let group = this.allGroups.find(g => {
                return g.id == this.billingGroupGetter
            })

            this.selectedGroup = group
            */
        },
        computed: {
            ...mapGetters('profile', ['billingGroupGetter']),
            group_id () {
                if (this.selectedGroup && this.selectedGroup.id) {
                    return this.selectedGroup.id
                }
                return null
            }
        },
        methods: {
            ...mapActions('billing', ['getAllGroups']),    
            async getGroups(val, persistMessages) {
                this.isLoadingGroupsSearch = true;
                try {
                        this.allGroups = await this.getAllGroups()

                    if (!persistMessages) {
                        this.successMsg = ''
                        this.errorMsg = ''
                    }

                    this.isLoadingGroupsSearch = false;

                } catch (e) {
                    this.isLoadingGroupsSearch = false;
                    defaultErrorHandler(e);
                }
            },                
            addPaymentMethod () {
                this.openAddPaymentMethod = true
            },
            async getGroupsOld(val, persistMessages) {
                this.isLoadingGroupsSearch = true;
                try {
                    const response = await this.$apollo.query({
                        query: SEARCH_GROUPS,
                        variables: {
                            group_id: null
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoadingGroupsSearch = false;
                    console.log('phxGroups response: ', response);
                    if (response.data.phxGroups) {
                      if (!val) {
                        this.allGroups = response.data.phxGroups.sort(function(a, b) {
                           return a.name.trim().localeCompare(b.name.trim());
                        });
                      }
                    } else if (response.errors && response.errors.length > 0) {
                        if (response.errors[0].message) {
                            this.errorMsg = response.errors[0].message;
                        } else {
                            this.errorMsg = "Error 2E. Invalid response.";
                        }
                    } else {
                        // data did not come back
                        console.log('phxGroups data did not come back: ', response);
                    }
                    if (!persistMessages) {
                        this.successMsg = ''
                        this.errorMsg = ''
                    }
                } catch (e) {
                    this.isLoadingGroupsSearch = false;
                    defaultErrorHandler(e);
                }
            }
        },
        watch: {
            selectedGroup(val) {
                this.$store.commit('profile/setBillingGroup', val)                
            }

        }
    }

</script>

<style scoped>

</style>
