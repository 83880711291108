<template>
    <div
        style="max-width: 600px"
    >
        <h3
            v-if="!users.length"
        >{{sending ? 'Loading...' : 'No members in group'}}</h3>
        <v-list
            subheader
            two-line
        >

            <v-list-item
                v-for="u in users"
                :key="u.id"
            >
                <v-list-item-content>
                <v-list-item-title>{{u.last_name}}, {{u.first_name}}</v-list-item-title>

                <v-list-item-subtitle
                    class="pl-5"
                >Mailboxes: {{u.mailboxDescription}}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                <v-btn
                icon
                    @click="startEditMailbox(u)"
                >
                    <v-icon color="grey lighten-1">edit</v-icon>
                </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list>

        <v-dialog v-model="edit" persistent max-width="300">
            <v-card style="padding-top: 15px;">
                <v-card-text>Edit Mailboxes</v-card-text>
                <v-card-text class="fs-18">
                    <v-select
                    v-model="editMailboxes"
                    :items="allMailboxes"
                    label="Select Mailboxes"
                    multiple
                    chips
                    ></v-select>
                    
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn text @click="doSave">OK</v-btn>
                    <v-btn text @click="edit = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>  
 
    </div>
</template>
<script>
import ycmdUserSearch from '@/graphql/queries/phoenix/ycmdUserSearch';
export default {
    components: {
    },
    data: () => ({
        users: [],
        edit: false,
        editMailboxes: [],
        editingUser: null,
        sending: false
    }),
    props: {
        groupId: {
            type: String,
            default: ''
        },
        mailboxes: {
            type: Array,
            default: () => []
        },
        allMailboxes: {
            type: Array,
            default: () => []
        },

    },
    methods: {
        getMailboxesToSave () {
            let mailboxes = {}
            this.users.map(u => {
                if (u.mailboxes && u.mailboxes.length) {
                    u.mailboxes.map(mb => {
                        let mailbox = mailboxes[mb]
                        if (!mailbox) {
                            mailboxes[mb] = {
                                users: {}
                            }
                            mailbox = mailboxes[mb]
                        }
                        mailbox.users[u.id] = true
                    })
                }
            })

            let mbArray = []
            for (let mb in mailboxes) {
                let mbo = mailboxes[mb]
                let arr = []
                for (let u in mbo.users) {
                    arr.push(u)
                }
                mbArray.push({
                    name: mb,
                    users: arr
                })
            }

            return mbArray
        },
        startEditMailbox (u) {
            if (!u.mailboxes || !u.mailboxes.length) {
                //no mailboxes means all mailboxes
                this.editMailboxes = []
                this.allMailboxes.map(mb => {
                    this.editMailboxes.push(mb)
                })
            } else {
                this.editMailboxes = u.mailboxes
            }
            this.editingUser = u
            this.edit = true
        },
        doSave () {
            this.editingUser.mailboxes = this.editMailboxes
            if (!this.editMailboxes || !this.editMailboxes.length) {
                this.editMailboxes[0] = '--none--'
            } else {
                let mbcheck = {}
                this.allMailboxes.map(mb => {
                    mbcheck[mb] = true
                })

                this.editingUser.mailboxes.map(mb => {
                    mbcheck[mb] = false
                })

                let all = true
                this.allMailboxes.map(mb => {
                    if (mbcheck[mb]) {
                        all = false
                    }
                })

                if (all) {
                    this.editingUser.mailboxes = []
                }

            }
            this.editingUser.mailboxDescription = this.getMailboxDescription(this.editingUser.mailboxes)
            this.edit = false

            this.$emit('changed', this.getMailboxesToSave())
        },
        getUserMailboxes () {
            let users = {}
            if (this.mailboxes && this.mailboxes.length) {
                this.mailboxes.map(mb => {
                    if (mb.users && mb.users.length) {
                        mb.users.map(u => {
                            let user = users[u]
                            if (!user) {
                                users[u] = {
                                    mailboxes: []
                                }
                                user = users[u]
                            }
                            user.mailboxes.push(mb.name)
                        })
                    }
                })
            }
            return users
        },
        getMailboxDescription (mailboxes) {
            if (mailboxes && mailboxes.length) {
                if (mailboxes[0] === '--none--') {
                    return 'NONE'
                }
                return mailboxes.join(',')
            } else {
                return 'Any'
            }
        },
        async getUsers () {
            this.sending = true
            console.log('this.groupId', this.groupId)
            let response = await this.$apollo.query({
                query: ycmdUserSearch,
                variables: {
                    group_ids: [this.groupId],
                    roles: ['member']
                },
                fetchPolicy: 'no-cache',
                errorPolicy: 'all'
            });
            let arr = []
            let userMailboxes = this.getUserMailboxes()
            if (response && response.data && response.data.ycmdUserSearch && response.data.ycmdUserSearch.length) {
                let group = response.data.ycmdUserSearch.find(g => { 
                    return g.group_id == this.groupId
                })

                if (group) {
                    group.users.map(u => {
                        console.log('group user', u)
                        let umb = userMailboxes[u.id]
                        u.mailboxes = umb ? umb.mailboxes: null
                        console.log('u.mailboxes', u.mailboxes)
                        u.mailboxDescription = this.getMailboxDescription(u.mailboxes)
                        arr.push(u)
                    })
                }
            }

            arr = arr.sort(function(a, b) {
                let ret = a.last_name.trim().localeCompare(b.last_name.trim());
                if (!ret) {
                    ret = a.first_name.trim().localeCompare(b.first_name.trim());
                }
                return ret
            });
            this.sending = false
            this.users = arr
        }
    },
    computed: {
    },
    activated() {
    },
    async mounted() {
        this.getUsers()
    },
    watch: {
        async groupId (newValue, oldValue) {
            if (newValue) {
                this.getUsers()
            }
        }
    }
}

</script>
