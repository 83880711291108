import gql from 'graphql-tag'

export default gql`query ycmdReportDefinitionGet($report_id: ID, $parameters: [IdValueInput]) {
	ycmdReportDefinitionGet(report_id: $report_id, parameters: $parameters) {
		name
    	id
		categories
		reportProperties {
			name
			value
		}
		parameters {
			id
			name
			data_type
			select_list_key
			select_multiple
			required
			attributes {
				name
				value
			}
		}
		lists {
			key
			values {
				name
				id
				value
			}
		}
		schedules {
			schedule_id
    		scheduling_user_id
    		schedule_repeat
			dow
			dom
			time
			parameters {
				id
				value
			}
			format
			emails
			last_run
		}
		progress {
			type
		}
	}
}`
