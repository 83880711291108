import gql from 'graphql-tag'

export default gql`query ycmdProfileGet($accountType: AccountType) {
	ycmdProfileGet(accountType: $accountType)
    {
        first_name
        last_name
        middle_initial
        street
        street2
        city
        state
        zip_code
        email
    }
}`
