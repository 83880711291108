<template>

    <div class="view ycmd-blue-gradient">

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <div class="i-card__main pa-2">

                <div class="i-card__main__header">
                    <div class="d-flex align-center flex-grow-1 ml-1">
                        <v-btn icon class="hidden-sm-and-up mr-2" @click="toggleMenu">
                            <v-icon :size="22" color="white">menu</v-icon>
                        </v-btn>
                        <div class="d-flex align-center white--text fs-13" style="width: 168px">
                            <div class="hidden-xs-only fade-2 hidden-sm-and-down">Showing</div>
                            <div class="fw-500 fs-14 pl-1 pr-1">{{messages.length}}</div>
                            <div class="fade-2">of</div>
                            <div class="fw-500 fs-14 pl-1 pr-1">{{this.totalMessages}}</div>
                            <v-tooltip bottom
                                       nudge-bottom="5"
                                       :disabled="$vuetify.breakpoint.xsOnly"
                                       color="ycmd-black"
                                       content-class="fade-0 pa-4 pt-2 pb-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon dark depressed v-on="on" class="ml-2 mr-2" color="white">
                                        <v-icon :size="22" @click="searchMessages()">refresh</v-icon>
                                    </v-btn>
                                </template>
                                <span>Refresh List</span>
                            </v-tooltip>
                        </div>
                        <v-spacer/>
                        <!-- <div class="d-flex flex-grow-1">
                            <v-text-field hide-details dark flat single-line dense solo
                                          ref="searchBarRef"
                                          v-model="searchValue"
                                          v-on:keyup.enter="searchMessages"
                                          label="Search"
                                          color="blue"
                                          :class="`hidden-xs-only search-bar ycmd-medium-blue ml-2 br-0 br_tl-2 br_bl-2`"/>
                            <v-btn icon dark depressed
                                   @click="searchMessages()"
                                   class="ycmd-dark-blue hidden-xs-only mr-2 br-0 br_tr-2 br_br-2"
                                   style="width: 56px; height: 38px">
                                <v-icon>search</v-icon>
                            </v-btn>
                        </div> -->
                        <input-search ref="searchBarRef"
                                              @keyup.enter.native="searchMessages"
                                              :hasAdvancedSearch="false"
                                              :onSearchClick="onSearchClick"
                                              :searchValue.sync="searchValue" />
                    </div>
                    <div class="d-flex align-center justify-end ml-2" style="width: 135px">
                        <v-select hide-details light dense flat solo
                                  :items="pcpUserMessageTypes"
                                  :rules="[rules.required]"
                                  v-model="pcpUserMessageTypeSelected"
                                  item-text="name"
                                  return-object
                                  class="full-width pt-1 ml-2 mr-0"/>
                    </div>
                </div>

                <div class="i-card__main__content white pa-2 br-2">

                    <v-container fluid class="fixed-header pl-4 hidden-sm-and-down">

                            <!-- Header: Make sure this matches layout below -->
                            <v-row no-gutters>
                                <v-col cols="6" sm="4" md="2" lg="2">
                                    <div class="fixed-header__column">PCP
                                        <v-btn icon color="ycmd-green" class="hidden">
                                            <v-icon :size="16">arrow_downward</v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-col cols="4" sm="8" md="3" lg="3">
                                    <div class="fixed-header__column">Message
                                        <v-btn icon color="ycmd-blue" class="hidden">
                                            <v-icon :size="16">arrow_downward</v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-col cols="6" sm="4" md="3" lg="2">
                                    <div class="fixed-header__column">Occurred
                                        <v-btn icon color="ycmd-blue">
                                            <v-icon :size="16">arrow_downward</v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-col cols="6" sm="4" md="2" lg="2">
                                    <div class="fixed-header__column">Patient
                                        <v-btn icon color="ycmd-blue" class="hidden">
                                            <v-icon :size="16">arrow_downward</v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="4" md="2" lg="3">
                                    <div class="fixed-header__column">Reason
                                        <v-btn icon color="ycmd-blue" class="hidden">
                                            <v-icon :size="16">arrow_downward</v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row>

                            <!-- Spacing for list buttons -->
                            <div style="width: 40px">&nbsp;</div>
                        </v-container>

                    <div class="i-card__scrollable">

                        <div class="list-container">

                            <v-container fluid v-for="(message, i) in messages" :key="`user_${i}`" :class="`list-item flex-shrink-0 pa-3 pl-4 pr-4 ${ message.confirmed_time ? 'message--confirmed' : 'message--confirmed' }`">

                                <v-row no-gutters>
                                    <v-col cols="6" sm="4" md="2" lg="2">
                                        <div :class="`list-item__column ${ $vuetify.breakpoint.smAndDown ? 'pb-4' : '' }`">
                                            <div class="hidden-md-and-up label-title">PCP</div>
                                            <div class="label-value fw-500">
                                                {{ message.pcp_given_name || 'N/A' }}
                                                {{ message.pcp_family_name || 'N/A' }}
                                                {{ message.pcp_suffix }}
                                              <span v-if="message.pcp_degree" class="label-value">, {{ message.pcp_degree }}</span>
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col cols="4" sm="8" md="3" lg="3">
                                        <div :class="`list-item__column ${ $vuetify.breakpoint.smAndDown ? 'pb-4' : '' }`">
                                            <div class="hidden-md-and-up label-title">Message</div>
                                            <div class="label-value">
                                                {{ message.message_sending_facility || 'N/A' }} | {{ parsePcpStatus[message.message_trigger_event] || 'N/A'}}
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col cols="6" sm="4" md="3" lg="2">
                                        <div :class="`list-item__column ${ $vuetify.breakpoint.smAndDown ? 'pb-4' : '' }`">
                                            <div class="hidden-md-and-up label-title">Occurred</div>
                                            <div class="label-value fs-10">{{ format(parseISO(message.event_recorded_time), 'iii MMM d, yyyy h:mm a') }} PST</div>
                                        </div>
                                    </v-col>
                                    <v-col cols="6" sm="4" md="2" lg="2">
                                        <div :class="`list-item__column ${ $vuetify.breakpoint.smAndDown ? 'pb-4' : '' }`">
                                            <div class="hidden-md-and-up label-title">Patient</div>
                                            <div class="label-value fw-500">
                                                {{ message.patient_given_name || 'N/A' }}
                                                {{ message.patient_family_name || 'N/A' }}
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="2" lg="3">
                                        <div class="d-flex fill-height align-center">
                                            <div :class="`${ $vuetify.breakpoint.smAndDown ? 'd-flex flex-column flex-grow-1' : '' }`">
                                                <div class="hidden-md-and-up label-title">Reason</div>
                                                <div class="label-value fs-10">{{ message.visit_admit_reason || 'N/A' }}</div>
                                            </div>
                                            <div class="hidden-sm-and-up">
                                                <v-tooltip left
                                                           :disabled="$vuetify.breakpoint.xsOnly"
                                                           color="ycmd-black"
                                                           content-class="fade-0 pa-4 pt-2 pb-2">
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn icon dark text small
                                                               @click="() => { changeView('details-view', { data: {visit_id: message.visit_id}});  confirmMessagePCP(message.visit_id) }"
                                                               v-on="on" color="white" class="ycmd-blue mr-1">
                                                            <v-icon :size="14">arrow_forward</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Show Details / Confirm</span>
                                                </v-tooltip>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>

                                <div class="hidden-xs-only">
                                    <div class="d-flex flex-shrink-0 fill-height align-center justify-center">
                                        <v-tooltip left
                                                   :disabled="$vuetify.breakpoint.xsOnly"
                                                   color="ycmd-black"
                                                   content-class="fade-0 pa-4 pt-2 pb-2">
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon dark text small
                                                       @click="() => { changeView('details-view', { data: {visit_id: message.visit_id}}); confirmMessagePCP(message.visit_id) }"
                                                       v-on="on" color="white" class="ycmd-blue mr-1">
                                                    <v-icon :size="14">arrow_forward</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Show Details / Confirm</span>
                                        </v-tooltip>
                                    </div>
                                </div>

                            </v-container>

                            <infinite-loading @infinite="infiniteHandler" :identifier="infiniteId">
                                <div slot="no-more" style="color: white; font-weight: 500; font-size: 16px;">No more data</div>
                                <div slot="no-results" style="color: white; font-weight: 500; font-size: 16px;">No results</div>
                                <div slot="no-results">
                                    <div class="pt-4 mt-4">{{this.loadingMessageResult}}</div>
                                </div>
                                <div slot="spinner">
                                    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                </div>
                            </infinite-loading>
                        </div>

                    </div>

                </div>
            </div>

        </i-card>

    </div>

</template>

<script>

    import { format, parseISO } from 'date-fns';
    import Loading from 'vue-loading-overlay';
    import InfiniteLoading from 'vue-infinite-loading';
    import {defaultErrorHandler} from '../../../../graphql/handler/errorHandler';
    import {refreshHandler} from '../../../../graphql/handler/refreshHandler';
    import { parsePcpStatus } from '../../../../methods/globalMethods';

    import InputSearch from "@/components/input-search";

    import GET_MESSAGES from '../../../../graphql/queries/pcpMessagesBrowse';
    import GET_FACILITIES from '../../../../graphql/queries/pcpGetFacilities';
    import CONFIRM_MESSAGES from '../../../../graphql/mutations/pcpMessageConfirm';
    import {mapActions, mapState} from "vuex";

    import { pcpUserMessageTypes } from '../../../../assets/pcpUser/constants.js';

    export default {
        name: 'notifications',
        components: {
            Loading,
            InfiniteLoading,
            'input-search': InputSearch
        },
        data() {
            return {
                format,
                parseISO,
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                parsePcpStatus: parsePcpStatus,
                messages: [],
                page: 1,
                searchValue: '',
                infiniteId: 1,
                loadingMessageResult: '',
                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 8 || 'Min 8 characters'
                },
                pcpUserMessageTypes: pcpUserMessageTypes,
                pcpUserMessageTypeSelected: pcpUserMessageTypes[0],

                facilityIdHardcoded: '510836dedb8ae51e93f27e3b',
                filterID: null,
                filterReferenceID: null,
                filterFacilityID: null,
                filterVisitID: null,

                nextToken: null,
                totalMessages: 0,

                selectedFacilitiesSearched: '',
                facilitiesSearched: [],
                isLoadingSearch: false,
                searchFacility: null,

                acceptedMessageTypes: {
                  'A01': true,
                  'A03': true,
                  'A04': true,
                  'A06': true,
                  'A07': true
                }

            }
        },
        created() {

        },
        mounted() {
          // console.log(this.pcpUserMessageTypes);
        },
        activated() {

        },
        watch: {
          pcpUserMessageTypeSelected(val) {
            // reset count
            this.totalMessages = 0;
            // do new search here
            this.searchMessages()
          }
        },
        methods: {
            ...mapActions('menu', ['openMenu','closeMenu','toggleMenu']),
            changeView(view, params) {

                this.$emit('onChangeView', {view: view, params: params});
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
            searchMessages() {
                this.infiniteId += 1;
                this.messages = [];
                this.nextToken = null;
            },
            onSearchClick(e, value) {
                this.searchMessages();
            },
            async getFacilities(val) {
              this.isLoadingSearch = true;
              try {
                const response = await this.$apollo.query({
                    query: GET_FACILITIES,
                    variables: {
                        name: val,
                        limit: 10
                    },
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all'
                });
                this.isLoadingSearch = false;
                if (response.data && response.data.pcpGetFacilities) {
                  this.facilitiesSearched = response.data.pcpGetFacilities;
                  return response.data.pcpGetFacilities;
                }
              } catch(e) {
                defaultErrorHandler(e);
              }
            },
            confirmMessagesUI(visitID) {
              if (!visitID) return;
              for (let message in this.messages) {
                const currentMessage = this.messages[message];
                // check if visitID matches
                if (currentMessage.visit_id === visitID) {
                  currentMessage.confirmed_time = Date.now();
                }
              }
            },
            async confirmMessagePCP(visitID) {
              // TODO: TEMPORARY
              // taking this out as requested for now
              return;
              //
              if (!visitID) {
                this.$toasted.error(`There was an error confirming message - missing visit ID`);
                return;
              }
              this.confirmMessagesUI(visitID);
              try {
                const response = await this.$apollo.mutate({
                  mutation: CONFIRM_MESSAGES,
                  variables: { visit_id: visitID },
                  fetchPolicy: 'no-cache',
                  errorPolicy: "all"
                })
                // console.log('MESSAGE: Confirm message response: ', response);
                if (response.data && response.data.pcpMessageConfirm) {
                    // check for success
                    if (response.data.pcpMessageConfirm.status === 'success') {
                        this.$toasted.success(`Successfully confirmed message.`);
                    }
                } else if (response.errors && response.errors.length > 0) {
                    if (response.errors[0].message) {
                        this.$toasted.error(`There was a problem confirming messsage - ${response.errors[0].message}`);
                    } else {
                        this.$toasted.error(`There was an error confirming message.`);
                    }
                } else {
                    // data did not come back
                    console.log('updateStatusError data did not come back: ', response);
                    this.$toasted.error(`There was an error.`);
                }
              } catch(e) {
                defaultErrorHandler(e);
              }
            },
            async infiniteHandler($state) {

                this.isLoading = true;

                try {
                    const response = await this.$apollo.query({
                        query: GET_MESSAGES,
                        variables: {
                            limit: 20,
                            search: this.searchValue || null,
                            sort_direction: 'desc',
                            sort_field: '_sort_time',
                            nextToken: this.nextToken || null,
                            event_type_code: this.pcpUserMessageTypeSelected ? this.pcpUserMessageTypeSelected.value : this.pcpUserMessageTypes[0].value
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    console.log('response: ', response);
                    this.isLoading = false;

                    // update count
                    if (response.data.pcpMessagesBrowse && response.data.pcpMessagesBrowse.message_totals && response.data.pcpMessagesBrowse.message_totals.message_types) {
                      for (let type in response.data.pcpMessagesBrowse.message_totals.message_types) {
                          const currentType = response.data.pcpMessagesBrowse.message_totals.message_types[type];
                          if (currentType.message_type === 'search') this.totalMessages = currentType.count;
                      }
                    }

                    if (response.data.pcpMessagesBrowse && response.data.pcpMessagesBrowse.messages.length) {
                        this.page += 1;
                        //alert(this.page);
                        this.messages.push(...response.data.pcpMessagesBrowse.messages);
                        // check nextToken
                        if (response.data.pcpMessagesBrowse.nextToken) {
                          this.nextToken = response.data.pcpMessagesBrowse.nextToken;
                          $state.loaded();
                        } else {
                          this.nextToken = null;
                          this.loadingMessageResult = '';
                          $state.complete();
                        }
                    } else {
                        // data did not come back
                        //console.log('pcpMessagesBrowse data did not come back or is finished: ', response);
                        this.loadingMessageResult = 'No results were found';
                        $state.complete();
                    }
                } catch (e) {
                    $state.complete();
                    defaultErrorHandler(e);
                }
            },
            route(name, transition) {

                this.$router.push({
                    name: name, params: {
                        transition: transition
                    }
                }).catch(err => { console.log('router error: ', err) });
            }
        },
        computed: {
            ...mapState({
                isMenuOpen: state => state.menu.open
            })
        }
    }

</script>

<style scoped>

    .message--confirmed {

    }

    .message--unconfirmed {
      background-color: rgba(11, 160, 236, 0.5);
    }

    @media (max-width: 600px) {
        .list-container {
            font-size: 10px;
            padding: 0 0 8px 0;
        }
    }

    /* Small Breakpoint */
    @media (min-width: 600px) {

    }

    /* Medium Breakpoint */
    @media (min-width: 960px) {
        .list-item__column {
            display: flex;
            align-items: center;
            height: 100%;
        }
        .label-value {
            padding-bottom: 0;
        }
    }

    /* Large Breakpoint */
    @media (min-width: 1264px) {

    }

    /* XLarge Breakpoint */
    @media (min-width: 1904px) {

    }

</style>
