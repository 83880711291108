// Initial state
const state = {
  open: false,
  indexOf: 0
};

const getters = {
  isMenuOpen: state => state.open,
  menuIndex: state => state.index
};

const actions = {

  changeMenu: ({commit}, index) => commit('change', index),
  toggleMenu: ({commit}) =>  commit('toggle'),
  openMenu: ({commit}) => commit('open'),
  closeMenu: ({commit}) => commit('close'),
};

const mutations = {

  change(state, index) {

    state.index = index;
  },
  toggle (state) {

    state.open = !state.open;
  },
  open(state) {

    state.open = true;
  },
  close(state) {

    state.open = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
