import Vue from 'vue'
import Vuex from 'vuex'
import layout from './modules/layout'
import menu from './modules/menu'
import profile from './modules/profile'
import data from './modules/data'
import report from './modules/report'
import billing from './modules/billing'

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    layout,
    menu,
    profile,
    data,
    report,
    billing
  },
  strict: debug
})
