import {createAuthLink} from 'aws-appsync-auth-link';
import {SubscriptionHandshakeLink} from "aws-appsync-subscription-link/lib/subscription-handshake-link";
import {NonTerminatingHttpLink} from "aws-appsync-subscription-link/lib/non-terminating-http-link";

import {ApolloClient, ApolloLink, InMemoryCache, from, createHttpLink, HttpLink, split} from '@apollo/client/core';
import {setContext} from '@apollo/client/link/context'
import {getMainDefinition} from '@apollo/client/utilities';

import Vue from 'vue'
import {onError} from '@apollo/client/link/error';
import {refreshHandler} from '../handler/refreshHandler';
import {logout} from '@/methods/globalMethods';

//import {inactivityTimer} from '../handler/inactivityTimer'

const apiKey = process.env.VUE_APP_APPSYNC_API_KEY;

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_APPSYNC_URL
});

const wsLink = ApolloLink.from([
  new NonTerminatingHttpLink("subsInfo", {uri: process.env.VUE_APP_APPSYNC_URL}, true),
  new SubscriptionHandshakeLink("subsInfo")
]);

const errorLink = onError(({graphQLErrors, networkError}) => {

    if (graphQLErrors) {
        graphQLErrors.map(({ errorType, message, path }) => {
            console.error(`[GraphQL error]: ErrorType: ${errorType}, Message: ${ message }, Path: ${path}`)
            // NOTE: PCP token will run into UnauthorizedException, YCMD token will run into TokenExpiredError
            switch (errorType) {
                case 'NoAuthToken':
                    console.log("WARNING, NoAuthToken - LOGGING OUT...");
                    // Vue.toasted.error(`Session expired... Please log in again`);
                    logout();
                break;
                case 'TokenExpiredError':
                    console.log("WARNING, TokenExpiredError - LOGGING OUT...");
                    // Vue.toasted.error(`Session expired... Please log in again`);
                    logout();
                case 'UnauthorizedException':
                    console.log('WARNING, UnauthorizedException - LOGGING OUT...');
                    // Vue.toasted.error(`Session expired... Please log in again`);
                    logout();
                break;
            }
        });
    }
    if (networkError) {
        console.error(`[Network error]: networkError: ${networkError}`)
    }

});

const authLink = setContext(async (request, {headers}) => {
    //inactivityTimer()
    const refreshHandlerResponse = await refreshHandler();
    const token = localStorage.accessToken;
    // const token = "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJncm91cF9pZCI6IjUxZGFlYjZlMmMwYTYyNmM2MTAwMDAwNyIsInVzZXJfaWQiOiI1ZTdjMmNkZjUyYWVjZTE5Mjc3MTVjZjIiLCJ1c2VyX2xpbmtfaWQiOm51bGwsImlhdCI6MTYyMzE5OTA0MywiZXhwIjoxNjIzMjAyNjQzfQ.kkTaMXoVbG2r6te4kF_SWFpuvxuMZpnjyXfNRdx2CYBZ1sTIftVgrWbzU0F8_Nw-g8sZtsA5s1Xig0L66IT3tA"
    // console.log('testing refresh response: ', refreshHandlerResponse);

    // if (!refreshHandlerResponse) {
    //     console.log('WARNING, refreshHandlerResponse returned false')
    //     // logout();
    // }

    try {
        return {
            headers: {
                ...headers,
                authorization: token,
                'x-api-key': apiKey
            },
        };
    }
    catch (e) {
        return {
            headers: {
                ...headers,
                'x-api-key': apiKey,
            },
        };
    }

});

const phoenixLink = ApolloLink.from([
  errorLink,
  authLink,
  split(
    (operation) => {
      const {query} = operation;
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    httpLink
  )
]);

const phoenixClient = new ApolloClient({
  link: phoenixLink,
  cache: new InMemoryCache({addTypename: false})
});

export default phoenixClient;
