<template>
    <div
        style="width: 100%"
    >
        <h6
            class="pb-2"
            v-if="!restrictPrompt"
        >{{label}}</h6>
        <template
            v-for="obj in languageFilteredPrompts"
        >
            <v-textarea
                :key="obj.language"
                v-model="obj.text"
                :label="getLabel(obj.language)"
                auto-grow
                outlined
                rows="1"
                :disabled="disabled"
                @change="changed"
            >
                <template slot="append-outer">
                    <v-icon
                        v-if="obj.defaultText && obj.defaultText != obj.text"
                        @click="restoreToDefault(obj)"
                        title="Restore defaults"
                    >settings_backup_restore</v-icon>
                </template>
            </v-textarea>
        </template>
    </div>
</template>
<script>

export default {
    components: {
    },
    props: {
        prompt: {
            type: Array,
            default: () => []
        },
        language: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: 'Hello there'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        default: {
            type: String,
            default: null
        },
        restrictPrompt: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        changed (value) {
            this.$emit('changed', value)
        },
        getLabel (language) {
            return `${this.languageDescription(language)}`
        },
        languageDescription (language) {
            return language == 'es-US' ? 'Spanish': 'English'
        },
        restoreToDefault (obj) {
            obj.text = obj.defaultText
        },
        restoreDefaultIcon (obj) {
            if (this.default) {
                return 'settings_backup_restore'
            }
            return null
        }
    },
    computed: {
        languageFilteredPrompts () {
            console.log('prompt', this.prompt)
            let arr = []
            let lan = this.language ? this.language: 'prompt'
            if (this.prompt && this.prompt.length) {
                this.prompt.map(p => {
                    if (lan === 'prompt' || p.language === lan) {
                        arr.push(p)
                    }
                })
            }
            return arr
        }
    },
    data: () => ({
    }),
    activated() {
    },
    mounted() {
    },
    watch: {
    }
}

</script>
