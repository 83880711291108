<template>
    <div
        style="width: 100%"
    >
        <!--
        <h5
            style="width: 100%;text-align: center;border-bottom: 1px solid #ced8dd;line-height: 0.1em;margin: 10px 0 20px;"
        ><span
            class="v-label v-label--active theme--light"
            style="background:#f0f6fa"
        >Options</span></h5>
        -->
        <h2>Menu Options</h2>

        <v-data-table
            :headers="headers"
            :items="items"
            dense
            :hide-default-footer="true"
            :disable-pagination="true"
            no-data-text="No options"
            :show-expand="false"
        >
            <template v-slot:item.delete="{ item }">
                <v-btn icon dark
                        color="error"
                        @click.stop="deleteOption(item)"
                        >
                    <v-icon :size="20">delete</v-icon>
                </v-btn>
                <v-btn icon dark
                        color="ycmd-blue"
                        @click.stop="beginEditOption(item)"
                        >
                    <v-icon :size="20">edit</v-icon>
                </v-btn>
            </template>

        </v-data-table>
        <v-btn
            @click.stop="beginAddOption"
            class="py-5"
        >Add a menu option</v-btn>

        <v-dialog v-model="dialog" persistent max-width="300">
            <v-card style="padding-top: 15px;">
                <v-card-text>{{menuOptionTitle}}</v-card-text>
                <v-card-text class="fs-18">
                    
                    <v-select
                        light dense flat outlined
                        v-model="newNumber"
                        :items="filteredNumbers"
                        label="Number"
                        style="width: 300px;"
                        background-color="white"
                        v-if="this.optionMode == 'add'"
                    ></v-select>

                    <v-text-field
                        light dense flat outlined
                        v-model="newNumber"
                        label="Number"
                        style="width: 300px;"
                        background-color="white"
                        :disabled="true"
                        v-if="this.optionMode == 'edit'"
                    />
                    
                    <v-select
                        light dense flat outlined
                        v-model="newAction"
                        :items="actions"
                        label="Action"
                        item-text="text"
                        item-value="value"
                        style="width: 300px;"
                        background-color="white"
                    ></v-select>

                    <v-select
                        light dense flat outlined
                        v-model="newActionValue"
                        :items="menus"
                        label="Menu"
                        item-text="name"
                        item-value="name"
                        style="width: 300px;"
                        background-color="white"
                        v-if="newAction == 'menu'"
                    ></v-select>

                    <v-text-field
                        light dense flat outlined
                        v-model="newActionValue"
                        :items="actions"
                        :label="actionLabel"
                        item-text="text"
                        item-value="value"
                        style="width: 300px;"
                        background-color="white"
                        v-else
                    />
                    
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn text @click="addOption" >OK</v-btn>
                    <v-btn text @click="dialog = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>   
    </div>
</template>
<script>

export default {
    components: {
    },
    props: {
        options: {
            type: Array,
            default: () => {}
        },
        language: {
            type: String,
            default: ''
        },
        menus: {
            type: Array,
            default: () => {}
        }
    },
    methods: {
        beginAddOption () {
            this.optionMode = 'add'
            this.dialog = true
        },
        beginEditOption (option) {
            let index = this.localOptions.findIndex(o => {
                return o.number == option.number
            })
            if (index >=0) {
                this.newNumber = this.localOptions[index].number
                this.optionMode = 'edit'
                this.dialog = true
            }
        },
        translateAction(action) {
            let option = this.actions.find(a => {
                return a.value == action
            })
            
            if (option) {
                return option.text
            }

            return ''
        },
        translateActionValue(action, action_value) {
            let option = this.actions.find(a => {
                return a.value == action
            })

            if (!option || !option.value) {
                return ''
            }

            switch (option.value) {
                case 'menu':
                case 'voicemail':
                case 'transfer_to_number':
                    return action_value
                default: 
                    return ''
            }
        },
        deleteOption (item) {
            let index = this.options.findIndex(a => {
                return a.number === item.number
            })
            if (index >= 0) {
                let number = this.options[index].number
                this.options.splice(index, 1)
                this.options.map(o => {
                    if (o.number > number) {
                        o.number -= 1
                    }
                })
            }
            this.$emit('changed', this.options)
        },
        editOption (item) {
            let index = this.options.findIndex(a => {
                return a.number === item.number
            })
            if (index >= 0) {
                let number = this.options[index].number
                this.options.splice(index, 1)
                this.options.map(o => {
                    if (o.number > number) {
                        o.number -= 1
                    }
                })
            }
            this.$emit('changed', this.options)
        },
        addOption () {
            let option = {
                number: this.newNumber,
                action: this.newAction
            }
            
            switch (this.newAction) {
                case 'menu':
                case 'voicemail':
                case 'transfer_to_number':
                    option.action_value = this.newActionValue
                    break
                default:
                    option.action_value = ''
                    break
            }

            if (this.optionMode == 'edit') {
                let index = this.options.findIndex(o => {
                    return o.number == this.newNumber
                })
                this.options[index] = option

                this.options.push(option)  //add an element and remove it to force the array to be regenerated
                this.options.length = this.options.length - 1 

            } else {
                this.options.push(option)
            }
            this.$emit('changed', this.options)

            this.dialog = false
        }
    },
    computed: {
        menuOptionTitle () {
            return this.optionMode === 'add' ? 'Add Menu Option' : 'Edit Menu Option'
        },
        actionLabel () {
            switch (this.newAction) {
                case 'menu':
                    return 'Menu'
            
                case 'voicemail':
                    return 'Mailbox'

                case 'transfer_to_number':
                    return 'Phone Number'

                case 'transfer_to_twilio':
                case 'hangup':
                    return ''
            }
            return 'Value'
        },

        headers () {
            let headers = [
                {
                    value: 'delete',
                    text: '',
                    width: '30px',
                    sortable: false
                },
                {
                    text: 'Number',
                    value: 'number',
                    width: '75px',
                    sortable: false
                },
                {
                    text: 'Action',
                    value: 'action',
                    width: '300px',
                    sortable: false
                },
                {
                    text: 'Value',
                    value: 'action_value',
                    width: '500px',
                    sortable: false
                }
            ]
            return headers
        },
        items () {
            let arr = []
            if (this.localOptions && this.localOptions.length) {
                this.localOptions.map(o => {
                    arr.push({
                        number: o.number,
                        action: this.translateAction(o.action),
                        action_value: this.translateActionValue(o.action, o.action_value),
                    })
                })
            }
            let sorted = arr.sort( (a,b) => {
                return (a.number+'').localeCompare(b.number+'')
            })
            return sorted
        },
        filteredNumbers () {
            let options = {}
            this.options.map(o => {
                options[`i_${o.number}`] = true
            })
            let numbers = []
            for (let i = 1;i<=9;i++) {
                if (!options[`i_${i}`]) {
                    numbers.push(i+'')
                }
            }
            return numbers
        }
    },
    data: () => ({
        actions: [
            {value: 'menu', text: 'Menu'},
            {value: 'voicemail', text: 'Voicemail'},
            {value: 'transfer_to_twilio', text: 'Transfer to YoucallMd'},
            {value: 'transfer_to_number', text: 'Transfer to a Phone Number'},
            {value: 'hangup', text: 'End call'}
        ],
        numbers: ['1','2','3','4','5','6','7','8','9'],
        dialog: false,
        newNumber: '',
        newAction: null,
        newActionValue: null,
        localOptions: [],
        optionMode: 'add'
    }),
    activated() {
    },
    mounted() {
        this.localOptions = this.options
    },
    watch: {
        options: {
            handler (newValue, oldValue) {
                this.localOptions = newValue
            }, 
            deep: true
        }
    }
}

</script>
